import { useEffect, useState } from "react"
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks"
import { RootState } from "../../../../redux/store"
import { Link, useNavigate } from "react-router-dom"
import { setInitialState } from "../../../../redux/reservas/editReservaSlice"
import { getStatesAndGrades } from "../../../../redux/reservas/statesGradesSlice"
import { selectRouteValues } from "../../ReservaRecorrido/selectRouteValues"
import { Reservas } from "../../../../services/reservasCalls"
import { validationSchema } from "../validationSchema"
import { Spinner } from "../../../Spinner"
import { useFormik } from "formik"
import { TextField, Select, InputLabel, MenuItem } from "@mui/material"
import {
  Form,
  ControlForm,
  InputErrors,
  ContainerButton,
  Buttons,
  CancelButton,
} from "./styles"
import {
  sweetAlertConfirm,
  sweetAlertMixin,
} from "../../../../helpers/sweetAlert"
import ReactSelect from "react-select"

import makeAnimated from "react-select/animated"
import moment from "moment"
import { deleteReserveProfile } from "../../../../redux/profile/profileSlice"
import { setStep } from "../../../../redux/reservas/reservasSlice"
import { validateCueCalls } from "../../../../services/validateCueCalls"
import { reservaSchema } from "../../Modal/validationSchema"

interface InitialValuesProps {
  institution: string
  cue: string
  state_id: number | string
  city: string
  participants: number | string
  grade_id: number | string
  tour_paths: string[]
}

export const FormEdit = ({ setOpenEditFormModal }: any) => {
  const options = [
    {
      value: "Surtido Bagley",
      label: "Surtido Bagley",
    },
    {
      value: "Butter Toffees",
      label: "Butter Toffees",
    },
    {
      value: "Bon o Bon",
      label: "Bon o Bon",
    },
    {
      value: "Azúcar",
      label: "Azúcar",
    },
    {
      value: "Tomate",
      label: "Tomate",
    },
    {
      value: "Cartón",
      label: "Cartón",
    },
  ]
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { oldTourBook, newTourBook } = useAppSelector(
    (state: any) => state.editTour
  )

  const { states, grades } = useAppSelector(
    (state: RootState) => state.StateGrades
  )
  const [recorridos, setRecorridos] = useState<any>([])
  const [isFirstValidaton, setIsFirstValidaton] = useState(true)
  const [recorridoError, setRecorridoError] = useState(false)
  const [defaultValues, setDefaultValues] = useState<any>([])
  const [state, setState] = useState(false)

  const animatedComponents = makeAnimated()

  const initialValues: InitialValuesProps = {
    institution: oldTourBook.institution,
    cue: oldTourBook.cue,
    city: oldTourBook.city,
    state_id: oldTourBook.state_id,
    participants: oldTourBook.participants,
    grade_id: oldTourBook.grade_id,
    tour_paths: [oldTourBook.paths[0]?.name, oldTourBook.paths[1]?.name],
  }

  const { getFieldProps, errors, touched, handleSubmit, setErrors } = useFormik(
    {
      enableReinitialize: true,
      initialValues,
      validationSchema: reservaSchema,
      onSubmit: async (values) => {
        setState(true)

        try {
          const response = await validateCueCalls.getCueValidation(values.cue)

          if (!response.data.cue) {
            if (isFirstValidaton) {
              setIsFirstValidaton(false)
              setErrors({ cue: "¡Verifica que el CUE esté bien ingresado!" })
              setState(false)
              return
            } else {
              setErrors({
                cue: "custom",
              })
              setState(false)
              return
            }
          }

          if (response.data.cue) {
            let newPaths: string[] = []
            selectRouteValues.map((el: any) => {
              if (
                el.title === recorridos[0]?.value ||
                el.title === recorridos[1]?.value
              ) {
                newPaths.push(el.id)
              }
            })
            values.tour_paths = newPaths

            if (recorridoError) return

            const newHour = newTourBook.hour.split(" ")
            const fechaCompleta = `${newTourBook.date} ${newHour[0]}`

            const bodyParameters = {
              ...values,
              date: newTourBook.date_id,
            }

            await Reservas.updateTourBook(oldTourBook.id, bodyParameters)
              .then((res) => {
                dispatch(setStep(0))
                dispatch(setInitialState())
                setState(false)
                sweetAlertMixin("success", "Se modifico la reserva con exito")
                navigate("/user/profile")
              })
              .catch((err) => {
                setState(false)
                sweetAlertMixin(
                  "error",
                  "No pudimos modificar la reserva, intenta mas tarde."
                )
              })
          }
        } catch (error) {
          console.error(error)
        } finally {
          setState(false)
        }
      },
    }
  )

  useEffect(() => {
    dispatch(getStatesAndGrades())
    let pathsLabel: string[] = []
    options.map((el: any) => {
      if (
        el.value === oldTourBook.paths[0]?.name ||
        el.value === oldTourBook.paths[1]?.name
      ) {
        pathsLabel.push(el)
      }
    })
    setDefaultValues(pathsLabel)
    setRecorridos(pathsLabel)
  }, [])

  const handleChangeReactSelect = (item: any) => {
    setRecorridos(item)
  }

  const handleDeleteReserve = async () => {
    const response = await sweetAlertConfirm()
    if (response) {
      await dispatch(deleteReserveProfile(oldTourBook.id))
      navigate("/user/profile")
    }
  }

  useEffect(() => {
    if (recorridos.length < 1) {
      setRecorridoError(true)
    } else {
      setRecorridoError(false)
    }
  }, [recorridos])

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        type="text"
        label="Institucion"
        {...getFieldProps("institution")}
        error={touched.institution && Boolean(errors.institution)}
        helperText={
          touched.institution && errors.institution ? errors.institution : null
        }
        fullWidth
        size="small"
      />
      <TextField
        type="text"
        label="CUE"
        {...getFieldProps("cue")}
        error={touched.cue && Boolean(errors.cue)}
        helperText={
          touched.cue &&
          errors.cue &&
          (errors.cue === "custom" ? (
            <>
              Este CUE es inválido, contacta con el equipo de Arcor{" "}
              <Link to="/contacto" onClick={() => setOpenEditFormModal(false)}>
                desde acá
              </Link>{" "}
              .
            </>
          ) : (
            errors.cue
          ))
        }
        fullWidth
        size="small"
      />

      <ControlForm>
        {defaultValues.length > 0 && (
          <ReactSelect
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={defaultValues}
            onChange={(item) => handleChangeReactSelect(item)}
            isOptionDisabled={() => recorridos.length >= 1}
            isMulti
            isClearable={false}
            options={options}
            placeholder="Seleccione un recorrido"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 99999,
                fontFamily: "Raleway",
              }),
              multiValue: (base) => ({
                ...base,
                maxWidth: "fit-content",
              }),
              valueContainer: (base) => ({
                ...base,
                fontFamily: "Raleway",
                fontSize: "14px",
                maxWidth: "fit-content",
              }),
              multiValueRemove: (base) => ({
                ...base,
                ":hover": {
                  backgroundColor: "transparent",
                  cursor: "pointer",
                },
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: "none",
              }),
              control: (base) => ({
                ...base,
                height: "45px",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              fontFamily: "Raleway",
            })}
          />
        )}
        {recorridoError ? (
          <InputErrors>Elije al menos 1 recorrido</InputErrors>
        ) : null}
      </ControlForm>

      <ControlForm>
        <InputLabel id="provnicia-label">Provincia</InputLabel>
        <Select
          labelId="provnicia-label"
          id="provnicia-label"
          type="text"
          label="Provincia"
          {...getFieldProps("state_id")}
          error={touched.state_id && Boolean(errors.state_id)}
          fullWidth
          size="small"
        >
          {states.map((state: any) => (
            <MenuItem key={state.id} value={state.id}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
        <InputErrors>
          {touched.state_id && errors.state_id && errors.state_id.toString()}
        </InputErrors>
      </ControlForm>

      <TextField
        type="text"
        label="Localidad"
        {...getFieldProps("city")}
        error={touched.city && Boolean(errors.city)}
        helperText={touched.city && errors.city ? errors.city : null}
        fullWidth
        size="small"
      />

      <TextField
        type="number"
        label="Cantidad de participantes"
        {...getFieldProps("participants")}
        error={touched.participants && Boolean(errors.participants)}
        helperText={
          touched.participants && errors.participants
            ? errors.participants
            : null
        }
        fullWidth
        size="small"
      />
      <ControlForm>
        <InputLabel id="grado-label">Grado</InputLabel>
        <Select
          labelId="grado-label"
          id="grado-label"
          type="text"
          label="Grado"
          {...getFieldProps("grade_id")}
          error={touched.grade_id && Boolean(errors.grade_id)}
          size="small"
        >
          {grades.map((grade) => (
            <MenuItem key={grade.id} value={grade.id}>
              {grade.name}
            </MenuItem>
          ))}
        </Select>
        <InputErrors>
          {touched?.grade_id && errors?.grade_id && (
            <InputErrors>{String(errors.grade_id)}</InputErrors>
          )}
        </InputErrors>
      </ControlForm>
      <ContainerButton>
        <Buttons
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => setOpenEditFormModal(false)}
        >
          Volver
        </Buttons>
        <Buttons
          type="submit"
          variant="contained"
          color="primary"
          disabled={state}
          fullWidth
        >
          {state ? (
            <Spinner width="30px" height="30px" color="#FFFFFF" />
          ) : (
            "Enviar"
          )}
        </Buttons>
      </ContainerButton>
      <CancelButton onClick={handleDeleteReserve}>Cancelar Turno</CancelButton>
    </Form>
  )
}
