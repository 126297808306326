import { useAppSelector } from "../../../../redux/hooks"
import { RootState } from "../../../../redux/store"
import { BookingButton } from "../BookingButton"
import { Container, Text } from "./styles"
import { useMediaQuery } from "@mui/material"

export const Title = () => {
  const { profile } = useAppSelector((state: RootState) => state.profile)
  const isMobile = useMediaQuery("(max-width:900px)")

  const toursAgendados = profile.length ? profile.length : 0
  return (
    <Container>
      <Text>Mis Tours</Text>
      {isMobile ? <Text>{toursAgendados} agendados</Text> : <BookingButton />}
    </Container>
  )
}
