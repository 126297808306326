import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { statesAndGradesCalls } from '../../services/statesAndGradesCalls';

interface GradesProps {
    id: number;
    name: string;
    created_at: null;
    updated_at: null;
}

interface StatesProps {
    id: number;
    name: string;
    created_at: null;
    updated_at: null;
}

interface InitialProps {
    states: StatesProps[];
    grades: GradesProps[];
    error: string;
    loading: boolean
}

const initialState: InitialProps = {
    states: [],
    grades: [],
    error: '',
    loading: false
} 

export const getStatesAndGrades = createAsyncThunk("StateGrades/get", async () => {
    try{
        const response = await statesAndGradesCalls.getStatesAndGrades();
    
        return response.data
    }catch(error: any){
        return error.response.data
    }
})

const statesGradesSlice = createSlice({
    name: "StateGrades",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStatesAndGrades.pending, (state) => {
                state.loading = true
            })
            .addCase(getStatesAndGrades.fulfilled, (state, { payload }) => {
                state.states = payload.states;
                state.grades = payload.grades
                state.loading = false
            })
            .addCase(getStatesAndGrades.rejected, (state) => {
                state.loading = false
                state.error = 'Ocurrio un error inesperado, intente nuevamente mas tarde'
            })
    },
});

export default statesGradesSlice.reducer;