import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IProps } from "./intefaces";
import { Reservas } from "../../services/reservasCalls";
import { selectRouteValues } from "../../components/ReservaTour/ReservaRecorrido/selectRouteValues";
import { authApiCalls } from '../../services/authApiCalls';

const initialState: IProps = {
  reserva: {
    id: "",
    date: "",
    date_id: 0,
    hour: "",
    recorrido: selectRouteValues,
    tour_paths: [],
    owner: {
      institution: "",
      cue: "",
      state_id: "",
      city: "",
      participants: "",
      grade_id: "",
    },
  },
  currentStep: 0,
  error: false,
  loading: false,
  success: false,
};

interface PropsMake {
  date: number;
  tour: any;
  token: string | null;
  city: string;
  cue: string;
  grade_id: number | string;
  institution: string;
  participants: number | string;
  state_id: number | string;
  tour_paths: number[];
}

export const makeReservation = createAsyncThunk(
  "reservas/make-reservation",
  async (values: PropsMake, { rejectWithValue, fulfillWithValue }) => {
    try {
 
      const bodyParameters = {
        institution: values.institution,
        city: values.city,
        state_id: values.state_id,
        participants: values.participants,
        grade_id: values.grade_id,
        cue: values.cue,
        tour_id: values.tour,
        date: values.date,
        tour_paths: values.tour_paths,
      };

      const response = await Reservas.makeReservationCall(
        bodyParameters
      );

      

      if (response.data.message === "The Date is Reserved") {
        return rejectWithValue("La fecha esta reservada");
      }

      return fulfillWithValue(response);
    } catch (error: any) {
      throw rejectWithValue(
        "Ocurrió un error inesperado, intente nuevamente mas tarde"
      );
    }
  }
);

const reservasSlice = createSlice({
  name: "reservas",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    setHourReserva: (state, action) => {
      state.reserva.hour = action.payload.hour;
      state.reserva.date = action.payload.date;
    },
    setDateIdReserva: (state, action) => {
      state.reserva.date_id = action.payload;
    },
    setDateReserva: (state, action) => {
      state.reserva.date = action.payload;
    },
    setOwnerReserva: (state, action) => {
      state.reserva.owner = action.payload;
    },
    setRecorrido: (state, action) => {
      state.reserva.tour_paths = action.payload;
    },
    setSelectRecorrido: (state, action) => {
      state.reserva.recorrido = action.payload;
    },
    setReserva: (state, action) => {
      state.loading = false;
      state.reserva = action.payload;
    },
    setErrorTrue: (state) => {
      state.error = true;
    },
    setErrorFalse: (state) => {
      state.error = false;
    },
    setSuccessFalse: (state) => {
      state.success = false;
    },
    nextStep: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    previousStep: (state) => {
      state.currentStep = state.currentStep - 1;
    },
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setInitiState: (state) => {
      state.currentStep = initialState.currentStep;
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.reserva = initialState.reserva;
      state.success = initialState.success;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(makeReservation.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(makeReservation.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(makeReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      });
  },
});

export const {
  startLoading,
  setReserva,
  setErrorTrue,
  setDateReserva,
  setDateIdReserva,
  setHourReserva,
  setOwnerReserva,
  nextStep,
  previousStep,
  setStep,
  setRecorrido,
  setSuccessFalse,
  setErrorFalse,
  setInitiState,
  setSelectRecorrido,
} = reservasSlice.actions;

export default reservasSlice.reducer;
