import { axiosInstance } from "./axiosInstance";


export const ContactForm = {
  sendMessage: async (bodyParameters: any) => {
    const response = await axiosInstance.post(
      `/send-contact-form`,
      bodyParameters
    );
    return response;
  },
};
