import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "10px",
}));

export const Subtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "20.25px",
  color: "#000000",
}));
