import { CardContainer, ContainerNumber, Image, Text, SubContainer, TextoResaltado } from "./styles";



type Props = {
    key: number;
    card: {
        img: string;
        number: number;
        descResaltado: string;
        descFirst: string;
        descSecond?: string;
    }
}

export const Card = ({ card }: Props) => {
    return (
        <CardContainer>
            <SubContainer>
            <Image 
                alt={`${card.descFirst}${card.descResaltado}${card.descSecond}`}
                src={card.img}
            />
            <ContainerNumber>
                {card.number}
            </ContainerNumber>
            <Text>
                {card.descFirst}
                <TextoResaltado>{card.descResaltado}</TextoResaltado>
                {card.descSecond}
            </Text>
            </SubContainer>
        </CardContainer>
    )
}