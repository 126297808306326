import { useEffect, useState, SetStateAction } from 'react';
import { Container, Title, Subtitle, FaceContainer, SubContainerFace, RoundedContainer, Emoji, Number } from './styles';
import EmojiCero from '../../../assets/images/encuestas/EmojiCero.svg'
import EmojiUno from '../../../assets/images/encuestas/EmojiUno.svg'
import EmojiDos from '../../../assets/images/encuestas/EmojiDos.svg'
import EmojiTres from '../../../assets/images/encuestas/EmojiTres.svg'
import EmojiCuatro from '../../../assets/images/encuestas/EmojiCuatro.svg'

type Props = {
  created_at: string;
  id: number;
  question: string;
  sort: number;
  survey_id: number;
  survey_question_type_id: number;
  updated_at: string;
}

const initialState = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false
}

export const SatisfactionQuestion = ({ element, idx, handleChange, state }: any) => {

  const [value, setValue] = useState<SetStateAction<string>>('');
  const [selected, setSelected] = useState(initialState)

  const { success } = state;
  const { question } = element;

  const handleSelect = (id: number) => {

    Object.entries(selected).forEach(([key, value]) => {
      if (key === id.toString() && value === false) {
        setSelected({
          ...selected,
          [id]: true
        })
        setValue(id.toString())
      }
    })
    Object.entries(selected).forEach(([key, value]) => {
      if (key !== id.toString() && value === true) {
        setSelected({
          ...selected,
          [key]: false,
          [id]: true
        })
        setValue(id.toString())
      }
    })
    Object.entries(selected).forEach(([key, value]) => {
      if (key === id.toString() && value === true) {
        setSelected({
          ...selected,
          [key]: false
        })
        setValue('')
      }
    })
  }

  useEffect(() => {
    handleChange(idx, value)
  }, [value])

  useEffect(() => {
    if (success) {
      setSelected(initialState)
    }
  }, [success])

  return (
    <Container>
      <Title>{question}</Title>
      <Subtitle>Marcá una opción del 1 al 5</Subtitle>
      <FaceContainer>
        <SubContainerFace>
          <RoundedContainer selected={selected[1]} onClick={() => handleSelect(1)}>
            <Emoji 
              alt='Emoji Image'
              src={EmojiCero}
            />
          </RoundedContainer>
          <Number selected={selected[1]}>1</Number>
        </SubContainerFace>
        <SubContainerFace>
          <RoundedContainer selected={selected[2]} onClick={() => handleSelect(2)}>
            <Emoji
              alt='Emoji Image'
              src={EmojiUno}
            />
          </RoundedContainer>
          <Number selected={selected[2]}>2</Number>
        </SubContainerFace>
        <SubContainerFace>
          <RoundedContainer selected={selected[3]} onClick={() => handleSelect(3)}>
            <Emoji
              alt='Emoji Image'
              src={EmojiDos}
            />
          </RoundedContainer>
          <Number selected={selected[3]}>3</Number>
        </SubContainerFace>
        <SubContainerFace>
          <RoundedContainer selected={selected[4]} onClick={() => handleSelect(4)}>
            <Emoji
              alt='Emoji Image'
              src={EmojiTres}
            />
          </RoundedContainer>
          <Number selected={selected[4]}>4</Number>
        </SubContainerFace>
        <SubContainerFace>
          <RoundedContainer selected={selected[5]} onClick={() => handleSelect(5)}>
            <Emoji
              alt='Emoji Image'
              src={EmojiCuatro}
            />
          </RoundedContainer>
          <Number selected={selected[5]}>5</Number>
        </SubContainerFace>
      </FaceContainer>
    </Container>
  )

}