import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

type ButtonProps = {
  isReserve?: boolean;
};

export const Container = styled(Box)(({ theme }) => ({
  height: "50px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5rem",
  marginBottom: "5rem",
  [theme.breakpoints.down("md")]: {
    position: "fixed",
    bottom: 0,
    zIndex: theme.zIndex.appBar,
    marginBottom: "1rem",
    marginTop: 0,
  },
}));

export const Button = styled("button")<ButtonProps>(({ isReserve }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 20px",
  width: "155px",
  height: "49px",
  background: `${isReserve ? "#3366CC" : "#F2C94C"}`,
  color: `${isReserve ? "#FFFFFF" : "#000000"}`,
  marginLeft: `${isReserve ? "10px" : ""}`,
  borderRadius: "86px",
  border: "none",
  outline: "none",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "19px",
  cursor: "pointer",
  transition: "all .3s ease",
  "&:hover": {
    background: `${isReserve ? "#103F63" : "#B18C06"}`,
  },
}));
