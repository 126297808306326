import { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import { RootState } from "../../redux/store"
import { useNavigate } from "react-router-dom"
import {
  Container,
  Title,
  FlexContainer,
  TextContainer,
  Text,
  Icon,
  ImagesContainer,
  Images,
  ButtonsContainer,
  Botones,
} from "./styles"
import IconEdit from "../../assets/images/profile/EditIcon.svg"
import moment from "moment"
import "moment/locale/es"
import { makeReservation, setStep } from "../../redux/reservas/reservasSlice"
import { Spinner } from "../Spinner"

export const ReserveConfirm = () => {
  moment.locale("es")

  const [toShow, setToShow] = useState<any>({
    day: "",
    date: "",
    hour: "",
    tour: "",
    participants: "",
    grade: "",
    institution: "",
    pathsImg: [],
    pathsName: [],
  })

  const {
    day,
    date,
    hour,
    tour,
    participants,
    grade,
    institution,
    pathsImg,
    pathsName,
  } = toShow

  const dispatch = useAppDispatch()
  const { reserva, success, loading } = useAppSelector(
    (state: RootState) => state.reservas
  )
  const { tours } = useAppSelector((state: RootState) => state.tours)
  const { grades } = useAppSelector((state: RootState) => state.StateGrades)
  const user = useAppSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  const formatToRender = (reserve: any, grades: any) => {
    const writeDay = moment(reserve.date).format("dddd")
    const firstLetterUppercase =
      writeDay.charAt(0).toUpperCase() + writeDay.substring(1)
    const dateDay = moment(reserve.date).format("DD/MM")

    let imagePaths: string[] = []
    let namePaths: string[] = []

    const gradeName = grades.find((el: any) => {
      if (el.id === reserve.owner.grade_id) return el
    })

    reserve.recorrido.map((el: any) => {
      if (reserva.tour_paths.includes(el.id)) {
        imagePaths.push(el.img)
      }
    })

    reserve.recorrido.map((el: any) => {
      if (reserva.tour_paths.includes(el.id)) {
        namePaths.push(el.title)
      }
    })

    setToShow({
      day: firstLetterUppercase,
      date: dateDay,
      hour: reserve.hour,
      tour: tours[0].name,
      participants: reserve.owner.participants,
      institution: reserve.owner.institution,
      grade: gradeName.name,
      pathsImg: imagePaths,
      pathsName: namePaths,
    })
  }

  const goRoute = () => {
    dispatch(setStep(3))
    navigate("/reserva-tour")
  }

  const goCalendar = () => {
    dispatch(setStep(0))
    navigate("/reserva-tour")
  }

  const goForm = () => {
    dispatch(setStep(2))
    navigate("/reserva-tour")
  }

  useEffect(() => {
    formatToRender(reserva, grades)
  }, [])

  const handleMakeReservation = async () => {
    const hora = reserva.hour.split("h")
    const fechaCompleta = `${reserva.date} ${hora[0]}`
    const body = {
      date: reserva.date_id,
      tour: tours[0].id,
      token: user.token,
      city: reserva.owner.city,
      cue: reserva.owner.cue,
      grade_id: reserva.owner.grade_id,
      institution: reserva.owner.institution,
      participants: reserva.owner.participants,
      state_id: reserva.owner.state_id,
      tour_paths: reserva.tour_paths,
    }

    await dispatch(makeReservation(body))
  }

  useEffect(() => {
    if (success) {
      navigate("/reserva/reserva-exitosa")
    }
  }, [success])

  return (
    <Container>
      <Title>Vas a reservar:</Title>
      <FlexContainer>
        <TextContainer>
          <Text>
            {day} {date} - {hour}
          </Text>
          <Text>Tour {tour}</Text>
        </TextContainer>
        <Icon alt="edit icon" src={IconEdit} onClick={goCalendar} />
      </FlexContainer>
      <FlexContainer>
        <TextContainer>
          <Text>Capacidad: {participants}/30</Text>
          <Text>Grado: {grade}</Text>
          <Text>Colegio: {institution}</Text>
        </TextContainer>
        <Icon alt="edit icon" src={IconEdit} onClick={goForm} />
      </FlexContainer>
      <FlexContainer>
        <TextContainer>
          <ImagesContainer>
            {pathsImg?.map((el: string, index: number) => (
              <Images alt="Imagen" src={el} key={index} />
            ))}
          </ImagesContainer>
          <Text>Recorrido:</Text>
          <Text>
            {pathsName[1]
              ? `${pathsName[0]} + ${pathsName[1]}`
              : `${pathsName[0]}`}
          </Text>
        </TextContainer>
        <Icon alt="edit icon" src={IconEdit} onClick={goRoute} />
      </FlexContainer>
      <ButtonsContainer>
        <Botones variant="outlined" color="primary" fullWidth onClick={goRoute}>
          Volver
        </Botones>
        <Botones
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleMakeReservation}
          disabled={loading}
        >
          {!loading ? (
            "Continuar"
          ) : (
            <Spinner color="#FFF" height={25} width={25} />
          )}
        </Botones>
      </ButtonsContainer>
    </Container>
  )
}
