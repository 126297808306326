

import { Container, SubContainer, Text, NenaVerdeAnim, ContactLink } from "./styles";

import NenaVerde from '../../../../assets/gifs/nenaVerde.webm';

export const Footer = () => {
  return (
    <Container>
      <SubContainer>
        <Text>¿Tenes dudas?</Text>
        <ContactLink to='/contacto'>CONTACTATE CON NOSOTROS</ContactLink>
      </SubContainer>
      <NenaVerdeAnim 
        src={NenaVerde}
        loop
        muted
      />
    </Container>
  );
};
