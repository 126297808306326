import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FondoMobile from "../../assets/images/confirmReserve/FondoMobile.svg";
import FondoDesktop from "../../assets/images/confirmReserve/FondoDesktop.svg";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh !important",
  backgroundImage: `url(${FondoMobile})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  justifyContent: "spacer-between",
  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${FondoDesktop})`,
    backgroundSize: "cover",
  },
}));
