export const information = {
  0: {
    title: "USO Y ACCESO",
    firstP:
      'ARCOR S.A.I.C. (en adelante "ARCOR") ha creado este sitio web para la información de sus usuarios, quienes al ingresar a éste quedarán sujetos a las presentes condiciones generales de uso. ARCOR se reserva el derecho de modificar los términos y condiciones en cualquier momento, motivo por el cual le sugerimos que los verifique periódicamente.',
    secondP:
      "Estos términos se aplicarán a los usuarios y al uso y acceso y al sitio:",
  },
  1: {
    title: "1.- ACEPTACIÓN.",
    firstP:
      "Al usar y/o Visitar este sitio web Usted (i) declara y reconoce que ha leído y comprendido en su totalidad los términos y condiciones descriptos aquí, y (ii) acepta los presentes términos y condiciones en su totalidad, sin limitación o excepción alguna. El sólo acceso al sitio no determina que el usuario sea en forma automática considerado consumidor o cliente.",
  },
  2: {
    title: "2.- OBLIGACIONES DE LOS USUARIOS.",
    firstP:
      "El usuario deberá cumplir con todas las leyes y regulaciones relativas a cualquier producto ofrecido en el sitio. El usuario deberá también cumplir con todas las instrucciones razonables indicadas por ARCOR al usuario en relación al sitio.",
  },
  3: {
    title: "3.- PROPIEDAD INTELECTUAL.",
    firstP:
      "Todos los derechos e intereses de propiedad intelectual e industrial, incluyendo, pero sin limitarse a marcas, patentes, modelos de utilidad, diseños industriales, derechos de autor, como todos los conceptos, sistemas y escritos, gráficos y otro material relacionado con el servicio poseído o creado por ARCOR incluyendo, pero sin limitares al site, permanecerán en exclusiva propiedad de ARCOR. Visto que los derechos de propiedad intelectual se encuentran protegidos por legislación nacional e internacional, el usuario se compromete expresamente a no violar ni infringir los derechos de propiedad intelectual de ARCOR y/o sus sociedades relacionadas y tomará todas las medidas necesarias para preservar y proteger estos derechos.",
    secondP:
      "Información sobre Copyright, propiedad industrial e intelectual. La presentación y todo el contenido de la página de ARCOR están protegidos por derechos de propiedad intelectual e industrial y no podrá ser utilizada en forma diferente a la forma aquí indicada. El acceso y uso de este sitio web no podrá interpretarse como el otorgamiento de una licencia de uso (expresa o tácita) de dichos derechos de propiedad intelectual.",
  },
  4: {
    title: "4.- INFORMACIÓN DEL SITIO.",
    firstP:
      'La información contenida aquí ha sido diseñada para ser lo más amplia y razonable posible. Sin embargo, ARCOR no será responsable por ningún error u omisión contenidos en el sitio web y se reserva el derecho de realizar cambios en cualquier momento, sin previo aviso. Es por ello que, se sugiere que verifique periódicamente la página. Las imágenes no son contractuales ya que las mismas se exhiben con fines publicitarios y son meramente ilustrativas. Podrían existir diferencias entre las imágenes y las dimensiones y características reales de los mismos. El contenido del sitio, así como los productos ofrecidos a través del mismo se proporcionan en base "como si", "como disponible".',
    secondP:
      "ARCOR no otorga ninguna garantía, ya sean expresas o implícitas en relación que el acceso al sitio será ininterrumpido o sin errores. ARCOR realiza sus mejores esfuerzos para que el sitio sea seguro; y que la información del sitio sea completa, precisa, actualizada y oportuna. ARCOR no está asociado ni afiliado con sponsors o productores de websites que estén linkeados a o desde el sitio ARCOR.com, y ARCOR no respalda, representa u otorga garantía alguna respecto del contenido de dichos sitios.",
  },
  5: {
    title: "5.- LIMITACIÓN DE LA RESPONSABILIDAD.",
    firstP:
      "Ninguna sugerencia o información, ya sea oral o por escrito, obtenida por el usuario de ARCOR o a través o desde el sitio, creará garantía alguna. Bajo ninguna circunstancia ARCOR será responsable por los daños que pueda causar a terceros la utilización del sitio o su contenido.",
    secondP:
      "ARCOR tampoco será responsable por las fallas, errores, omisiones, interrupciones, pérdidas de ingresos, pérdidas de información, demoras en las operaciones o transmisión on-line, virus, errores de sistema, o cualquier otro tipo de pérdida que sea consecuencia del uso del sitio. Esta limitación se aplica a pesar de que los daños sean reclamados invocando responsabilidad contractual basada en negligencia, y aunque nuestros representantes o nosotros hayamos sido negligentes aun cuando se nos hubiese advertido de la posibilidad de tales daños.",
  },
  6: {
    title: "6.- POLÍTICA DE ACTUALIZACIÓN.",
    firstP:
      "El presente sitio web podrá ser suprimido en cualquier momento y la información aquí difundida podrá ser objeto de modificación total o parcial en cualquier forma por ARCOR y las empresas mencionadas y sin aviso previo, no generándose en ninguno de los casos derecho a indemnización alguna a favor de terceros, por lo que Ud. es invitado a revisar estos términos y condiciones cada vez que visite esta página.",
  },
  7: {
    title:
      "7.- POLÍTICA DE PRIVACIDAD DE LA INFORMACIÓN SUMINISTRADA POR EL CLIENTE.",
    firstP:
      "La información que Ud. nos suministre será conservada en forma confidencial y solamente será utilizada para fines estadísticos y para hacerle llegar información que podría ser de su interés dentro de los límites de la legislación aplicable.",
    secondP:
      "En este sentido, los datos personales que Usted brinde podrán ser utilizados por ARCOR y sus sociedades vinculadas para realizar campañas publicitarias y/o de marketing tendientes a mantenerlo actualizado sobre nuevos productos, promociones y/o distintos servicios que ofrezca por sí y/o a través de su Red Oficial de Concesionarios y/o Servicios Oficiales.",
    thirdP:
      "Por consiguiente, ARCOR podrá ceder y/o compartir los datos que proporciones con: i) Su red oficial de concesionarios, Agencias de Publicidad, y/o eventos y/o promociones y/o cualquier otra acción de marketing ii) Empresas que le provean cualquier tipo de servicios informáticos y/o de archivo de información electrónica y/o física; iii) Empresas que operen su Centro de Atención al Cliente, iv) Empresas relacionadas o vinculadas a ARCOR. Las empresas mencionadas en los puntos i) a iv) podrán localizar la base de datos en Argentina como en terceros países. ARCOR se compromete a incluir en sus acuerdos con estas empresas, la obligación de respetar las disposiciones de las normas de protección de datos personales vigentes en Argentina, como así también la política de ARCOR en materia de protección de la privacidad, seguridad y confidencialidad de la información.",
    fourthP:
      "Podrás acceder a la información vinculada con tus datos personales, actualizar y/o modificar y/o pedir la supresión de los mencionados datos comunicándose al 0800-333-7362 o de cualquier otra forma que permita una notificación expresa y que deje su constancia en tal sentido. El hecho de que proporciones los datos que te sean requeridos implica aceptación de los términos expuestos en esta política. Te informamos que la Dirección Nacional de Protección de Datos Personales, es el órgano de control de las normas sobre protección de datos personales y tiene la atribución de atender las denuncias y reclamos que se interpongan con relación el incumplimiento de tales normas.",
    fifthP:
      "Ingreso de datos personales de usuario - Disposición 10/08. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. La DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.",
    sixthP:
      "Dirección Nacional de Protección de Datos Personales: Disposición nº: 10/08, Ley 25.326, art. 27, inciso 3º: el titular podrá, en cualquier momento, solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que refiere el presente artículo. Decreto 1158/01, art. 27, párrafo 3º: en toda publicidad que se realice por correo, teléfono, correo electrónico, Internet u otro medio a distancia a conocer, se deberá indicar, en forma expresa y destacada, la posibilidad del titular del dato de solicitar el retiro o bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado se deberá informar el nombre del responsable o usuario del banco de datos que proveyó la información.",
  },
  8: {
    title: "8.- HIPERVÍNCULOS.",
    firstP:
      "El sitio web puede contener textos de hipervínculo con otros sitios de Internet que sean completamente independientes de este. ARCOR no se hace responsable ni garantiza la precisión, amplitud o veracidad de la información contenida en ninguno de dichos hipervínculos u otros sitios de Internet. El acceso a cualquiera de los sitios enlazados con el sitio de ARCOR, lo hace bajo su propia y exclusiva responsabilidad.",
  },
  9: {
    title: "9.- COOKIES. ARCOR",
    firstP:
      'y sus sociedades vinculadas pueden usar "cookies" para rastrear preferencias de usuarios, alcanzar un mejor diseño de su sitio web y procurar una navegación más ágil. También podrán ser utilizadas para realizar en el futuro campañas publicitarias tendientes a mantener actualizado al usuario sobre nuevos productos, promociones y/o distintos servicios que ofrezca por si, o a través de terceras personas.',
    secondP:
      'Las cookies permiten adaptar el contenido de nuestras páginas de Internet a sus necesidades específicas y así también mejorar el servicio que le ofrecemos. Las cookies también pueden ser usadas para determinar si una conexión ya ha sido hecha de su ordenador a nuestros sitios. Desde luego, usted también puede ver nuestro sitio web sin ninguna "cookie". La mayoría de los navegadores aceptan "cookies" automáticamente. Usted puede impedir el uso de "cookies" seleccionando la opción “no aceptar cookies” de su explorador. También puede visitar www.aboutcookies.org, que contiene información detallada de cómo hacerlo en una amplia variedad de navegadores.',
  },
};
