import { Container } from './styles/encuestaPageStyles';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { Encuestas } from '../components/Encuestas';

import ReactGA from "react-ga4";

export const EncuestaPage = () => {

  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Encuesta" });
  
  return (
    <Container>
      <HeaderLayout>
        <Encuestas />
      </HeaderLayout>
    </Container>
  )
}