import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditIProps } from "./intefaces";
import { Reservas } from "../../services/reservasCalls";

const initialState: EditIProps = {
  oldTourBook: {
    id: "",
    name: "",
    date: "",
    hour: "",
    participants: "",
    grade: "",
    tour_code: "",
    access_code: "",
    guide: {
      id: "",
      name: "",
    },
    state_id: "",
    city: "",
    institution: "",
    grade_id: "",
    cue: "",
    paths: [],
  },
  newTourBook: {
    date: "",
    hour: "",
    date_id: 0,
    id: 0,
    participants: "",
  },
  edit: false,
  loading: false,
  success: false,
  error: false,
};

export const getTourBookEdit = createAsyncThunk(
  "editReservas/getTourBookEdit",
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await Reservas.getTourBook(id);
      return response.data.data;
    } catch (error: any) {
      throw rejectWithValue(
        "Ocurrió un error inesperado, intente nuevamente mas tarde"
      );
    }
  }
);

const editReservaSlice: any = createSlice({
  name: "editReservas",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    setHourReservaEdit: (state, action) => {
      state.newTourBook.hour = action.payload.hour;
      state.newTourBook.date = action.payload.date;
    },
    setDateReservaEdit: (state, action) => {
      state.newTourBook.date = action.payload;
    },
    setDateIdReservaEdit: (state, action) => {
      state.newTourBook.date_id = +action.payload;
    },
    setErrorTrue: (state) => {
      state.error = true;
    },
    setErrorFalse: (state) => {
      state.error = false;
    },
    setSuccessFalse: (state) => {
      state.success = false;
    },
    setInitialState: (state) => {
      state.oldTourBook = initialState.oldTourBook;
      state.newTourBook = initialState.newTourBook;
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.success = initialState.success;
    },
    setEdit: (state) => {
      state.edit = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTourBookEdit.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
        state.edit = true;
      })
      .addCase(getTourBookEdit.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.oldTourBook = payload;
        state.newTourBook.date = payload.date;
        state.newTourBook.hour = payload.hour;
      })
      .addCase(getTourBookEdit.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      });
  },
});

export const {
  startLoading,
  setErrorTrue,
  setDateReservaEdit,
  setDateIdReservaEdit,
  setHourReservaEdit,
  setSuccessFalse,
  setErrorFalse,
  setInitialState,
  setEdit,
} = editReservaSlice.actions;

export default editReservaSlice.reducer;
