import { useAppSelector } from "../../../redux/hooks"
import { NextBookings } from "./NextBookings"
import { ScheduledBookings } from "./ScheduledBookings"
import { Container, BookingsContainer } from "./styles"
import { Title } from "./Title"
import { Spinner } from "../../Spinner"
import { UseFetchTourProfile } from "../../../hooks/UseFetchTourProfile"
import { Typography } from "@mui/material"
import { RootState } from "../../../redux/store"
import theme from "../../../theme/index"

export const BookingsSection = () => {
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { profile, loading } = useAppSelector((state) => state.profile)

  UseFetchTourProfile(user)

  const renderContent = () => {
    if (loading) {
      return (
        <Spinner
          width="50px"
          height="50px"
          color={theme.palette.primary.main}
        />
      )
    }

    if (profile.length === 0) {
      return (
        <Typography
          fontFamily="Raleway"
          fontWeight={500}
          variant="h4"
          fontSize={theme.typography.fontSize * 1.2}
        >
          No hay tours reservados
        </Typography>
      )
    }

    return (
      <>
        <NextBookings profile={profile} />
        <ScheduledBookings profile={profile} />
      </>
    )
  }

  return (
    <Container>
      <Title />
      <BookingsContainer isEmpty={profile.length === 0} loading={loading}>
        {renderContent()}
      </BookingsContainer>
    </Container>
  )
}
