import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { Navigate, Outlet } from "react-router-dom";


export const PrivateRoutes = () => {

  const { token } = useAppSelector(
    (state: RootState) => state.auth
  );


  return token ? <Outlet /> : <Navigate to="/auth/login" />;
}