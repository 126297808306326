import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "2rem auto 0px auto",
  [theme.breakpoints.up("md")]: {
    width: "90%",
    justifyContent: "space-between",
  },
  [theme.breakpoints.up(1100)]: {
    width: "80%",
  },
}));

export const ImgNeneTomate = styled("img")(({ theme }) => ({
  width: "150px",
  height: "120px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const ImagenViaje = styled("img")(({ theme }) => ({
  width: "300px",
  [theme.breakpoints.up("md")]: {
    width: "352px",
  },
}));

export const ImgBotonReserva = styled("img")(({ theme }) => ({
  width: "150px",
  height: "150px",
  cursor: "pointer",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.standard,
  }),
  "&:hover": {
    transform: "rotate(14deg)",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
