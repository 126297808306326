import { Container, TitleText, TitleImage, BoyImage } from './styles'
import universoArcor from '../../../assets/images/profile/universoArcor.svg';
import imageBoy from '../../../assets/images/profile/ImageBoy.svg';

export const Title = () => {
  return (
    <Container>
      <TitleText>Bienvenido a</TitleText>
      <TitleImage
        alt='Universo Arcor'
        src={universoArcor}
      />
      <BoyImage
        alt='Boy Image'
        src={imageBoy}
      />
    </Container>
  )
}