import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";


export const ContainerTitle = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px 0px 20px 0px",
  }));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontWeight: "400",
  fontSize: "45px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#3366CC",
  marginBottom: "10px",
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16.9px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#000000",
  marginBottom: "20px",
  [theme.breakpoints.up("md")]: {
    width: "60%",
  },
}));

export const ButtonStyle = styled(Button)(() => ({
  padding: "12px 40px 12px 40px",
  borderRadius: "86px",
  fontSize: "16px",
  fontWeight: "700",
  fontFamily: "Raleway",
  textTransform: "uppercase",
}));

