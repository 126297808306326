import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { nextStep } from '../../redux/reservas/reservasSlice';
import { Titulo, Container, Subtitle, NextButton } from './styles/titleStyles';



export const Title = () => {

    const { edit } = useAppSelector((state: any) => state.editTour);
    const dispatch = useAppDispatch();

    const handleNextStep = () => {
        dispatch(nextStep())
    }

    return (
        <Container>
            <Titulo>{edit ? "Elegi un nuevo DIA" : "Elegí el DÍA"}</Titulo>
            <Subtitle>Estos son los proxímos días disponibles:</Subtitle>
            {edit && <NextButton variant='contained' color='primary' onClick={handleNextStep}>Siguiente</NextButton>}
        </Container>
    )
};