import { useRef, useState } from "react";
import {
  Container,
  Carousel,
  DotsContainer,
  Dots,
  Flechas,
} from './carrouselStyles';
import { Card } from "../Card/Card";

import ImageUno from "../../../../../assets/images/Home/imageUno.svg";
import ImageDos from "../../../../../assets/images/Home/imageDos.svg";
import ImageTres from "../../../../../assets/images/Home/imageTres.svg";
import Flecha from "../../../../../assets/images/Home/Flecha.svg";

export const Carrousel = () => {
  const [count, setCount] = useState(0);
  const carousel = useRef<any>();

  const values = [
    {
      img: ImageUno,
      number: 1,
      descResaltado: 'RESERVÁ TU VISITA',
      descFirst: "EN UN DIA Y HORARIO",
    },
    {
      img: ImageDos,
      number: 2,
      descResaltado: "MAIL DE CONFIRMACIÓN",
      descFirst: "TE LLEGARÁ UN",
      descSecond: "CON EL DIA Y HORARIO DE TU RESERVA"
    },
    {
      img: ImageTres,
      number: 3,
      descResaltado: "UN GUÍA VIRTUAL ARCOR",
      descFirst: "TE RECIBIRÁ",
      descSecond: "PARA LLEVARTE EN EL RECORRIDO"
    },
  ];

  const handleChangeCarousel = (number: number) => {
    if (!carousel.current) return;

    const width = carousel.current.offsetWidth;

    if (count + number > values.length - 1) {
      setCount(0);
      carousel.current.scrollTo(0, 0);
      return;
    } else if (count + number < 0) {
      setCount(values.length - 1);
      carousel.current.scrollTo(width * values.length - 1, 0);
      return;
    }

    carousel.current.scrollTo(carousel.current.scrollLeft + width * number, 0);
    setCount((c) => c + number);
  };

  return (
    <>
      <Container>
        <Flechas
          alt="Flecha"
          src={Flecha}
          left={true}
          onClick={() => handleChangeCarousel(-1)}
        />
        <Carousel ref={carousel}>
          {values.map((element) => (
            <Card key={element.number} card={element} />
          ))}
        </Carousel>
        <Flechas
          alt="Flecha"
          src={Flecha}
          onClick={() => handleChangeCarousel(1)}
        />
      </Container>
      <DotsContainer>
        <Dots checked={count === 0 ? true : false}></Dots>
        <Dots checked={count === 1 ? true : false}></Dots>
        <Dots checked={count === 2 ? true : false}></Dots>
      </DotsContainer>
    </>
  );
};
