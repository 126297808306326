import { useEffect } from "react"
import { getTourProfile } from "../redux/profile/profileSlice"
import { useAppDispatch } from "../redux/hooks"

export const UseFetchTourProfile = (user: any) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const fetchTourProfile = async () => {
      try {
        const token = user?.access_token || sessionStorage.getItem("token")
        await dispatch(getTourProfile(token))
      } catch (error) {
        console.error("Error fetching tour profile:", error)
      }
    }

    fetchTourProfile()
  }, [user, dispatch])
}
