import { useEffect } from "react";
import { Formik } from "formik";
import { TextField, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { registerSchema } from "./registerSchema";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { registerUser, resetState } from "../../redux/auth/authSlice";
import { RootState } from "../../redux/store";

import { Spinner } from "../Spinner";
import { sweetAlertMixin } from "../../helpers/sweetAlert";

import {
  ContainerPage,
  Container,
  ContImgTitle,
  ContainerTitle,
  Title,
  Subtitle,
  ContainerImage,
  Img,
  ContainerForm,
  ButtonStyle,
  ContainerLinks,
  Links,
  DividerBottom,
  Text,
} from "./styles";

import Image from "../../assets/images/auth/ImageRegister.svg";

interface FormValues {
  name: string;
  email: string;
  password: string;
  lastname: string;
  document_number: string;
  password_confirmation: string;
  telephone: string;
}

export const RegisterForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading, success, error } = useAppSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    if (success && !isMobile) {
      sweetAlertMixin(
        "success",
        "Se registro correctamente, te enviamos un email para validar tu correo."
      );
      navigate("/auth/login");
      dispatch(resetState());
    }
  }, [navigate, success, isMobile, dispatch]);

  useEffect(() => {
    if (error.length > 0) {
      sweetAlertMixin("error", `${error}`);
      dispatch(resetState());
    }
  }, [error, dispatch]);

  const initialValues: FormValues = {
    name: "",
    email: "",
    lastname: "",
    password: "",
    document_number: "",
    password_confirmation: "",
    telephone: "",
  };

  return (
    <ContainerPage success={success}>
      <Container>
        <ContImgTitle>
          <ContainerTitle>
            <Title>Registrate</Title>
            <Subtitle>
              Para realizar tu reserva primero tenés que crear una cuenta.
            </Subtitle>
          </ContainerTitle>
          <ContainerImage>
            <Img src={Image} alt="Imagen de Frutas" />
          </ContainerImage>
        </ContImgTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={registerSchema}
          onSubmit={async (values) => await dispatch(registerUser(values))}
        >
          {({ getFieldProps, errors, touched }) => (
            <ContainerForm>
              <TextField
                sx={{ marginBottom: "1rem" }}
                type="text"
                id="name"
                {...getFieldProps("name")}
                label="Nombre"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name ? errors.name : null}
                fullWidth
              />

              <TextField
                sx={{ marginBottom: "1rem" }}
                id="lastname"
                type="text"
                {...getFieldProps("lastname")}
                label="Apellido"
                error={touched.lastname && Boolean(errors.lastname)}
                helperText={
                  touched.lastname && errors.lastname ? errors.lastname : null
                }
                fullWidth
              />

              <TextField
                sx={{ marginBottom: "1rem" }}
                id="email"
                {...getFieldProps("email")}
                type="email"
                label="Email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email ? errors.email : null}
                fullWidth
              />

              <TextField
                sx={{ marginBottom: "1rem" }}
                id="document_number"
                {...getFieldProps("document_number")}
                type="text"
                label="DNI"
                error={
                  touched.document_number && Boolean(errors.document_number)
                }
                helperText={
                  touched.document_number && errors.document_number
                    ? errors.document_number
                    : null
                }
                fullWidth
              />

              <TextField
                sx={{ marginBottom: "1rem" }}
                id="telephone"
                {...getFieldProps("telephone")}
                type="phone"
                label="Teléfono"
                error={
                  touched.telephone &&
                  Boolean(errors.telephone)
                }
                helperText={
                  touched.telephone && errors.telephone
                    ? errors.telephone
                    : null
                }
                fullWidth
              />

              <TextField
                sx={{ marginBottom: "1rem" }}
                id="password"
                {...getFieldProps("password")}
                type="password"
                label="Contraseña"
                error={touched.password && Boolean(errors.password)}
                helperText={
                  touched.password && errors.password ? errors.password : null
                }
                fullWidth
              />

              <TextField
                sx={{ marginBottom: "1rem" }}
                id="password_confirmation"
                {...getFieldProps("password_confirmation")}
                type="password"
                label="Repetir - Contraseña"
                error={
                  touched.password_confirmation &&
                  Boolean(errors.password_confirmation)
                }
                helperText={
                  touched.password_confirmation && errors.password_confirmation
                    ? errors.password_confirmation
                    : null
                }
                fullWidth
              />

              <ButtonStyle
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{ textTransform: "none" }}
                type="submit"
              >
                {loading ? (
                  <Spinner color="#000" height={25} width={25} />
                ) : (
                  "REGISTRARSE"
                )}
              </ButtonStyle>

              {!isMobile && (
                <ContainerLinks>
                  <Links to="/auth/login">Tenés usuario? inicia sesión</Links>
                </ContainerLinks>
              )}
            </ContainerForm>
          )}
        </Formik>

        {isMobile && (
          <>
            <DividerBottom flexItem />
            <Text>¿Ya tenes cuenta?</Text>
            <ButtonStyle
              variant="outlined"
              sx={{ marginBottom: "25px" }}
              onClick={() => navigate("/auth/login")}
            >
              INICIA SESION
            </ButtonStyle>
          </>
        )}
      </Container>
    </ContainerPage>
  );
};
