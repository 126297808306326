import React, { useRef, useState } from "react"
import { Container, DotsContainer, Dots, Carousel, Flechas } from "./styles"
import { CardHome } from "../CardHome"

import GifUno from "../../../assets/gifs/Plantas.mp4"
import GifDos from "../../../assets/gifs/Biblioteca.mp4"
import GifTres from "../../../assets/gifs/Caroya.mp4"

import Flecha from "../../../assets/images/Home/Flecha.svg"

export const Carrousel = () => {
  const [count, setCount] = useState(0)

  const carousel = useRef<any>()

  const values = [
    {
      src: GifUno,
      title: "Conocé",
      desc: "LA HISTORIA DE LOS ALIMENOS",
      id: "1",
    },
    {
      src: GifDos,
      title: "Disfrutá",
      desc: "DE UNA EXPERIENCIA VITUAL ÚNICA",
      id: "2",
    },
    {
      src: GifTres,
      title: "Descubrí",
      desc: "UNA GUIA 360 POR NUESTRAS PLANTAS",
      id: "3",
    },
  ]

  const handleChangeCarousel = (number: number) => {
    if (!carousel.current) return

    const width = carousel.current.offsetWidth

    if (count + number > values.length - 1) {
      setCount(0)
      carousel.current.scrollTo(0, 0)
      return
    } else if (count + number < 0) {
      setCount(values.length - 1)
      carousel.current.scrollTo(width * values.length - 1, 0)
      return
    }

    carousel.current.scrollTo(carousel.current.scrollLeft + width * number, 0)
    setCount((c) => c + number)
  }

  return (
    <>
      <Container>
        <Flechas
          alt="Flecha"
          src={Flecha}
          left={true}
          onClick={() => handleChangeCarousel(-1)}
        />
        <Carousel ref={carousel}>
          {values.map((element, index) => (
            <CardHome
              key={index}
              element={element}
              count={count}
              setCount={setCount}
              handleChangeCarousel={handleChangeCarousel}
            />
          ))}
        </Carousel>
        <Flechas
          alt="Flecha"
          src={Flecha}
          onClick={() => handleChangeCarousel(1)}
        />
      </Container>
      <DotsContainer>
        <Dots checked={count === 0 ? true : false}></Dots>
        <Dots checked={count === 1 ? true : false}></Dots>
        <Dots checked={count === 2 ? true : false}></Dots>
      </DotsContainer>
    </>
  )
}
