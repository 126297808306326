import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { logout } from "../../redux/auth/authSlice"
import { RootState } from "../../redux/store"
import { useNavigate } from "react-router-dom"
import { Box, Menu, MenuItem } from "@mui/material"
import { AvatarHeader, Links } from "./styles/menuHeader"
import { setInitiState } from "../../redux/reservas/reservasSlice"
import { setEdit } from "../../redux/reservas/editReservaSlice"

export const MenuHeader = () => {
  const { user } = useAppSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const nameAvatar = user.user.name.charAt(0)

  const handleLogout = () => {
    dispatch(setInitiState())
    dispatch(setEdit())
    dispatch(logout())
    navigate("/")
  }

  const handleResetState = () => {
    dispatch(setEdit())
    dispatch(setInitiState())
    // dispatch(setInitialState());
  }

  return (
    <Box>
      <AvatarHeader
        onClick={handleClick}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {nameAvatar}
      </AvatarHeader>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Links to="/user/profile" onClick={handleResetState}>
            Perfil
          </Links>
        </MenuItem>
        <MenuItem onClick={handleLogout}>Cerrar Sesion</MenuItem>
      </Menu>
    </Box>
  )
}
