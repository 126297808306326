import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { sweetAlertMixin } from "../../helpers/sweetAlert"
import { EncuestaForm } from "../../services/encuestaApiCalls"
import { Spinner } from "../Spinner"
import { SatisfactionQuestion } from "./SatisfactionQuestion"
import { Container, CardContainer, SendButton, ErrorText } from "./styles"
import { TextQuestion } from "./TextQuestion"

type Props = {
  created_at: string
  id: number
  question: string
  sort: number
  survey_id: number
  survey_question_type_id: number
  updated_at: string
}

type StateProps = {
  success: boolean
  error: boolean
  loading: boolean
  loadingData: boolean
}

export const Encuestas = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [questions, setQuestions] = useState<Props[]>([])
  const [values, setValues] = useState<any>({})
  const [ids, setIds] = useState<number[]>([])
  const [state, setState] = useState<StateProps>({
    success: false,
    error: false,
    loading: false,
    loadingData: true,
  })
  const [emptyInput, setEmptyInput] = useState(false)

  const { success, loading, error, loadingData } = state

  const getEncuesta = async (id: string | undefined) => {
    setState({ ...state, loadingData: true })
    const res = await EncuestaForm.getEncuesta(id)
    const response = res.data.data.survey_questions.sort(
      (a: any, b: any) => a.sort - b.sort
    )
    setQuestions(response)
    const questionIds = response.map((element: Props) => element.id)
    setIds(questionIds)
    setState({ ...state, loadingData: false })
  }

  useEffect(() => {
    getEncuesta(id)
  }, [])

  const handleChange = (idx: number, value: string) => {
    setValues({ ...values, [idx]: value })
  }

  const handleSubmit = async () => {
    const filterAnswers = Object.keys(values).map((key) => values[key])
    if (filterAnswers.includes("") || filterAnswers.length !== ids.length) {
      setEmptyInput(true)
      return
    } else {
      setEmptyInput(false)
    }
    setState({ ...state, loading: true })
    const bodyParameters = {
      survey_reply_id: id,
      questions_ids: ids,
      answer: filterAnswers,
    }
    const response = await EncuestaForm.postEncuesta(bodyParameters)
    if (response.status === 200) {
      setValues({})
      setState({ ...state, loading: false, success: true })
      sweetAlertMixin("success", "Gracias por la respuesta")
      navigate("/")
    } else {
      setState({ ...state, loading: false, error: true })
    }
  }

  useEffect(() => {
    if (error) {
      sweetAlertMixin("error", "Occurió un error, intente nuevamente mas tarde")
    }
  }, [error])

  return (
    <Container>
      {loadingData ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner color="#3366CC" width="150px" height="150px" />
        </div>
      ) : (
        <CardContainer>
          {questions.map((element: Props, idx: number, index) => {
            if (element.survey_question_type_id === 2) {
              return (
                <SatisfactionQuestion
                  key={index}
                  element={element}
                  idx={idx}
                  handleChange={handleChange}
                  state={state}
                />
              )
            } else {
              return (
                <TextQuestion
                  key={index}
                  element={element}
                  idx={idx}
                  handleChange={handleChange}
                  state={state}
                />
              )
            }
          })}
          {emptyInput && (
            <ErrorText>Todos los campos son obligatorios</ErrorText>
          )}
          <SendButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner width="30px" height="30px" color="#000" />
            ) : (
              "ENVIAR"
            )}
          </SendButton>
        </CardContainer>
      )}
    </Container>
  )
}
