import { useMediaQuery } from "@mui/material"
import { HeaderLayout } from "../../../layouts/HeaderLayout";
import { Card } from "./Card";


export const ReservaSuccess = () => {

    const isMobile = useMediaQuery("(max-width:900px)");

    return (
        <>
            {
                isMobile
                    ?
                    <Card />
                    :
                    <HeaderLayout>
                        <Card />
                    </HeaderLayout>
            }
        </>
    )
}