import { Container, Imagen, TitleContainer, Title, Description, CheckBoxContainer, CheckBoxText, CheckBox, ContainerCircle, CheckBoxIcon } from './styles';


export const Card = ({ element, handleSetRecorrido }: any) => {

  const { img, title, description, propsColor, selected, checkIcon} = element;

  return (
    <Container>
      <ContainerCircle
        selected={selected}
        backgroundcolor={propsColor.backgroundcolor}
      >
      </ContainerCircle>
      <Imagen
        alt='Imagen Arcor'
        src={img}
      />
      <TitleContainer
        selected={selected}
        bordertitlecolor={propsColor.bordertitlecolor}
      >
        <Title
          selected={selected}
          titlecolor={propsColor.titlecolor}
        >
          {title}
        </Title>
      </TitleContainer>
      <Description
        selected={selected}
        descriptioncolor={propsColor.descriptioncolor}
      >
        {description}
      </Description>
      <CheckBoxContainer>
        <CheckBoxText
          selected={selected}
          textcheckboxcolor={propsColor.textcheckboxcolor}
        >
          SELECCIONAR
        </CheckBoxText>
        {selected ?
          <CheckBoxIcon
            alt='check icon'
            src={checkIcon}
            onClick={() => handleSetRecorrido(element)}
          />
          :
          <CheckBox
            onClick={() => handleSetRecorrido(element)}
            selected={selected}
            checkboxcolor={propsColor.checkboxcolor}
          >
          </CheckBox>
        }
      </CheckBoxContainer>
    </Container>
  )
}