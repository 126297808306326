import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

type DotsProps = {
  checked?: boolean;
};

type FlechaProps = {
  left?: boolean;
};

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100vw",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  [theme.breakpoints.up("lg")]: {
    width: "700px",
  },
}));

export const Carousel = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  overflowX: "scroll",
  scrollSnapType: "x mandatory",
  scrollBehavior: "smooth",
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
}));

export const DotsContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2.8rem",
  marginBottom: "4.6rem",
}));

export const Dots = styled(Box)<DotsProps>(({ theme, checked }) => ({
  width: "17.92px",
  height: "17.92px",
  borderRadius: "50%",
  backgroundColor: `${checked ? "#3366CC" : "#C0C0C0"}`,
  margin: "0px 4.5px 0px 4.5px",
  transition: "all 0.5s ease-in-out",
}));

export const Flechas = styled("img")<FlechaProps>(({ theme, left }) => ({
  height: "30px",
  width: "30px",
  marginLeft: `${left ? "30px" : null}`,
  marginRight: `${!left ? "30px" : null}`,
  transform: `${left ? "rotate(180deg)" : null}`,
  position: "absolute",
  top: "50%",
  cursor: "pointer",
  left: `${left && "-10px"}`,
  right: `${!left && "-10px"}`,
  zIndex: "10000",
  [theme.breakpoints.down("sm")]: {
    left: `${left && "-20px"}`,
    right: `${!left && "-20px"}`,
  },
  [theme.breakpoints.up("sm")]: {
    left: `${left && "10%"}`,
    right: `${!left && "10%"}`,
  },
  [theme.breakpoints.up("md")]: {
    left: `${left && 0}`,
    right: `${!left && 0}`,
  },
  [theme.breakpoints.up(1200)]: {
    left: `${left && "-5%"}`,
    right: `${!left && "-5%"}`,
  },
}));
