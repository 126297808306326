import { Container, Dividers, ContainerBody, PalomaVideo } from './styles';
import { Description } from './Description';
import { Steps } from './Steps';
import { Footer } from './Footer';

import Paloma from '../../../assets/gifs/Paloma.webm';

export const Body = () => {
    return(
        <Container>
            <ContainerBody>
                <Description />
                <Dividers flexItem />
                <Steps />
                <PalomaVideo 
                    src={Paloma}
                    loop
                    muted
                />
            </ContainerBody>
            <Dividers flexItem />
            <Footer />
        </Container>
    )
}