import { useEffect, useState } from 'react';
import { Container, Subtitle } from './styles';
import { TextField } from '@mui/material';

type Props = {
  created_at: string;
  id: number;
  question: string;
  sort: number;
  survey_id: number;
  survey_question_type_id: number;
  updated_at: string;
}


export const TextQuestion = ({ element, idx, handleChange, state }: any) => {

  const { success } = state;

  const { question } = element;

  useEffect(() => {
    if (success) {

    }
  }, [success])

  return (
    <Container>
      <Subtitle>{question}</Subtitle>
      <TextField
        id="mensaje"
        name="mensaje"
        onChange={(ev) => handleChange(idx, ev.target.value)}
        // onBlur={handleBlur}
        type="text"
        label="Danos tu opinion..."
        multiline
        rows={4}
        maxRows={4}
        // error={touched.mensaje && Boolean(errors.mensaje)}
        // helperText={
        // touched.mensaje && errors.mensaje ? errors.mensaje : null
        // }
        fullWidth
      />
    </Container>
  )
}