import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px',
    width: '100%',
    height: '100%',
    flex: '1 0 100%',
    scrollSnapAlign: 'start',
    [theme.breakpoints.up('md')]:{
        marginRight: '200px',
    }
}));

export const Image = styled('img')(() => ({
    width: '200px',
    height: '200px'
}));

export const SubContainer = styled(Box)(({theme}) => ({
    background: '#D1F1FF',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
    borderRadius: '20px',
    width: '300px',
    maxWidth: '500px',
    height: '450px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up(1200)]:{
        width: '100%',
        maxWidth: '615px',
        height: '260px',
        flexDirection: 'row',
        justifyContent: 'center',
    }
}))


export const ContainerNumber = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    width: '40px',
    height: '40px',
    background: '#F2C94C',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
    borderRadius: '63px',
    color: '#333333',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '149.4%',
    margin: '1.6rem 0px 2.3rem 0px',
    fontFamily: 'Raleway'
}));

export const Text = styled(Typography)(() => ({
    fontSize: '1.25rem',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '23px',
    textAlign: 'center',
    color: '#3366CC',
    padding: "0px 20px",
    marginBottom: '2.1rem',
    width: '250px'
}));


export const TextoResaltado = styled(Typography)(() => ({
    fontSize: '1.25rem',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '23px',
    textAlign: 'center',
    color: "#3366CC"
}));