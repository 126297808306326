import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

type TextProps = {
  oldBook?: boolean;
  title?: boolean;
};

export const Container = styled(Box)(({ theme }) => ({
  width: "80vw",
  height: "60vh",
  background: "#FFFFFF",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "30px",
  [theme.breakpoints.up("sm")]: {
    width: "260px",
    height: "420px",
  },
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#000000",
  width: "200px",
  textAlign: "center",
}));

export const LastBookContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingBottom: "30px",
  borderBottom: "4px solid #3366CC",
  gap: "3px",
}));

export const NewBookContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingBottom: "30px",
  gap: "3px",
}));

export const Text = styled(Typography)<TextProps>(({ oldBook }) => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "21px",
  color: oldBook ? "#9C9C9C" : "#000000",
}));

export const TitleText = styled(Typography)<TextProps>(({ oldBook }) => ({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "21px",
  color: oldBook ? "#9C9C9C" : "#000000",
}));

export const ButtonsContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
}));

export const ButtonStyled = styled(Button)(() => ({
  padding: "10px 30px",
  gap: "10px",
  borderRadius: "86px",
}));
