import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "168px",
  backgroundColor: "#66A7DB",
  display: "flex",
  justifyContent: "center",
  zIndex: 1200,
  [theme.breakpoints.up("md")]: {
    height: "56px",
  }
}));

export const SubContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "30px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export const LogoCopyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "15px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export const Logo = styled("img")(() => ({
  width: "51px",
  height: "35px",
}));

export const Text = styled(Typography)(() => ({
  fontFamily: "Roboto",
  fontWeight: 700,
  fontSize: "9.5px",
  textAlign: "center",
  color: "#F2F2F2",
}));

export const TextLink = styled(Link)(() => ({
  fontFamily: "Roboto",
  fontWeight: 700,
  fontSize: "11px",
  textAlign: "center",
  textDecoration: "underline",
  color: "#F2F2F2",
}));
