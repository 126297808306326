import { Btn } from "./styles"
import { useNavigate } from "react-router-dom"

export const BookingButton = () => {
  const navigate = useNavigate()
  return (
    <Btn variant="contained" onClick={() => navigate("/reserva-tour")}>
      RESERVÁ NUEVO TOUR
    </Btn>
  )
}
