import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"
import FondoMobile from "../../assets/images/termsAndCondition/FondoMobile.svg"
import FondoDesktop from "../../assets/images/termsAndCondition/FondoDesktop.svg"

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  backgroundImage: `url(${FondoMobile})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "cover",

  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${FondoDesktop})`,
    backgroundPosition: "top center",
  },
}))
