// import { useMediaQuery } from "@mui/material";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Reservas } from "../../services/reservasCalls";

import BonoBon from "../../assets/images/selectRoute/BonOBon.svg"
import ButterToffes from "../../assets/images/selectRoute/ButterToffes.svg";
import Surtido from "../../assets/images/selectRoute/SurtidoBagley.svg";
import Tomate from "../../assets/images/selectRoute/Tomate.svg";
import Carton from "../../assets/images/selectRoute/Carton.svg";
import Azucar from "../../assets/images/selectRoute/Azucar.svg";
import CheckBlanco from "../../assets/images/selectRoute/checkIcon.svg";
import CheckNegro from "../../assets/images/selectRoute/checkIconBlack.svg";

interface AvailableWeekProps {
  id: number;
  available: boolean;
  date: string;
  hour: string;
}

interface AvailableWeekApiProps {
  id: number;
  available: boolean;
  date: string;
}
interface IProps {
  tours: any;
  available: any;
  hours: any;
  error: string;
  loading: boolean;
}

interface IParameters {
  params: {
    dateFrom: string | null;
    dateTo: string | null;
  };
  token: string | null;
  allDays: any;
}

const initialState: IProps = {
  tours: {},
  available: [],
  hours: [],
  error: "",
  loading: false,
};

export const getAllTours = createAsyncThunk("tours/getAll", async () => {
  try {
    const response = await Reservas.getAllToursCall();
    const formatRoutes = response.data.data[0].paths.map((element: any) => {
      switch (element.name) {
        case "Bon o Bon":
          return {
            ...element,
            title: element.name,
            description: "Descubrí cómo producimos el bon o bon en la planta industrial Arcor Colonia Caroya, Córdoba.",
            img: BonoBon,
            checkIcon: CheckNegro,
            propsColor: {
              backgroundcolor: "#FFA000",
              titlecolor: "#000000",
              bordertitlecolor: "#000000",
              descriptioncolor: "#000000",
              textcheckboxcolor: "#000000",
              checkboxcolor: "#000000",
            },
            selected: false,
          }
          case "Butter Toffees":
            return {
              ...element,
              title: element.name,
              description: "Recorré el proceso productivo de los caramelos Butter Toffees en la planta industrial Arcor Arroyito, Córdoba",
              img: ButterToffes,
              checkIcon: CheckNegro,
              propsColor: {
                backgroundcolor: "#E1CDB8",
                titlecolor: "#000000",
                bordertitlecolor: "#000000",
                descriptioncolor: "#000000",
                textcheckboxcolor: "#000000",
                checkboxcolor: "#000000",
              },
              selected: false,
            }
            case "Surtido Bagley":
            return {
              ...element,
              title: element.name,
              description: "Conocé cómo producimos las galletitas de Surtido Bagley en la planta industrial Bagley Salto, Buenos Aires.",
              img: Surtido,
              checkIcon: CheckNegro,
              propsColor: {
                backgroundcolor: "#FFCB00",
                titlecolor: "#000000",
                bordertitlecolor: "#000000",
                descriptioncolor: "#000000",
                textcheckboxcolor: "#000000",
                checkboxcolor: "#000000",
              },
              selected: false,
            }
            case "Tomate":
            return {
              ...element,
              title: element.name,
              description: "Conocé el proceso productivo del tomate perita Arcor en la planta industrial Arcor Villa Krause, San Juan.",
              img: Tomate,
              checkIcon: CheckBlanco,
              propsColor: {
                backgroundcolor: "#CA1D1C",
                titlecolor: "#FFF",
                bordertitlecolor: "#FFFFFF",
                descriptioncolor: "#FFF",
                textcheckboxcolor: "#FFF",
                checkboxcolor: "#FFF",
              },
              selected: false,
            }
            case "Cartón":
            return {
              ...element,
              title: element.name,
              description: "Recorré el proceso productivo del cartón corrugado en el complejo Cartocor Luján, la principal planta de cartón, papel y envases de Argentina.",
              img: Carton,
              checkIcon: CheckBlanco,
              propsColor: {
                backgroundcolor: "#B1A598",
                titlecolor: "#FFF",
                bordertitlecolor: "#FFFFFF",
                descriptioncolor: "#FFF",
                textcheckboxcolor: "#FFF",
                checkboxcolor: "#FFF",
              },
              selected: false,
            }
            case "Azúcar":
            return {
              ...element,
              title: element.name,
              description: "Descubrí el proceso productivo del Azúcar Arcor en el Ingenio La Providencia, Tucumán (Próximamente)",
              img: Azucar,
              checkIcon: CheckBlanco,
              propsColor: {
                backgroundcolor: "#3366CC",
                titlecolor: "#FFF",
                bordertitlecolor: "#FFFFFF",
                descriptioncolor: "#FFF",
                textcheckboxcolor: "#FFF",
                checkboxcolor: "#FFF",
              },
              selected: false,
            }
        default:
          break;
      }
    })
    response.data.data[0].paths = formatRoutes;
    return response.data.data;
  } catch (error) {
    return error;
  }
});

export const getAvailableDate = createAsyncThunk(
  "tours/getAvailableDate",
  async (
    parameters: IParameters,
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    // const isMobile = useMediaQuery("(max-width:600px)");
    try {
      const { tours, editTour }: any = getState();
      const { params, allDays } = parameters;

      const config = {
        params,
      };

      const response = await Reservas.getAvailableDatesCall(
        tours.tours[0].id,
        config
      );

      const availableDays = response.data.map((element: any) => {
        const date = element.date.split(" ");

        if (element.available) {
          element = {
            start: date[0],
            end: date[0],
            available: true,
            id: element.id,
          };
        } else {
          element = {
            start: date[0],
            end: date[0],
            available: false,
            id: element.id,
          };
        }
        return element;
      });

      let counter = 0;
      for (let i = 0; i < allDays.length; i++) {
        for (let j = 0; j < availableDays.length; j++) {
          
          if (
            allDays[i].start === availableDays[j].start &&
            availableDays[j].available === true
          ) {
            counter++;
            allDays[i].id = availableDays[j].id;
            allDays[i].color = "#3366CC";
            allDays[i].extendedProps = {
              disponible: true,
            };
            allDays[i].title = `${counter} Turnos`;
          }
          if (editTour.edit && allDays[i].start === editTour.oldTourBook.date) {
         
            allDays[i].color = "#56CCF2";
            allDays[i].extendedProps = {
              edit: true,
            };
          }

          if (allDays[i].start !== availableDays[j].start) {
            counter = 0;
          }
        }
      }

      return allDays;
    } catch (error) {
      return error;
    }
  }
);

export const getAvailableWeekHour = createAsyncThunk(
  "tours/getAvailableWeekHour",
  async (
    dateValue: string,
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { tours }: any = getState();
      
      const config = {
        params: {
          date: dateValue,
        },
      };

      const response = await Reservas.getAvailableHourCall(
        tours.tours[0].id,
        config
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const toursSlice = createSlice({
  name: "tours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTours.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getAllTours.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tours = payload;
        state.error = "";
      })
      .addCase(getAllTours.rejected, (state) => {
        state.loading = false;
        state.error =
          "Ocurrio un error inesperado, intente nuevamente mas tarde.";
      })
      .addCase(getAvailableDate.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getAvailableDate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.available = payload;
        state.error = "";
      })
      .addCase(getAvailableDate.rejected, (state) => {
        state.loading = false;
        state.error =
          "Ocurrio un error inesperado, intente nuevamente mas tarde.";
      })
      .addCase(getAvailableWeekHour.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getAvailableWeekHour.fulfilled, (state, { payload }) => {
        const AvailableWeek = payload.map((element: AvailableWeekApiProps) => {
          const { available, id } = element;
          let nuevoArray: AvailableWeekProps[] = [];
          const dateHour = element.date.split(" ");
          const date = dateHour[0];
          const hour = dateHour[1];
          return { ...nuevoArray, id,  date, hour, available };
        });

        state.loading = false;
        state.hours = AvailableWeek;
        state.error = "";
      })
      .addCase(getAvailableWeekHour.rejected, (state) => {
        state.loading = false;
        state.error =
          "Ocurrio un error inesperado, intente nuevamente mas tarde.";
      });
  },
});

export default toursSlice.reducer;
