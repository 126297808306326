import { useMemo } from "react"
import { useLocation } from "react-router-dom"

import { ContainerTitle, Subtitle, Title, ButtonStyle } from "./styles"

export const Text = () => {
  const { search } = useLocation()

  const url = useMemo(() => {
    const queries = new URLSearchParams(search)
    const name = queries.get("name")
    const code = queries.get("code")
    const tourCode = queries.get("tour_code")
    const isGuide = queries.get("isGuide")

    const validParams = name && code && tourCode

    const href = validParams
      ? `${process.env.REACT_APP_BASE_URL || ""}${
          process.env.REACT_APP_SUBDOMINIO_URL || ""
        }/index.html?name=${name}&code=${code}&tour_code=${tourCode}&isGuide=${isGuide}`
      : "/ingresar-tour"

    return href
  }, [search])

  return (
    <ContainerTitle>
      <Title>¡Tuvimos un inconveniente!</Title>
      <Subtitle>
        Te pedimos que vuelvas a hacer click en el botón para reiniciar la experiencia
      </Subtitle>
      <a href={url}>
        <ButtonStyle
          variant="contained"
          color="secondary"
          sx={{ textTransform: "none" }}
        >
          Click aquí
        </ButtonStyle>
      </a>
    </ContainerTitle>
  )
}
