import BonoBon from "../../../assets/images/selectRoute/BonOBon.svg";
import ButterToffes from "../../../assets/images/selectRoute/ButterToffes.svg";
import Surtido from "../../../assets/images/selectRoute/SurtidoBagley.svg";
import Tomate from "../../../assets/images/selectRoute/Tomate.svg";
import Carton from "../../../assets/images/selectRoute/Carton.svg";
import Azucar from "../../../assets/images/selectRoute/Azucar.svg";
import CheckBlanco from "../../../assets/images/selectRoute/checkIcon.svg";
import CheckNegro from "../../../assets/images/selectRoute/checkIconBlack.svg";

export const selectRouteValues = [
  {
    id: 1,
    img: BonoBon,
    title: "Bon o Bon",
    checkIcon: CheckNegro,
    description:
      "Descubrí cómo producimos el bon o bon en la planta industrial Arcor Colonia Caroya, Córdoba.",
    propsColor: {
      backgroundcolor: "#FFA000",
      titlecolor: "#000000",
      bordertitlecolor: "#000000",
      descriptioncolor: "#000000",
      textcheckboxcolor: "#000000",
      checkboxcolor: "#000000",
    },
    selected: false,
  },
  {
    id: 4,
    img: ButterToffes,
    title: "Butter Toffees",
    checkIcon: CheckNegro,
    description:
      "Recorré el proceso productivo de los caramelos Butter Toffees en la planta industrial Arcor Arroyito, Córdoba",
    propsColor: {
      backgroundcolor: "#E1CDB8",
      titlecolor: "#000000",
      bordertitlecolor: "#000000",
      descriptioncolor: "#000000",
      textcheckboxcolor: "#000000",
      checkboxcolor: "#000000",
    },
    selected: false,
  },
  {
    id: 2,
    img: Surtido,
    title: "Surtido Bagley",
    checkIcon: CheckNegro,
    description:
      "Conocé cómo producimos las galletitas de Surtido Bagley en la planta industrial Bagley Salto, Buenos Aires.",
    propsColor: {
      backgroundcolor: "#FFCB00",
      titlecolor: "#000000",
      bordertitlecolor: "#000000",
      descriptioncolor: "#000000",
      textcheckboxcolor: "#000000",
      checkboxcolor: "#000000",
    },
    selected: false,
  },
  {
    id: 5,
    img: Tomate,
    title: "Tomate",
    checkIcon: CheckBlanco,
    description:
      "Conocé el proceso productivo del tomate perita Arcor en la planta industrial Arcor Villa Krause, San Juan.",
    propsColor: {
      backgroundcolor: "#CA1D1C",
      titlecolor: "#FFF",
      bordertitlecolor: "#FFFFFF",
      descriptioncolor: "#FFF",
      textcheckboxcolor: "#FFF",
      checkboxcolor: "#FFF",
    },
    selected: false,
  },
  {
    id: 3,
    img: Carton,
    title: "Cartón",
    checkIcon: CheckBlanco,
    description:
      "Recorré el proceso productivo del cartón corrugado en el complejo Cartocor Luján, la principal planta de cartón, papel y envases de Argentina.",
    propsColor: {
      backgroundcolor: "#B1A598",
      titlecolor: "#FFF",
      bordertitlecolor: "#FFFFFF",
      descriptioncolor: "#FFF",
      textcheckboxcolor: "#FFF",
      checkboxcolor: "#FFF",
    },
    selected: false,
  },
  {
    id: 6,
    img: Azucar,
    title: "Azúcar",
    checkIcon: CheckBlanco,
    description:
      "Descubrí el proceso productivo del Azúcar Arcor en el Ingenio La Providencia, Tucumán (Próximamente)",
    propsColor: {
      backgroundcolor: "#3366CC",
      titlecolor: "#FFF",
      bordertitlecolor: "#FFFFFF",
      descriptioncolor: "#FFF",
      textcheckboxcolor: "#FFF",
      checkboxcolor: "#FFF",
    },
    selected: false,
  },
];
