import { HeaderLayout } from "../layouts/HeaderLayout"
import { ContainerPage } from "./styles/HomePageStyles"
import { Home } from "../components/Home"

import ReactGA from "react-ga4"

export const HomePage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: "Home Page",
  })
  ReactGA.gtag("event", "conversion", {
    send_to: "AW-704540496/0dV5COzU04cYENDe-c8C",
  })

  return (
    <>
      <ContainerPage>
        <HeaderLayout>
          <Home />
        </HeaderLayout>
      </ContainerPage>
    </>
  )
}
