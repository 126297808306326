import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: `${theme.spacing(6)} auto`,
  width: "80%",
  gap: theme.spacing(12),
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
  },
}))

export const FloatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
  marginBottom: theme.spacing(2),
  zIndex: theme.zIndex.appBar,
  marginTop: 0,
}))
