import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";


export const Container = styled(Box)(({ theme  }) => ({
  width: "100vw",
  minHeight: "100vh",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('md')]:{
    flexDirection: 'row',
    gap: '0px'
  }
}));

export const Image = styled("img")(({ theme }) => ({
  width: "250px",
  height: "213px",
  [theme.breakpoints.up('md')]:{
    paddingBottom: '10rem',
    width: "400px",
    height: "340px",
  }
}));

export const Texto = styled(Typography)(({ theme }) => ({
  fontFamily: "Telolet",
  fontWeight: "400",
  fontSize: "45px",
  textAlign: "center",
  color: "#3366CC",
  lineHeight: '40px',
  paddingBottom: '10rem',
  [theme.breakpoints.up('md')]:{
    width: '600px',
    lineHeight: '50px',
    fontSize: "65px",
  }
}));