import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { Container, ImgNeneTomate, ImagenViaje, ImgBotonReserva } from "./styles";
import neneTomate from "../../../assets/images/Home/neneTomate.svg";
import botonReserva from "../../../assets/images/Home/botonReserva.svg";
import imageViaje from '../../../assets/images/Home/RecorridoUniverso.svg';

export const SubHeader = () => {
  const navigate = useNavigate();

  const { token } = useAppSelector((state: RootState) => state.auth);

  return (
    <Container>
      <ImgNeneTomate alt="Nene con tomate" src={neneTomate} />
      <ImagenViaje
        alt='Un viaje por el mundo de los alimentos'
        src={imageViaje}
        width='352px'
      />
      <ImgBotonReserva
        alt="Boton de reserva"
        src={botonReserva}
        onClick={() =>
          token ? navigate("reserva-tour") : navigate("/auth/register")
        }
        // onClick={() => navigate("/comingsoon")}
      />
    </Container>
  );
};
