import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type ImageProps = {
  width?: string;
  height?: string;
};

interface SelectedProps {
  selected: boolean;
}

interface ContainerCircleProps extends SelectedProps {
  backgroundcolor: string;
}

interface BorderTitleColorProps extends SelectedProps {
  bordertitlecolor: string;
}

interface TitleColorProps extends SelectedProps {
  titlecolor: string;
}

interface DescriptionColorProps extends SelectedProps {
  descriptioncolor: string;
}

interface textCheckBoxColorProps extends SelectedProps {
  textcheckboxcolor: string;
}

interface CheckBoxColorProps extends SelectedProps {
  checkboxcolor: string;
}

export const Container = styled(Box)(() => ({
  position: "relative",
  width: "240px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px",
  gap: "5px",
  background: "#FAFAFA",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
}));

export const ContainerCircle = styled(Box)<ContainerCircleProps>(
  ({ selected, backgroundcolor }) => ({
    position: "absolute",
    top: "20%",
    left: "50%",
    background: selected ? backgroundcolor : "none",
    borderRadius: selected ? "14px" : "50%",
    width: selected ? "100%" : "200px",
    height: selected ? "100%" : "200px",
    transform: selected
      ? "translate(-50%, -20%) scale(1)"
      : "translate(-50%, -20%) scale(0.2)",
    transition: "all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
  })
);

export const Imagen = styled("img")<ImageProps>(() => ({
  width: "154px",
  height: "154px",
  alignSelf: "center",
  zIndex: "10000",
}));

export const TitleContainer = styled(Box)<BorderTitleColorProps>(
  ({ selected, bordertitlecolor }) => ({
    display: "flex",
    alignItems: "flex-start",
    padding: "10px 10px 10px 0px",
    gap: "10px",
    borderBottom: selected
      ? `3px solid ${bordertitlecolor}`
      : "3px solid #828282",
    zIndex: "10000",
  })
);

export const Title = styled(Typography)<TitleColorProps>(
  ({ selected, titlecolor }) => ({
    alignSelf: "flex-start",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "21px",
    lineHeight: "24px",
    color: selected ? titlecolor : "#828282",
  })
);

export const Description = styled(Typography)<DescriptionColorProps>(
  ({ selected, descriptioncolor }) => ({
    marginTop: "20px",
    width: "95%",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11.5px",
    lineHeight: "12px",
    color: selected ? descriptioncolor : "#828282",
    zIndex: "10000",
  })
);

export const CheckBoxContainer = styled(Box)(() => ({
  justifySelf: "flex-end",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "14px",
  marginTop: "20px",
  zIndex: "10000",
}));

export const CheckBoxText = styled(Typography)<textCheckBoxColorProps>(
  ({ selected, textcheckboxcolor }) => ({
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "14.25px",
    textAlign: "center",
    color: selected ? textcheckboxcolor : "#3366CC",
  })
);

export const CheckBoxIcon = styled("img")(() => ({
  width: "23.1px",
  height: "23.1px",
  cursor: "pointer",
}));

export const CheckBox = styled(Box)<CheckBoxColorProps>(
  ({ selected, checkboxcolor }) => ({
    width: selected ? "22.1px" : "20px",
    height: selected ? "22.1px" : "20px",
    borderRadius: "50%",
    background: selected ? checkboxcolor : "none",
    border: selected ? "none" : "2.1px solid #3366CC",
    cursor: "pointer",
  })
);
