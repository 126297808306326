import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";
import Image from "../../../assets/images/Home/FondoMobile.svg";

type ButtonContainerProps = {
  isMobile?: boolean;
};

export const Container = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "50px",
  [theme.breakpoints.up(1100)]: {
    backgroundImage: "none",
  },
}));

export const ContainerSubHeader = styled(Box)(({ theme }) => ({
  width: "70%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  marginBottom: "40px",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
}));

export const FlexContainer = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  width: "100%",
  alignSelf: "flex-start",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "40px",
  gap: "10px",
  cursor: "pointer",
  [theme.breakpoints.up(1100)]: {
    marginTop: "5px",
  },
}));

export const Icon = styled("img")(() => ({
  width: "24px",
  height: "24px",
}));

export const IconText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16.0678px",
  lineHeight: "19px",
  textAlign: "center",
  color: "#3366CC",
}));

export const DataContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.up(1100)]: {
    justifyContent: "space-between",
    gap: 0,
  },
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontWeight: 400,
  fontSize: "40px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#3366CC",
}));

export const Description = styled(Typography)(({ theme }) => ({
  width: "50%",
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "19px",
  textAlign: "center",
  color: "#000000",
  [theme.breakpoints.up(1100)]: {
    width: "25%",
  },
}));

export const ButtonContainer = styled(Box)<ButtonContainerProps>(
  ({ isMobile }) => ({
    width: isMobile ? "100%" : "30%",
    display: "flex",
    justifyContent: isMobile ? "space-evenly" : "space-between",
    alignItems: "center",
    padding: "10px 0px 10px 38px",
    gap: "15px",
    borderLeft: isMobile ? "none" : "3px solid #1A72B8",
  })
);

export const RouteText = styled(Typography)(() => ({
  width: "30%",
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#000000",
}));

export const ButtonBooking = styled(Button)(() => ({
  padding: "15px 30px",
  gap: "10px",
  borderRadius: "86px",
}));

export const ContainerRecorridos = styled(Box)(() => ({
  width: "80%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "70px",
}));

export const FloatContainer = styled(Box)(() => ({
  height: "89px",
  width: "100%",
  position: "fixed",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  bottom: 0,
  zIndex: 100000000000000000,
  background: "#FFFFFF",
}));
