import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  setHourReserva,
  setDateReserva,
  nextStep,
  setDateIdReserva,
} from "../../../redux/reservas/reservasSlice";
import { Spinner } from "../../Spinner";
import {
  Container,
  Boton,
  BotonDisabled,
  ContainerDay,
} from "./styles/tourStyles";
import moment from "moment";
import "moment/locale/es";
import {
  setDateIdReservaEdit,
  setHourReservaEdit,
} from "../../../redux/reservas/editReservaSlice";

export const Tour = ({
  setHandleOpen,
  setSelectedWeek,
  element,
  i,
  setOpenEditConfirmModal,
}: any) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state: RootState) => state.reservas);
  const { edit } = useAppSelector((state: any) => state.editTour);
  const { hours } = useAppSelector((state) => state.tours);

  moment.locale("es");

  const [daysToRender, setDaysToRender] = useState<any>({
    day: "",
    date: "",
    hours: [],
  });

  const setHandleHour = (hour: string, date: string) => {
    if (edit) {
      dispatch(setHourReservaEdit({ hour, date }));
      setOpenEditConfirmModal(true);
      dispatch(setDateIdReservaEdit(getMatchedId(hours, date, hour)));
    } else {
      // dispatch(startLoading());
      dispatch(setDateIdReserva(getMatchedId(hours, date, hour)));
      dispatch(setHourReserva({ hour, date }));
      dispatch(nextStep());
      setHandleOpen(true);
    }
  };

  const formatDateAndHour = (object: typeof element) => {
    const writeDay = moment(object.date).format("dddd");
    const firstLetterUppercase =
      writeDay.charAt(0).toUpperCase() + writeDay.substring(1);
    const dateDay = moment(object.date).format("DD/MM");
    const formatHours = object.hours?.map((hours: string) => {
      const actualDay = new Date().getTime();
      const reserveDay = new Date(`${object.date} ${hours}`).getTime();

      if (reserveDay > actualDay) {
        const newHour = hours.substring(0, 5);
        const addH = {
          hora: `${newHour} hs`,
          disabled: false,
        };
        return addH;
      } else {
        const newHour = hours.substring(0, 5);
        const addH = {
          hora: `${newHour} hs`,
          disabled: true,
        };
        return addH;
      }
    });

    setDaysToRender({
      day: firstLetterUppercase,
      date: dateDay,
      hours: formatHours,
    });
  };
  const getMatchedId = (dates: any, date: any, hour: any) => {
    try {
      element = {
        date,
        hour,
        available: true,
      };
      const id = searchObject(dates, element);
      return id;
    } catch (error) {
      console.error("Error fetching available dates:", error);
      throw error;
    }
  };
  const searchObject = (data: any, searchCriteria: any) => {
    const result = data.find(
      (item: any) =>
        item.date === searchCriteria.date &&
        item.hour.split(":").slice(0, 2).join(":") + " hs" ===
          searchCriteria.hour
    );

    return result.id ?? null;
  };

  useEffect(() => {
    if (element) {
      formatDateAndHour(element);
    }
  }, [element]);

  return (
    <>
      <Container>
        <ContainerDay>
          {daysToRender?.day} {daysToRender?.date}
        </ContainerDay>
        {daysToRender.hours.length > 0 ? (
          daysToRender?.hours.map((hour: any, i: any) => (
            <Boton
              key={i}
              variant="contained"
              color="primary"
              disabled={hour.disabled}
              onClick={() => setHandleHour(hour.hora, element.date)}
            >
              {loading ? (
                <Spinner color="#FFF" width={30} height={30} />
              ) : (
                hour.hora
              )}
            </Boton>
          ))
        ) : (
          <BotonDisabled variant="contained">
            {loading ? (
              <Spinner color="#FFF" width={30} height={30} />
            ) : (
              "sin turnos"
            )}
          </BotonDisabled>
        )}
      </Container>
    </>
  );
};
