import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { Container, ContainerModal, DateContainer, Text } from './styles';
import { Modal } from '@mui/material';
import { ReservaForm } from './ReservaForm';
import moment from 'moment';
import 'moment/locale/es';


export const ReservaModal = ({ handleOpen, setHandleOpen, setHandleClose }: any) => {
    moment.locale('es');
    const { reserva } = useAppSelector((state: RootState) => state.reservas);
    const { tours } = useAppSelector((state: RootState) => state.tours);
    const [dayToRender, setDayToRender] = useState({
        day: '',
        date: ''
    })

    const formatRender = (date: string) => {
        const writeDay = moment(date).format('dddd');
        const firstLetterUppercase = writeDay.charAt(0).toUpperCase() + writeDay.substring(1);
        const dateDay = moment(date).format('DD/MM');
        setDayToRender({
            day: firstLetterUppercase,
            date: dateDay
        })
    }

    useEffect(() => {
        formatRender(reserva.date)
    }, [])

    return (
        <Modal
            onClose={setHandleClose}
            open={handleOpen}
            keepMounted
            sx={{
                overflow: 'scroll'
            }}
        >
            <Container>
                <ContainerModal>
                    <DateContainer>
                        <Text variant='body1'>{dayToRender.day} {dayToRender.date} - {reserva.hour}</Text>
                        <Text>Tour {tours[0].name}</Text>
                    </DateContainer>
                    <ReservaForm setHandleOpen={setHandleOpen} handleOpen={handleOpen} />
                </ContainerModal>
            </Container>
        </Modal>
    )
};