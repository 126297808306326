import { useState, useEffect, useCallback } from "react";
import {
  AccordionStyled,
  AccordionSummaryStyled,
  CopyIconContainer,
  DataTourText,
  DayText,
  DetailContainer,
  DetailText,
  IconCopy,
  TourDataContainer,
  TourDetailsContainer,
} from "./styles";
// BORROWED STYLES FROM PROFILE CARD
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CopyIcon from "../../../assets/images/profile/CopyIcon.svg";
import moment from "moment";
import "moment/locale/es";
import { AccordionDetails } from "@mui/material";
import { sweetAlertMixin } from "../../../helpers/sweetAlert";

type Props = {
  element?: any;
};

export const Card = ({ element }: Props) => {
  const [dayFormat, setDayFormat] = useState<any>();

  moment.locale("es");

  const formatDateAndHour = useCallback((object: any) => {
    if (!object?.date?.day) {
      return;
    }

    const writeDay = moment(object.date.day).format("dddd");
    const firstLetterUppercase =
      writeDay.charAt(0).toUpperCase() + writeDay.substring(1);
    const dateDay = moment(object.date.day).format("DD/MM");
    const formatHours = object.date.hour.substring(0, 5);
    const newHour = `${formatHours} hs`;

    setDayFormat({
      day: firstLetterUppercase,
      date: dateDay,
      hours: newHour,
    });
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `Codigo de acceso: ${element?.code.toString()} Número de tour: ${element?.tour_id.toString()}` ||
        "No se copio"
    );
    sweetAlertMixin("success", "Copiado al portapapeles");
  };

  useEffect(() => {
    if (element) {
      formatDateAndHour(element);
    }
  }, [element, formatDateAndHour]);

  return (
    <AccordionStyled>
      <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
        <DayText isAccordion>
          <h4>
            {dayFormat?.day} {dayFormat?.date} - {dayFormat?.hours}
          </h4>
          <span>Experiencia Virtual</span>
        </DayText>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <DetailContainer>
          <TourDataContainer>
            <CopyIconContainer>
              <IconCopy alt="Copy Icon" src={CopyIcon} onClick={handleCopy} />
            </CopyIconContainer>
            <DataTourText>
              Número de Tour: {element?.tour_id} <br />
              Código de acceso: {element?.code}
            </DataTourText>
          </TourDataContainer>
          <TourDetailsContainer isAccordion>
            <DetailText>Capacidad: {element?.participants}/ 30</DetailText>
            <DetailText>Grado: {element?.grade_id}</DetailText>
            <DetailText>
              Colegio: {element?.institution || "Sin definir"}
            </DetailText>
          </TourDetailsContainer>
        </DetailContainer>
      </AccordionDetails>
    </AccordionStyled>
  );
};
