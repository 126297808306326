import { Title } from "./Title"
import { Information } from "./Information"
import { BookingsSection } from "./BookingsSection"
import { BookingButton } from "./BookingsSection/BookingButton"
import { Container, FloatContainer } from "./styles"

import { Box, useMediaQuery } from "@mui/material"
import theme from "../../theme/index"

export const Profile = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Container>
      {/* sidebar */}
      <Box>
        <Title />
        {!isMobile && <Information />}
      </Box>
      {/* boton de reserva flotante en mobile */}
      {isMobileSmall && (
        <FloatContainer>
          <BookingButton />
        </FloatContainer>
      )}
      {/* bookings section */}
      <BookingsSection />
      {/* information section */}
      {isMobile && <Information />}
    </Container>
  )
}
