import { Title, Container } from "./styles";
import { Carrousel } from "./Carrousel/Carrousel";

export const Steps = () => {

  return (
    <Container>
      <Title>¿Cómo funciona?</Title>
        <Carrousel />
    </Container>
  );
};
