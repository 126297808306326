import { useNavigate } from 'react-router-dom';
import { Container, Image, Title, Text, TextBold, Boton, TextContainer } from './styles';
import SucessIcon from '../../../assets/images/registerSuccess/SuccessIcon.svg';

export const Card = () => {

  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/auth/login');
  }


  return (
    <Container>
      <Image
        alt='Image reserve success'
        src={SucessIcon}
      />
      <TextContainer>
        <Title>¡LISTO!</Title>
        <TextBold>Su cuenta <br /> fue verificada con exito!</TextBold>
        <Text>Ahora debe ingresar con su <br /> cuenta para poder <br /> reservar un tour. <br /></Text>
        <Boton
          variant='contained'
          color='primary'
          onClick={handleClose}
          fullWidth
        >
          INGRESAR
        </Boton>
      </TextContainer>
    </Container>
  )
}