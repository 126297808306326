import { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getTourBookEdit } from "../../../redux/reservas/editReservaSlice";
import { enterTourCalls } from "../../../services/enterTourApiCalls";
import { useNavigate } from "react-router-dom";
import {
  ContainerCard,
  DetailContainer,
  DayContainer,
  DayText,
  TourDataContainer,
  DataTourText,
  TourDetailsContainer,
  DetailText,
  EnterTourButton,
  ContainerEditButton,
  EditButton,
  CopyIconContainer,
  IconCopy,
  IconEdit,
  CancelButton,
  AccordionStyled,
  AccordionSummaryStyled,
} from "./styles";
import EditIcon from "../../../assets/images/profile/EditIcon.svg";
import CopyIcon from "../../../assets/images/profile/CopyIcon.svg";
import moment from "moment";
import "moment/locale/es";
import {
  sweetAlertConfirm,
  sweetAlertMixin,
} from "../../../helpers/sweetAlert";
import { Spinner } from "../../Spinner";
import {
  deleteReserveProfile,
  getTourProfile,
  ProfileProps,
} from "../../../redux/profile/profileSlice";
import { AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  nextBooking?: any;
  element?: ProfileProps;
  isAccordion?: boolean; // Nueva prop para controlar si es un acordeón
};

export const Card = ({ nextBooking, element, isAccordion = false }: Props) => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dayFormat, setDayFormat] = useState<any>();
  const [route, setRoute] = useState<any>();
  const [loadingEnterTour, setLoadingEnterTour] = useState(false);

  console.log(element, "element");

  moment.locale("es");
  const formatDateAndHour = useCallback((object: typeof element) => {
    const writeDay = moment(object?.date).format("dddd");
    const firstLetterUppercase =
      writeDay.charAt(0).toUpperCase() + writeDay.substring(1);
    const dateDay = moment(object?.date).format("DD/MM");
    const formatHours = object?.hour.substring(0, 5);
    const newHour = `${formatHours} hs`;

    setDayFormat({
      day: firstLetterUppercase,
      date: dateDay,
      hours: newHour,
    });
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `Codigo de acceso: ${element?.tour_book_code.toString()} Número de tour: ${element?.tour_code.toString()}` ||
        "No se copio"
    );
    sweetAlertMixin("success", "Copiado al portapapeles");
  };

  const handleEnterTour = async () => {
    setLoadingEnterTour(true);
    const bodyParameters = {
      tour_book_code: element?.tour_book_code,
      tour_code: element?.tour_code,
      name: user.user.name,
    };
    try {
      const response = await enterTourCalls.enterTour(bodyParameters);
      const currentDate = new Date().getTime();
      const tourDate = new Date(
        `${response.data.data.tour_book.date}T${response.data.data.tour_book.hour}`
      ).getTime();
      if (currentDate >= tourDate) {
        setLoadingEnterTour(false);
        window.open(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBDOMINIO_URL}/index.html?name=${response.data.data.name}&code=${element?.tour_book_code}&tour_code=${element?.tour_code}&isGuide=${response.data.data.is_guide}`,
          "_blank"
        );
      } else {
        sweetAlertMixin(
          "error",
          "Todavia no comenzó la experiencia, revisa la fecha y hora."
        );
        setLoadingEnterTour(false);
      }
    } catch (err: any) {
      if (err.response.status === 405) {
        sweetAlertMixin(
          "error",
          "La experiencia todavía no comenzó. Si el problema persiste contacta a soporte"
        );
      } else if (err.response.status === 404) {
        sweetAlertMixin(
          "error",
          "No pudimos encontrar la reserva, revisa tus datos ingresados."
        );
      } else {
        sweetAlertMixin(
          "error",
          "Ocurrio un error, intente nuevamente mas tarde."
        );
      }
      setLoadingEnterTour(false);
    }
  };

  const handleEdit = () => {
    dispatch(getTourBookEdit(element?.id));
    navigate("/reserva-tour");
  };

  const handleDeleteReserve = async (id: number) => {
    const response = await sweetAlertConfirm();
    if (response) {
      await dispatch(deleteReserveProfile(id));
      const token = user.access_token || sessionStorage.getItem("token");
      dispatch(getTourProfile(token));
    }
  };

  useEffect(() => {
    if (element) {
      formatDateAndHour(element);
      setRoute({
        firstRoute: element?.paths[0],
        secondRoute: element?.paths[1],
      });
    }
  }, [element, formatDateAndHour]);

  if (isAccordion) {
    return (
      // THIS CARD IS ALSO USED IN MY TOURS
      <AccordionStyled>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
          <DayText isAccordion={isAccordion}>
            {dayFormat?.day} {dayFormat?.date} - {dayFormat?.hours} <br />
            <h4>{element?.name}</h4>
          </DayText>
        </AccordionSummaryStyled>
        <AccordionDetails>
          <DetailContainer>
            <TourDataContainer>
              <CopyIconContainer>
                <IconCopy alt="Copy Icon" src={CopyIcon} onClick={handleCopy} />
              </CopyIconContainer>
              <DataTourText>
                Número de Tour: {element?.tour_code} <br />
                Código de acceso: {element?.tour_book_code}
              </DataTourText>
            </TourDataContainer>
            <TourDetailsContainer isAccordion={isAccordion}>
              <DetailText>Capacidad: {element?.participants}/ 30</DetailText>
              <DetailText>Grado: {element?.grade}</DetailText>
              <DetailText>
                Colegio: {element?.institution || "Sin definir"}
              </DetailText>
              <DetailText>
                Recorrido:{" "}
                {route?.secondRoute?.name
                  ? `${route?.firstRoute?.name} + ${route?.secondRoute?.name}`
                  : `${route?.firstRoute?.name}`}
              </DetailText>
            </TourDetailsContainer>
          </DetailContainer>
          <ContainerEditButton nextBooking={nextBooking}>
            <EditButton onClick={handleEdit}>
              <IconEdit alt="Icon Edit" src={EditIcon} />
              EDITAR
            </EditButton>
            <CancelButton
              onClick={() => element?.id && handleDeleteReserve(element.id)}
            >
              Cancelar Turno
            </CancelButton>
          </ContainerEditButton>
        </AccordionDetails>
      </AccordionStyled>
    );
  }

  // Return original card layout for non-accordion version
  return (
    <ContainerCard isAccordion={isAccordion}>
      <DayContainer nextBooking={nextBooking}>
        <DayText>
          <h4>{element?.name}</h4>
          {dayFormat?.day} {dayFormat?.date} - {dayFormat?.hours} <br />
        </DayText>
      </DayContainer>
      <DetailContainer>
        <TourDetailsContainer>
          <DetailText>Capacidad: {element?.participants}/ 30</DetailText>
          <DetailText>Grado: {element?.grade}</DetailText>
          <DetailText>
            Colegio: {element?.institution || "Sin definir"}
          </DetailText>
          <DetailText>
            Recorrido:{" "}
            {route?.secondRoute?.name
              ? `${route?.firstRoute?.name} + ${route?.secondRoute?.name}`
              : `${route?.firstRoute?.name}`}
          </DetailText>
        </TourDetailsContainer>

        <TourDataContainer>
          <CopyIconContainer>
            <IconCopy alt="Copy Icon" src={CopyIcon} onClick={handleCopy} />
          </CopyIconContainer>
          <DataTourText>
            Número de Tour: {element?.tour_code} <br /> Código de acceso:{" "}
            {element?.tour_book_code}
          </DataTourText>
        </TourDataContainer>
      </DetailContainer>
      {nextBooking && (
        <EnterTourButton onClick={handleEnterTour} disabled={loadingEnterTour}>
          {loadingEnterTour ? (
            <Spinner width="30px" height="30px" color="#000000" />
          ) : (
            "INGRESAR AL TOUR"
          )}
        </EnterTourButton>
      )}
      <ContainerEditButton nextBooking={nextBooking}>
        <EditButton onClick={handleEdit}>
          <IconEdit alt="Icon Edit" src={EditIcon} />
          EDITAR
        </EditButton>
        <CancelButton
          onClick={() => element?.id && handleDeleteReserve(element.id)}
        >
          Cancelar Turno
        </CancelButton>
      </ContainerEditButton>
    </ContainerCard>
  );
};
