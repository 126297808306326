import LogoArcor from "../../assets/images/Header/LogoArcor.svg";
import LogoFundacion from "../../assets/images/quienesSomos/logoFundacion.svg";
import ImagenArriba from "../../assets/images/quienesSomos/imagenArriba.svg";
import ImagenAbajo from "../../assets/images/quienesSomos/imagenAbajo.svg";

export const data = [
  {
    logo: LogoArcor,
    textLink: "Conocé más",
    link: "WWW.ARCOR.COM.AR",
    image: ImagenArriba,
    redirect: "https://arcor.com.ar/",
    title:
      "Creemos en emprender e innovar para convertir los sueños en realidad.",
    firstDesc:
      "En Grupo Arcor comenzamos elaborando caramelos hace 70 años y hoy llegamos a mas de 1000 paises a traves de nuestras 3 divisiones de negocio: Alimentos de consumo masivo, Agronegocios y Packaging.",
    secondDesc:
      "Trabajamos con el proposito de hacer accesibles las tendencias en alimentacion para que todas las personas podamos vivir mejor. Por eso desde nuestros inicios, mantenemos el compromiso con la calidad en cada paso y mira-",
  },
  {
    logo: LogoFundacion,
    textLink: "Conocé más",
    link: "WWW.FUNDACIONARCOR.ORG",
    image: ImagenAbajo,
    redirect: "https://fundacionarcor.org/",
    title: "Generando igualdad de oportunidades para la infancia.",
    firstDesc:
      "Fundación Arcor Argentina (1991), Instituto Arcor Brasil (2004) Fundación Arcor Chile (2015): son entidades sin fines de lucro, creadas por Grupo ARCOR como expresión del compromiso y la responsabilidad social heredados de los fundadores de la empresa, a fin de promover de manera orgánica y corporativa, el desarrollo integral de las comunidades. Llevan adelante la Política de Inversión Social de Arcor, trabajando siempre en estrecha relación con la sociedad, bajo la convicción de que la educación es la herramienta clave para la generación de igualdad de oportunidades para la infancia.",
    secondDesc:
      "En 30 años de acción, han apoyado alrededor de 4.000 proyectos de los que participaron 6.7 millones de niñas y niños y 1.5 millones de personas que trabajan con la niñez, en alianza con más de 2.000 instituciones de Latinoamérica. Más información: www.fundacionarcor.org www.institutoarcor.org.br www.fundacionarcor.cl",
  },
];
