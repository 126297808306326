import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

type FlechaProps = {
  left?: boolean;
};

type DotsProps = {
  checked?: boolean;
};

export const Container = styled(Box)(() => ({
  width: "100%",
  maxWidth: "100vw",
  display: "flex",
  position: "relative",
}));

export const Carousel = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
  overflowX: "scroll",
  scrollSnapType: "x mandatory",
  scrollBehavior: "smooth",
}));

export const ContainerCard = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2.75rem",
  flex: "1 0 100%",
  scrollSnapAlign: "start",
}));

export const Flechas = styled("img")<FlechaProps>(({ theme, left }) => ({
  height: "30px",
  width: "30px",
  marginLeft: `${left ? "30px" : null}`,
  marginRight: `${!left ? "30px" : null}`,
  transform: `${left ? "rotate(180deg)" : null}`,
  position: "absolute",
  bottom: 60,
  zIndex: "10000",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    left: `${left && "5%"}`,
    right: `${!left && "5%"}`,
  },
  [theme.breakpoints.up("sm")]: {
    left: `${left && "15%"}`,
    right: `${!left && "15%"}`,
  },
}));

export const DotsContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "1rem",
}));

export const Dots = styled(Box)<DotsProps>(({ theme, checked }) => ({
  width: "17.92px",
  height: "17.92px",
  borderRadius: "50%",
  backgroundColor: `${checked ? "#3366CC" : "#C0C0C0"}`,
  margin: "0px 4.5px 0px 4.5px",
  transition: "all 0.5s ease-in-out",
}));
