import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { Form } from "formik";

export const Container = styled(Box)(({ theme }) => ({
  width: "90vw",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px auto 0px auto",
  [theme.breakpoints.up(1100)]: {
    flexDirection: "row",
    marginTop: "3.1rem",
    alignItems: "flex-start",
    minHeight: "calc(100vh - 177px) !important",
    width: "75vw",
  },
  [theme.breakpoints.up(1500)]: {
    marginTop: "5rem",
  },
}));

export const ContainerTitle = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up(1100)]: {
    margin: "50px 0px 20px 0px",
    width: "45%",
  },
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontWeight: "400",
  fontSize: "45px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#3366CC",
  marginBottom: "10px",
}));

export const ContainerImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "175px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up(1100)]: {
    marginTop: "5.1rem",
  },
}));

export const Img = styled("img")(({ theme }) => ({
  width: "232px",
  height: "197px",
  [theme.breakpoints.up(1100)]: {
    width: "300px",
    height: "250px",
  },
}));

export const ContainerForm = styled(Form)(({ theme }) => ({
  width: "80%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 5px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "14px",
  padding: "20px 30px",
  marginBottom: "24px",
  [theme.breakpoints.up("sm")]: {
    width: "40%",
  },
}));

export const ButtonStyle = styled(Button)(() => ({
  padding: "12px 40px 12px 40px",
  borderRadius: "86px",
  fontSize: "16px",
  fontWeight: "700",
  fontFamily: "Raleway",
  textTransform: "uppercase",
}));

export const ContainerLinks = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "30px 0px",
}));

export const Links = styled(Link)(() => ({
  fontFamily: "Raleway",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "149.4%",
  textAlign: "center",
  textDecoration: "underline",
  color: "#3366CC",
  marginBottom: "5px",
}));
