import { EnterTour } from "../components/EnterTour";
import { HeaderLayout } from "../layouts/HeaderLayout"
import { Container } from './styles/enterTourPageStyles';

import ReactGA from "react-ga4";

export const EnterTourPage = () => {

  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Enter Tour" });

  return (
    <Container>
      <HeaderLayout>
        <EnterTour />
      </HeaderLayout>
    </Container>
  )
}