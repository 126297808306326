
import { axiosInstance } from "./axiosInstance";



export const EncuestaForm = {
  getEncuesta: async (id: any) => {
    const response = await axiosInstance.get(
      `/get-survey-questions/${id}`
    );
    return response;
  },
  postEncuesta: async (bodyParameters: any) => {
    const response = await axiosInstance.post(
      `/answerSurvey`,
      bodyParameters
    );
    return response;
  },
};
