import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Profile } from "../../services/profileCalls"
import Swal from "sweetalert2"

interface GuideProps {
  id: string
  name: string
}

interface PivotProps {
  tour_book_id: number
  tour_path_id: number
}

interface PathProps {
  active: number
  created_at: string
  description: string
  id: number
  name: string
  pivot: PivotProps
  updated_at: string
}

export interface ProfileProps {
  name: string
  date: string
  date_id: number
  city: string
  cue: string
  participants: number
  grade: string
  grade_id: number
  tour_code: string
  tour_book_code: string
  access_code: number
  guide: GuideProps
  hour: string
  state_id: number
  id: number
  paths: PathProps[] | []
  institution: string
}

interface InitProps {
  profile: ProfileProps[]
  doneBookingsShow: any
  loading: boolean
  error: string
  success: boolean
}

const initialState: InitProps = {
  profile: [],
  doneBookingsShow: [],
  loading: false,
  error: "",
  success: false,
}

export const getTourProfile = createAsyncThunk(
  "profile/getToursProfile",
  async (token: string | null, { rejectWithValue }) => {
    try {
      const response = await Profile.getReserveProfile(token)
      return response.data
    } catch (error) {
      return rejectWithValue(
        "Ocurrio un error inesperado, intente nuevamente mas tarde."
      )
    }
  }
)

export const doneBookings = createAsyncThunk(
  "profile/doneBookings",
  async (token: string | null, { rejectWithValue }) => {
    try {
      const response = await Profile.getDoneBookings()

      let bookingsDone: any[] = []
      Object.entries(response.data).forEach(([key, value]) => {
        bookingsDone.push({ title: key, bookings: value })
      })
      return bookingsDone
    } catch (error) {
      return rejectWithValue(
        "Ocurrio un error inesperado, intente nuevamente mas tarde."
      )
    }
  }
)

export const deleteAlertSuscription = createAsyncThunk(
  "profile/deleteAlertSuscription",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await Profile.deleteAlertSuscription(email);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        "Ocurrio un error inesperado, intente nuevamente mas tarde."
      );
    }
  }
);

export const suscribeAlert = createAsyncThunk(
  "profile/suscribeAlert",
  async (token: string | null, { rejectWithValue }) => {
    try {
      const response = await Profile.suscribeAlert();
      return response.data;
    } catch (error) {
      return rejectWithValue(
        "Ocurrio un error inesperado, intente nuevamente mas tarde."
      );
    }
  }
);

export const deleteReserveProfile = createAsyncThunk(
  "profile/deleteReserveProfile",
  async (id: number, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await Profile.deleteReserveProfile(id)
      if (response.status !== 200) {
        return rejectWithValue(response.data)
      }
      Swal.fire("Cancelado!", "Se cancelo correctamente.", "success")
      return fulfillWithValue(response)
    } catch (error) {
      return rejectWithValue(
        "Ocurrio un error inesperado, intente nuevamente mas tarde."
      )
    }
  }
)

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTourProfile.pending, (state) => {
        state.loading = true
        state.error = ""
      })
      .addCase(getTourProfile.fulfilled, (state, action: any) => {
        const nextBookings = action.payload.filter((el: any) => {
          if (
            new Date(`${el.date} ${el.hour}:00`).getTime() + 3600000 >
            new Date().getTime()
          )
            return el
        })
        state.loading = false
        state.error = ""
        state.profile = nextBookings.sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      })
      .addCase(getTourProfile.rejected, (state, action: any) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(doneBookings.pending, (state) => {
        state.loading = true
        state.error = ""
      })
      .addCase(doneBookings.fulfilled, (state, action) => {
        state.loading = false
        state.doneBookingsShow = action.payload
        state.error = ""
      })
      .addCase(doneBookings.rejected, (state) => {
        state.loading = true
        state.error = ""
      })
      .addCase(deleteReserveProfile.pending, (state) => {
        state.loading = true
        state.error = ""
        state.success = false
      })
      .addCase(deleteReserveProfile.fulfilled, (state) => {
        state.loading = false
        state.error = ""
        state.success = true
      })
      .addCase(deleteReserveProfile.rejected, (state, action: any) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      })
      .addCase(deleteAlertSuscription.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(deleteAlertSuscription.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
        state.success = true;
      })
      .addCase(deleteAlertSuscription.rejected, (state, action: any) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      });
  },
})

export default profileSlice.reducer
