import { ForgotPasswordForm } from "../components/ForgotPasswordForm/ForgotPasswordForm"
import { HeaderLayout } from "../layouts/HeaderLayout"
import { Container } from './styles/forgotPageStyles';

import ReactGA from "react-ga4";

export const ForgotPasswordPage = () => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Forgot Password" });
    return(
        <Container>
            <HeaderLayout>
                <ForgotPasswordForm />
            </HeaderLayout>
        </Container>
    )
}