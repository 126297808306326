import { styled } from "@mui/material/styles";
import { Box, Button, FormHelperText } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "20px",
  gap: "20px",
  width: "80vw",
  height: "100%",
  background: "#FFFFFF",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
  margin: "10px 0px 30px 0px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
}));

export const SendButton = styled(Button)(() => ({
  padding: "15px 30px",
  gap: "10px",
  background: "#3366CC",
  borderRadius: "86px",
}));

export const ErrorText = styled(FormHelperText)(() => ({
  color: "#D33E34",
}));
