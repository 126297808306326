import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FondoDesktop from "../../assets/images/auth/FondoDesktop.svg";

export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: `url(${FondoDesktop})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
}));
