import * as Yup from "yup";

export const contactoSchema = Yup.object().shape({
  nombre: Yup.string().required("El campo es obligatorio"),
  email: Yup.string()
    .email("Ingrese un email valido")
    .required("El campo es obligatorio"),
  telefono: Yup.string()
    .matches(/^[0-9]+$/, "Deben ser numeros")
    .required("El campo es obligatorio"),
  mensaje: Yup.string().required("El campo es obligatorio"),
});
