import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  padding: "55px 0px 37px 0px",
  gap: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#D1F1FF",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
    height: "520px",
  },
  [theme.breakpoints.up("md")]: {
    width: "450px",
    height: "520px",
    margin: "40px auto",
  },
}));

export const Image = styled("img")(({ theme }) => ({
  width: "159px",
  height: "163px",
  [theme.breakpoints.up('sm')]:{
    width: "120px",
  height: "122px",
  }
}));

export const TextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "25px",
  padding: "0px 35px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Telolet",
  fontWeight: 400,
  fontSize: "45px",
  textAlign: "center",
  color: "#3366CC",
  marginTop: "28px",
  [theme.breakpoints.up('sm')]:{
    fontSize: '33px'
  }
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "20px",
  textAlign: "center",
  [theme.breakpoints.up('sm')]:{
    fontSize: '15px'
  }
}));

export const TextBold = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "20px",
  textAlign: "center",
  [theme.breakpoints.up('sm')]:{
    fontSize: '15px'
  }
}));

export const Boton = styled(Button)(({ theme }) => ({
  padding: "15px 30px !important",
  fontFamily: 'Raleway',
  gap: "10px",
  borderRadius: "86px",
  marginTop: "30px",
  fontSize: "16px !important",
  fontWeight: "700",
  [theme.breakpoints.up('sm')]:{
    padding: "11.25px 11.25px !important",
    fontFamily: 'Raleway',
    gap: "10px",
    borderRadius: "86px",
    marginTop: "5px",
    fontSize: "12px !important",
    fontWeight: "700",
  }
}));

