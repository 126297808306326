import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "50px auto 50px auto",
  width: "80%",
  gap: "20px",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    margin: "0px auto 90px auto",
  },
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontWeight: 400,
  fontSize: "45px",
  lineHeight: "45px",
  color: "#3366CC",
  marginTop: "20px",
  textAlign: "center",
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    gap: "50px",
  },
}));

export const FirstContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
}));

export const SecondContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
}));

export const FlexContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export const Subtitle = styled("span")(() => ({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 700,
}));

export const Text = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 500,
  textAlign: "start",
}));
