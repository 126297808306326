import { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "../../../redux/hooks"
import {
  Container,
  TitleText,
  Text,
  FlexContainer,
  TextContainer,
  Icon,
} from "./styles"
import { Modal } from "@mui/material"
import IconEdit from "../../../assets/images/profile/EditIcon.svg"
import CopyIcon from "../../../assets/images/profile/CopyIcon.svg"
import { FormEdit } from "./FormEdit"
import moment from "moment"
import { setStep } from "../../../redux/reservas/reservasSlice"

export const ModalEditForm = ({
  openEditFormModal,
  setOpenEditFormModal,
}: any) => {
  const dispatch = useAppDispatch()
  const [toShow, setToShow] = useState({
    day: "",
    date: "",
    hour: "",
    name: "",
  })

  const { oldTourBook, newTourBook } = useAppSelector(
    (state: any) => state.editTour
  )

  const { day, date, hour, name } = toShow

  useEffect(() => {
    setToShow({
      day: moment(newTourBook.date).format("dddd"),
      date: moment(newTourBook.date).format("DD/MM"),
      hour: newTourBook.hour,
      name: oldTourBook.name,
    })
  }, [newTourBook])

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `Numero de tour: ${oldTourBook.id.toString()} Codigo de acceso ${
        oldTourBook.access_code
      }` || "No se copio"
    )
  }

  return (
    <Modal
      open={openEditFormModal}
      keepMounted
      sx={{
        display: "flex",
        jsutifyContent: "center",
        alignItems: "center",
        zIndex: "1000 !important",
        overflow: "scroll",
      }}
    >
      <Container>
        <FlexContainer>
          <TextContainer>
            <TitleText>
              {day} {date} - {hour}
            </TitleText>
            <TitleText>Tour {name}</TitleText>
          </TextContainer>
          <Icon
            alt="edit icon"
            src={IconEdit}
            onClick={() => dispatch(setStep(0))}
          />
        </FlexContainer>
        <FlexContainer sx={{ paddingBottom: "20px" }}>
          <TextContainer>
            <Text>Número de tour: {oldTourBook.id}</Text>
            <Text>Código de acceso: {oldTourBook.tour_book_code}</Text>
          </TextContainer>
          <Icon alt="copy icon" src={CopyIcon} onClick={handleCopy} />
        </FlexContainer>
        <FormEdit setOpenEditFormModal={setOpenEditFormModal} />
      </Container>
    </Modal>
  )
}
