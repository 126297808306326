import { styled, Typography } from "@mui/material";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100vw",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ContainerModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alingItems: "center",
  backgroundColor: "#FFF",
  width: "45%",
  padding: "20px",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
  [theme.breakpoints.down("lg")]: {
    width: "70vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
  },
}));

export const DateContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "15px 10px 30px",
  borderBottom: "4px solid #3366CC",
}));

export const Text = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "18.5px",
  lineHeight: "26px",
  color: "#000000",
}));
