import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up('md')]:{
      width: '30%',
      // alignItems: 'space-between'
    }
}));

export const Texto = styled(Typography)(({theme}) => ({
  fontFamily: "Raleway",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "27px",
  textAlign: "center",
  margin: "20px 20px",
  [theme.breakpoints.up('md')]:{
    margin: '20px 0px'
  }
}));

export const TextoResaltado = styled("span")(() => ({
  color: "#3366CC",
  fontWeight: "700",
}));

export const NeneTomate = styled("img")(({ theme }) => ({
  width: "160px",
  height: "124px",
  marginTop: "2rem",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export const NenaBicicleta = styled("img")(({ theme }) => ({
  width: "200px",
  height: "200px",
  marginTop: "2rem",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));
