import { Box, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 0px 47px 0px",
}));

export const ContainerLogoTitle = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "17px",
}));

export const ContainerLogo = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Logos = styled("img")(() => ({
  width: "176px",
  height: "49px",
}));

export const Title = styled("img")(() => ({
  width: "166px",
  height: "21px",
}));

export const SuccessImg = styled("img")(() => ({
  width: "86px",
  height: "86px",
  margin: "36px 0px 50px 0px",
}));

export const BodyContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "60%",
  gap: "10px",
  padding: "10px",
}));

export const BodyTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "400",
  fontSize: "29px",
  lineHeight: "36px",
  textAlign: "center",
  color: "#000000",
}));

export const BodyText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#000000",
}));

export const ButtonContinuar = styled(Button)(() => ({
  borderRadius: "86px",
  width: "70%",
  padding: "15px 30px",
  marginTop: "35px",
}));
