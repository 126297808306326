import {
  Dispatch,
  SetStateAction,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  ContainerPage,
  Container,
  ContainerSpinner,
  TitleContainer,
  Title,
  FlexContainer,
  Icon,
  IconText,
  NextButton,
  FlexTitleContainer,
} from "./styles/toursStyles";
import { getAvailableWeekHour } from "../../../redux/reservas/toursSlice";
import { Tour } from "./Tour";
import { Spinner } from "../../Spinner";
import BackIcon from "../../../assets/images/profile/backIcon.svg";
import moment from "moment";
import "moment/locale/es";
import { ModalEdit } from "../ModalEdit";
import { ModalEditForm } from "../ModalEditForm";

interface IProps {
  setHandleOpen: Dispatch<SetStateAction<boolean>>;
  selectedWeek: any;
  setSelectedWeek: any;
  handleBack: () => void;
}

export const Tours: FunctionComponent<IProps> = ({
  setHandleOpen,
  selectedWeek,
  setSelectedWeek,
  handleBack,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { reserva } = useAppSelector((state: RootState) => state.reservas);
  const { hours, loading } = useAppSelector((state: RootState) => state.tours);
  const { edit, newTourBook } = useAppSelector((state: any) => state.editTour);

  const [availableWeekFormat, setAvailableWeekFormat] = useState<any>([]);
  const [openEditConfirmModal, setOpenEditConfirmModal] = useState(false);
  const [openEditFormModal, setOpenEditFormModal] = useState(false);

  moment.locale("es");

  const generateWeek = (startDay: string) => {
    const current = new Date(startDay);
    const day = current.getDay();
    if (day === 4) {
      current.setDate(current.getDate() - 4);
    } else if (day === 3) {
      current.setDate(current.getDate() - 3);
    } else if (day === 2) {
      current.setDate(current.getDate() - 2);
    } else if (day === 1) {
      current.setDate(current.getDate() - 1);
    }

    const week = [];
    const weekFormat: any = [];
    current.setDate(current.getDate() - current.getDay() + 1);
    for (let i = 0; i < 5; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    week.forEach((w) => {
      weekFormat.push({
        date: moment(w).format("YYYY-MM-DD"),
        hours: [],
        available: false,
      });
    });
    return weekFormat;
  };

  const filterAvailableHours = (week: any) => {
    for (let i = 0; i < week.length; i++) {
      for (let j = 0; j < hours.length; j++) {
        if (week[i].date === hours[j].date && hours[j].available === true) {
          week[i].hours = [...week[i].hours, hours[j].hour];
          week[i].available = true;
        }
      }
    }

    setAvailableWeekFormat(week);
  };

  useEffect(() => {
    if (edit) {
      dispatch(getAvailableWeekHour(newTourBook.date));
    } else {
      dispatch(getAvailableWeekHour(reserva.date));
    }
  }, [dispatch]);

  useEffect(() => {
    if (hours.length > 0 && !edit) {
      const weekG = generateWeek(reserva.date);
      filterAvailableHours(weekG);
    }
    if (hours.length > 0 && edit) {
      const weekG = generateWeek(newTourBook.date);
      filterAvailableHours(weekG);
    }
  }, [hours]);

  return (
    <ContainerPage>
      <TitleContainer>
        <FlexContainer onClick={handleBack}>
          <Icon alt="Back Icon" src={BackIcon} />
          <IconText>Volver</IconText>
        </FlexContainer>
        <FlexTitleContainer>
          <Title>{edit ? "Elegí un nuevo TURNO:" : "Elegí un TURNO"}</Title>
          {edit && (
            <NextButton
              variant="contained"
              color="primary"
              onClick={() => setOpenEditConfirmModal(true)}
            >
              Siguiente
            </NextButton>
          )}
        </FlexTitleContainer>
      </TitleContainer>

      <Container>
        {!loading ? (
          availableWeekFormat.map((element: any, i: any) => (
            <Tour
              key={i}
              setHandleOpen={setHandleOpen}
              setSelectedWeek={setSelectedWeek}
              setOpenEditConfirmModal={setOpenEditConfirmModal}
              element={element}
              i={i}
            />
          ))
        ) : (
          <ContainerSpinner>
            <Spinner width="80px" height="80px" color="#3366CC" />
          </ContainerSpinner>
        )}
      </Container>

      {edit && (
        <>
          <ModalEdit
            openEditConfirmModal={openEditConfirmModal}
            setOpenEditFormModal={setOpenEditFormModal}
            setOpenEditConfirmModal={setOpenEditConfirmModal}
          />

          <ModalEditForm
            openEditFormModal={openEditFormModal}
            setOpenEditFormModal={setOpenEditFormModal}
          />
        </>
      )}
    </ContainerPage>
  );
};
