import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

import { HeaderLayout } from "../../layouts/HeaderLayout";
import { RegisterForm } from "./RegisterForm";
import { MobileSuccess } from "./MobileSuccess";

import { useMediaQuery } from "@mui/material";

export const Register = () => {

  const { success } = useAppSelector(
    (state: RootState) => state.auth
  );
  const isMobile = useMediaQuery("(max-width:900px)");

  return (
    <>
      {
        success && isMobile
          ? <MobileSuccess />
          :
          <HeaderLayout>
            <RegisterForm />
          </HeaderLayout>
      }
    </>
  )
}