import { useRef, useState, useEffect, TouchEvent } from "react";
import { useMediaQuery } from "@mui/material";
import {
  ContainerCard,
  ContainerVideo,
  Video,
  LineasVideo,
  ContainerDescription,
  ContainerActions,
  Title,
  Subtitle,
  AnimVideo,
} from "./styles";

import Lineas from "../../../assets/images/lineasCirculos.svg";

interface ElementProps {
  src: string;
  title: string;
  desc: string;
  video?: string;
  id?: string | undefined;
}

type Props = {
  element?: ElementProps;
  count?: any;
  setCount?: any;
  handleChangeCarousel?: any
};

export const CardHome = ({ element, count, setCount, handleChangeCarousel}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const animRef = useRef<HTMLVideoElement | null>(null);

  let newCount = count && count + 1

  const isMobile = useMediaQuery("(max-width:900px)");

  const handlePlay = () => {
    videoRef.current?.play();
    animRef.current?.play();
  };

  const handleStop = () => {
    videoRef.current?.pause();
    animRef.current?.pause();
  };


  const [touchStart, setTouchStart] = useState<any>(null)
  const [touchEnd, setTouchEnd] = useState<any>(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 20

  const onTouchStart = (e: TouchEvent<HTMLImageElement>) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e: TouchEvent<HTMLImageElement>) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = (e: TouchEvent<HTMLImageElement>) => {
    // console.log(`TOUCH START: ${touchStart}`)
    // console.log(`TOUCH END: ${touchEnd}`)
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
    // add your conditional logic here
  }



  return (
    <ContainerCard>
      <ContainerVideo>
        <Video muted loop src={element?.src} ref={videoRef} autoPlay={isMobile} />
        <LineasVideo
          id={element?.id}
          src={Lineas}
          alt="Lineas del video"
          onMouseEnter={handlePlay}
          onMouseLeave={handleStop}
          onTouchMove={onTouchMove}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
        />
      </ContainerVideo>
      <ContainerActions>
        <ContainerDescription>
          <Title>{element?.title}</Title>
          <Subtitle>{element?.desc}</Subtitle>
          {element?.video && (
            <AnimVideo loop muted ref={animRef} src={element.video} />
          )}
        </ContainerDescription>
      </ContainerActions>
    </ContainerCard>
  );
};
