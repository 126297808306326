
import { axiosInstance } from "./axiosInstance";



export const enterTourCalls = {
  enterTour: async (bodyParameters: any) => {
    const response = await axiosInstance.post(
      `/tours/attendToReservation`,
      bodyParameters
    );
    return response;
  },
};
