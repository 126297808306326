import { WaitingRoom } from "../components/WaitingRoom"
import { HeaderLayout } from "../layouts/HeaderLayout"
import { Container } from "./styles/salaDeEsperaStyles"

import ReactGA from "react-ga4";

export const SalaDeEspera = () => {

  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "SalaDeEspera Page" });

  return (
    <Container>
      <HeaderLayout>
        <WaitingRoom />
      </HeaderLayout>
    </Container>
  )
}
