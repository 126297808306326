import { Container, Title, ContentContainer, FirstContainer, SecondContainer, FlexContainer, Subtitle, Text } from './style';
import { information } from './information';


type InfoProps = {
  title: string;
  firstP: string;
  secondP?: string;
  thirdP?: string;
  fourthP?: string;
  fifthP?: string;
  sixthP?: string;
}

export const TermsAndConditions = () => {
  return (
    <Container>
      <Title>TÉRMINOS Y CONDICIONES LEGALES</Title>
      <ContentContainer>
        <FirstContainer>
          <FlexContainer>
            <Subtitle>{information[0].title}</Subtitle>
            <Text>{information[0].firstP}</Text>
            <Text>{information[0].secondP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[1].title}</Subtitle> {information[1].firstP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[2].title}</Subtitle> {information[2].firstP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[3].title}</Subtitle> {information[3].firstP}</Text>
            <Text>{information[3].secondP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[4].title}</Subtitle> {information[4].firstP}</Text>
            <Text>{information[4].secondP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[5].title}</Subtitle> {information[5].firstP}</Text>
            <Text>{information[5].secondP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[6].title}</Subtitle> {information[6].firstP}</Text>
          </FlexContainer>
        </FirstContainer>
        <SecondContainer>
          <FlexContainer>
            <Text><Subtitle>{information[7].title}</Subtitle> {information[7].firstP}</Text>
            <Text>{information[7].secondP}</Text>
            <Text>{information[7].thirdP}</Text>
            <Text>{information[7].fourthP}</Text>
            <Text>{information[7].fifthP}</Text>
            <Text>{information[7].sixthP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[8].title}</Subtitle> {information[8].firstP}</Text>
          </FlexContainer>
          <FlexContainer>
            <Text><Subtitle>{information[9].title}</Subtitle> {information[9].firstP}</Text>
            <Text>{information[9].secondP}</Text>
          </FlexContainer>
        </SecondContainer>
      </ContentContainer>
    </Container>
  )
}