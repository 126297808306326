
import { ContainerPage } from './styles/quienesSomosPageStyles';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { CancelTour } from '../components/cancelTour';

import ReactGA from "react-ga4";

export const CancelTourPage = () => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Cancel Tour" });

    return (
        <ContainerPage>
            <HeaderLayout>
                <CancelTour />
            </HeaderLayout>
        </ContainerPage>
    )
}