import { sweetAlertMixin } from "./../../helpers/sweetAlert";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authApiCalls } from "../../services/authApiCalls";
import {
  ForgotProps,
  ResetProps,
  PropsInitialState,
  PropsLogin,
  PropsRegister,
} from "./interfaces";

const initialState: PropsInitialState = {
  user: {
    access_token: "",
    expires_in: 0,
    token_type: "",
    user: {
      email: JSON.parse(sessionStorage.getItem("user")!)?.email || "",
      id: JSON.parse(sessionStorage.getItem("user")!)?.id || 0,
      name: JSON.parse(sessionStorage.getItem("user")!)?.name || "",
    },
  },
  loading: false,
  error: "",
  success: false,
  token: sessionStorage.getItem("token") || null,
};

export const registerUser = createAsyncThunk(
  "auth/register-user",
  async (values: PropsRegister, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await authApiCalls.register(values);

      if (response.status !== 200) {
        return rejectWithValue(response.data);
      }
      // sweetAlertMixin("success", "Se registro correctamente, te enviamos un email para validar tu correo.")
      return fulfillWithValue(response);
    } catch (error: any) {
      if (error.response.status === 422) {
        // sweetAlertMixin("error", "El email ya se encuentra registrado")
        throw rejectWithValue("El email ya se encuentra registrado");
      } else {
        // sweetAlertMixin("error", "Ocurrió un error inesperado, intente nuevamente mas tarde.")
        throw rejectWithValue(
          "Ocurrió un error inesperado, intente nuevamente mas tarde"
        );
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login-user",
  async (values: PropsLogin, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await authApiCalls.login(values);

      if (response.status !== 200) {
        return rejectWithValue(response.data);
      }
      return fulfillWithValue(response);
    } catch (error: any) {
      if (error.response.status === 401) {
        sweetAlertMixin("error", "Email o contraseña invalidos.");
        throw rejectWithValue("Email o contraseña invalidos.");
      } else if (error.response.status === 403) {
        sweetAlertMixin(
          "error",
          "Cuenta sin habilitar, verifica tu email para activarla."
        );
        throw rejectWithValue(
          "Cuenta sin habilitar, verifica tu email para activarla."
        );
      } else {
        sweetAlertMixin(
          "error",
          "Ocurrió un error inesperado, intente nuevamente mas tarde."
        );
        throw rejectWithValue(
          "Ocurrió un error inesperado, intente nuevamente mas tarde"
        );
      }
    }
  }
);

export const forgotPasswordAsync = createAsyncThunk(
  "auth/forgot-password",
  async (values: ForgotProps, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await authApiCalls.forgotPassword(values);
      if (response.status !== 200) {
        return rejectWithValue(response.data);
      }
      sweetAlertMixin(
        "success",
        "Te enviamos un email para que puedas cambiar tu clave."
      );
      return fulfillWithValue(response);
    } catch (error: any) {
      if (error.response.status === 401) {
        sweetAlertMixin("error", "El email no esta registrado");
        throw rejectWithValue("El email no esta registrado");
      } else {
        sweetAlertMixin(
          "error",
          "Ocurrió un error inesperado, intente nuevamente mas tarde."
        );
        throw rejectWithValue(
          "Ocurrio un error inesperado, intente nuevamente mas tarde."
        );
      }
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "auth/reset-password",
  async (values: ResetProps, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await authApiCalls.resetPassword(values);
      if (response.status !== 200) {
        return rejectWithValue(response.data);
      }
      sweetAlertMixin("success", "Tu clave fue modificada con éxito.");
      return fulfillWithValue(response.data);
    } catch (error: any) {
      sweetAlertMixin(
        "error",
        "Ocurrió un error inesperado, intente nuevamente mas tarde."
      );
      throw rejectWithValue(
        "Ocurrió un error inesperado, intente nuevamente mas tarde"
      );
    }
  }
);

export const registerSuccess = createAsyncThunk(
  "auth/register-success",
  async (token: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await authApiCalls.registerSuccess(token);
      if (response.status !== 200) {
        return rejectWithValue(response.data);
      }
      return fulfillWithValue(response.data);
    } catch (error: any) {
      throw rejectWithValue(
        "Ocurrió un error inesperado, intente nuevamente mas tarde"
      );
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: (state) => {
      state.error = "";
      state.success = false;
    },
    logout: (state) => {
      sessionStorage.clear();
      state.user = {
        access_token: "",
        expires_in: 0,
        token_type: "",
        user: {
          email: "",
          id: 0,
          name: "",
        },
      };
      state.loading = false;
      state.error = "";
      state.success = false;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = "";
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = "";
      })
      .addCase(registerUser.rejected, (state, { payload }: any) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = "";
      })
      .addCase(loginUser.fulfilled, (state, { payload }: any) => {
        const user = {
          id: payload.data.user.id,
          name: payload.data.user.name,
          email: payload.data.user.email,
        };
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("token", payload.data.access_token);
        state.token = payload.data.access_token;
        state.loading = false;
        // state.success = true;
        // state.error = '';
        state.user = payload.data;
      })
      .addCase(loginUser.rejected, (state, { payload }: any) => {
        // sessionStorage.clear();
        state.loading = false;
        // state.success = false;
        // state.error = payload;
        state.user = {
          access_token: "",
          expires_in: 0,
          token_type: "",
          user: {
            email: "",
            id: 0,
            name: "",
          },
        };
      })
      .addCase(forgotPasswordAsync.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = "";
      })
      .addCase(forgotPasswordAsync.fulfilled, (state) => {
        state.loading = false;
        // state.success = true;
        // state.error = '';
      })
      .addCase(forgotPasswordAsync.rejected, (state, { payload }: any) => {
        // state.error = payload;
        state.loading = false;
        state.success = false;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = "";
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        // state.error = '';
      })
      .addCase(resetPasswordAsync.rejected, (state, { payload }: any) => {
        // state.error = payload;
        state.loading = false;
        state.success = false;
      })
      .addCase(registerSuccess.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = "";
      })
      .addCase(registerSuccess.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = "";
      })
      .addCase(registerSuccess.rejected, (state, { payload }: any) => {
        state.error = payload;
        state.loading = false;
        state.success = false;
      });
  },
});

export const { resetState, logout } = authSlice.actions;

export default authSlice.reducer;
