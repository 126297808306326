import {
  ContainerRecomendations,
  ListRecomendations,
  Texto,
  TextoResaltado,
} from "./styles"

export const Recomendations = () => {
  return (
    <ContainerRecomendations>
      <Texto>
        <TextoResaltado>Recomendaciones:</TextoResaltado>
      </Texto>
        <ListRecomendations>
          <li><Texto>La experiencia funciona mejor con los navegadores Chrome o Edge.</Texto></li>
          <li><Texto>Si es posible, cerrá todas las ventanas y pestañas que tengas abiertas en tu computadora.</Texto></li>
          <li><Texto>Revisá tu conexión a internet para asegurarte que funcione correctamente.</Texto></li>
        </ListRecomendations>
    </ContainerRecomendations>
  )
}
