import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { previousStep } from '../../redux/reservas/reservasSlice';
import { getAllTours } from '../../redux/reservas/toursSlice';
import { Step } from '../../helpers/steps';
import { Tours } from "./ReservaHour/Tours";
import { ReservaModal } from './Modal/index';
import { Calendar } from '../Calendar';
import { ReservaRecorrido } from './ReservaRecorrido/ReservaRecorrido';



export const HomeReserva = () => {

    const dispatch = useAppDispatch();
    const { currentStep } = useAppSelector((state: RootState) => state.reservas);

    const [handleOpen, setHandleOpen] = useState(false);
    const [handleClose, setHandleClose] = useState(false);
    const [selectedWeek, setSelectedWeek] = useState<any>([])

    const handleBack = () => {
        setSelectedWeek([]);
        dispatch(previousStep());
    }


    useEffect(() => {
        if (currentStep === 2) {
            setHandleOpen(true)
        } else {
            setHandleOpen(false);
        }
    }, [currentStep])

    return (
        <>

            {currentStep === 0 &&
                <Calendar />
            }

            {(currentStep === 1 || currentStep === 2) &&
                <Tours selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} setHandleOpen={setHandleOpen} handleBack={handleBack} />
            }

            {currentStep === 2 &&
                <ReservaModal handleOpen={handleOpen} setHandleOpen={setHandleOpen} setHandleClose={setHandleClose} />
            }

            {currentStep === 3 &&
                <ReservaRecorrido />
            }
        </>
    )
}