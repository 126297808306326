import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const Btn = styled(Button)(({ theme }) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "15px",
  textAlign: "center",
  color: "#FFFFFF",
  borderRadius: "86px",
  padding: "11.25px 22.5px",
  [theme.breakpoints.up(900)]: {
    marginRight: "25px",
  },
  [theme.breakpoints.up("lg")]: {
    marginRight: "0px",
  },
}));
