import { useState, useEffect } from "react";
import {
  MonthTitleContainer,
  MonthTitleText,
  Icon,
  CardContainer,
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionDetailsStyled,
} from "./styles";
import { Card } from "../Card";
import { translate } from "../dictionary";
import { useMediaQuery } from "@mui/material";
import ArrowIcon from "../../../assets/images/misTours/Arrowicon.svg";

export const SwipeContainer = ({ element }: any) => {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [isOpen, setIsOpen] = useState(true);
  const [orderElement, setOrderElement] = useState<any>([]);

  useEffect(() => {
    const elements = element.bookings;
    const dateOrderElements = [...elements].sort((a: any, b: any): any => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    setOrderElement(dateOrderElements);
  }, [element.bookings]);

  return (
    <AccordionStyled expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <AccordionSummaryStyled>
        <MonthTitleContainer>
          <MonthTitleText>
            {translate(element.title)}{" "}
            <span>({element.bookings.length} bookings)</span>
          </MonthTitleText>

          <Icon
            alt="Arrow icon"
            src={ArrowIcon}
            isOpen={isOpen}
            isMobile={isMobile}
          />
        </MonthTitleContainer>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <CardContainer isMobile={isMobile}>
          {orderElement?.map((el: any, index: number) => (
            <Card key={index} element={el} />
          ))}
        </CardContainer>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
