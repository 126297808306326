import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { doneBookings } from "../../redux/profile/profileSlice";
import { useMediaQuery } from "@mui/material";

import {
  Container,
  TitleContainer,
  Title,
  QuantityContainer,
  NumberToursText,
  ToursText,
  ButtonBooking,
  MonthContainer,
  FloatContainer,
  Btn,
  EmptyBookings,
} from "./styles";
import { SwipeContainer } from "./SwipeContainer";
import { Spinner } from "../Spinner";

interface Booking {
  // deberiamos agregar las propiedades que necesitemos
  id: string;
}

interface MonthlyBooking {
  // deberiamos agregar las propiedades que necesitemos
  month: string;
  bookings: Booking[];
}

export const MyTours = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:900px)");
  const dispatch = useAppDispatch();

  // Combine related state selectors
  const { doneBookingsShow, loading } = useAppSelector(
    (state: RootState) => state.profile
  );
  const { user } = useAppSelector((state: RootState) => state.auth);

  // Calculate total bookings using useMemo for performance
  const totalBookings = useMemo(
    () =>
      doneBookingsShow.reduce(
        (acc: number, el: MonthlyBooking) => acc + el.bookings.length,
        0
      ),
    [doneBookingsShow]
  );

  useEffect(() => {
    const token = user.access_token ?? sessionStorage.getItem("token");
    dispatch(doneBookings(token));
  }, [dispatch, user.access_token]);

  const renderEmptyState = useCallback(
    () =>
      !doneBookingsShow?.length &&
      !loading && <EmptyBookings>No tenés reservas realizadas</EmptyBookings>,
    [doneBookingsShow, loading]
  );

  const renderBookings = useCallback(
    () =>
      doneBookingsShow?.length > 0 &&
      !loading && (
        <MonthContainer>
          {doneBookingsShow.map((el: MonthlyBooking, index: number) => (
            <SwipeContainer element={el} key={`booking-${index}`} />
          ))}
        </MonthContainer>
      ),
    [doneBookingsShow, loading]
  );

  const renderFloatingButton = useCallback(
    () =>
      isMobile && (
        <FloatContainer>
          <Btn
            variant="contained"
            color="primary"
            onClick={() => navigate("/reserva-tour")}
          >
            RESERVÁ NUEVO TOUR
          </Btn>
        </FloatContainer>
      ),
    [isMobile, navigate]
  );

  return (
    <Container>
      <TitleContainer>
        <Title isMobile={isMobile}>Mis Tours realizados</Title>
        <QuantityContainer isMobile={isMobile}>
          <NumberToursText>{totalBookings}</NumberToursText>
          <ToursText>realizados</ToursText>
        </QuantityContainer>
        <ButtonBooking
          variant="contained"
          isMobile={isMobile}
          onClick={() => navigate("/reserva-tour")}
        >
          RESERVÁ NUEVO TOUR
        </ButtonBooking>
      </TitleContainer>

      {renderEmptyState()}
      {renderBookings()}
      {loading && <Spinner width="40px" height="40px" color="#3366CC" />}
      {renderFloatingButton()}
    </Container>
  );
};
