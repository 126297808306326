import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  FormHelperText,
  FormControl,
} from "@mui/material";

export const Form = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "15px",
  marginTop: "15px",
  [theme.breakpoints.up("sm")]: {
    marginTop: "30px",
  },
}));

export const ControlForm = styled(FormControl)(() => ({
  width: "100%",
  textAlign: "start",
}));

export const ButtonContainer = styled(Box)(() => ({
  marginTop: "10px",
  width: "100%",
  marginBottom: "20px",
  gap: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Botones = styled(Button)(() => ({
  paddingTop: "15px",
  paddingBottom: "15px",
  gap: "10px",
  borderRadius: "86px",
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "19px",
  textAlign: "center",
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
  [theme.breakpoints.up("sm")]: {
    width: "38%",
  },
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#000000",
  textAlign: "center",
}));

export const InputErrors = styled(FormHelperText)(() => ({
  marginBottom: 0,
  paddingBottom: 0,
  color: "#D32F2F",
}));
