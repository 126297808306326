import { ContainerPage } from './styles/quienesSomosPageStyles';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { ComingSoon } from '../components/ComingSoon';

import ReactGA from "react-ga4";

export const ComingSoonPage = () => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Coming Soon" });

    return (
        <ContainerPage>
            <HeaderLayout>
                <ComingSoon />
            </HeaderLayout>
        </ContainerPage>
    )
}