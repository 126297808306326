import { styled } from "@mui/material";
import Image from "../../assets/images/auth/FondoDesktop.svg";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100vw",
  minHeight: "100vh",
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));
