import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

type Props = {
  selected: boolean;
};

export const Container = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "10px",
  borderBottom: "4px solid #3366CC",
  paddingBottom: "20px",
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "21px",
  lineHeight: "24.75px",
  color: "#000000",
}));

export const Subtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "10.5px",
  lineHeight: "20.25px",
  color: "#000000",
}));

export const FaceContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const SubContainerFace = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const RoundedContainer = styled(Box)<Props>(({ selected, theme }) => ({
  width: "47px",
  height: "47px",
  backgroundColor: selected ? "#3366CC" : "#E0E0E0",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "all .3s ease",
  [theme.breakpoints.up("sm")]: {
    width: "71px",
    height: "71px",
  },
}));

export const Emoji = styled("img")(({ theme }) => ({
  width: "35px",
  height: "35px",
  [theme.breakpoints.up("sm")]: {
    width: "51px",
    height: "51px",
  },
}));

export const Number = styled(Typography)<Props>(({ selected, theme }) => ({
  fontFamily: "Raleway",
  fontWeight: selected ? 700 : 500,
  fontSize: "18px",
  lineHeight: "21px",
  color: selected ? "#000000" : "#757575",
  transition: "all .3s ease",
  [theme.breakpoints.up("sm")]: {
    fontSize: "27.3px",
    lineHeight: "32.25px",
  },
}));
