import { Container, Title } from "./styles"
import { Card } from "../../Card"
import { ProfileProps } from "../../../../redux/profile/profileSlice"

export const NextBookings = ({ profile }: { profile: ProfileProps[] }) => {
  return (
    <Container>
      <Title>Próximo Tour</Title>
      <Card nextBooking={true} element={profile[0]} />
    </Container>
  )
}
