import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../redux/hooks";
import { resetState } from "../../redux/auth/authSlice";
import {
  Container,
  ContainerLogoTitle,
  ContainerLogo,
  Logos,
  Title,
  SuccessImg,
  BodyContainer,
  BodyTitle,
  BodyText,
  ButtonContinuar,
} from "./styles/mobileSuccess";
import LogosJuntos from "../../assets/images/auth/Logos.svg";
import universoArcor from "../../assets/images/auth/UniversoArcor.svg";
import ImagenSuccess from "../../assets/images/auth/RegisterSuccess.svg";

export const MobileSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(resetState())
    navigate('/auth/login')
  }

  return (
    <Container>
      <ContainerLogoTitle>
        <ContainerLogo>
          <Logos alt="Logo de arcor y fundacion arcor" src={LogosJuntos} />
        </ContainerLogo>
        <Title alt="Universo Arcor" src={universoArcor} />
      </ContainerLogoTitle>
      <SuccessImg alt="Icono Success" src={ImagenSuccess} />
      <BodyContainer>
        <BodyTitle>Se registro correctamente</BodyTitle>
        <BodyText>Te enviamos un email para confirmar tu cuenta.</BodyText>
      </BodyContainer>
      <ButtonContinuar
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleClick}
      >
        Continuar
      </ButtonContinuar>
    </Container>
  );
};
