import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "90vw",
  minHeight: "100vh",
  background: "#FFFFFF",
  borderRadius: "14px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "30px",
  margin: "auto",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "50vw",
    minHeight: "50vh",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "30vw",
  },
}));

export const TitleText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "23px",
  color: "#000000",
}));

export const Text = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "27px",
  color: "#000000",
}));

export const FlexContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "10px",
  padding: "15px 10px 20px 9px",
  borderBottom: "4px solid #3366CC",
}));

export const TextContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flex: 1,
}));

export const Icon = styled("img")(() => ({
  width: "17px",
  height: "17px",
  cursor: "pointer",
}));
