import { ContainerAlerts, ButtonAlerts } from "./styles/styles"
import { useAppDispatch } from "../../redux/hooks"
import { suscribeAlert } from "../../redux/profile/profileSlice"
import { useState } from "react"
import { Typography } from "@mui/material"

export const ButtonSuscribeAlerts = () => {
  const dispatch = useAppDispatch()
  const [wasClicked, setWasClicked] = useState(false)

  const handleClick = () => {
    dispatch(suscribeAlert("Avisame"))
    setWasClicked(true)
  }

  return wasClicked ? (
    <ContainerAlerts>
      <Typography>
        Muchas gracias, le avisaremos cuando se carguen nuevos turnos.
      </Typography>
    </ContainerAlerts>
  ) : (
    <ContainerAlerts>
      <Typography>
        Si los turnos disponibles no se ajustan a tus horarios o no hay turnos
        disponibles. Hace click y te avisaremos vía mail cuando haya nuevos
        lugares disponibles.
      </Typography>
      <ButtonAlerts onClick={handleClick} variant="contained" color="primary">
        ¡Avisame!
      </ButtonAlerts>
    </ContainerAlerts>
  )
}
