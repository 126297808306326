import { styled, Box, Typography, Button } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "80vw",
  backgroundColor: "#FFF",
  display: "flex",
  flexDirection: "column",
  alingItems: "center",
  padding: "20px",
  gap: "20px",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
  margin: "20px auto 20px auto",
  [theme.breakpoints.up("sm")]: {
    width: "60vw",
  },
  [theme.breakpoints.up("md")]: {
    width: "40vw",
  },
  [theme.breakpoints.up("lg")]: {
    width: "30vw",
  },
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontWeight: 400,
  fontSize: "45px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#3366CC",
}));

export const FlexContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "10px",
  padding: "15px 10px 20px 9px",
  borderBottom: "4px solid #3366CC",
}));

export const TextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flex: 1,
}));

export const Text = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "22px",
  lineHeight: "26px",
  color: "#000000",
}));

export const Icon = styled("img")(() => ({
  width: "17px",
  height: "17px",
  cursor: "pointer",
}));

export const ImagesContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: "44px",
}));

export const Images = styled("img")(() => ({
  width: "113px",
  height: "66px",
}));

export const ButtonsContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
}));

export const Botones = styled(Button)(() => ({
  width: "100%",
  padding: "15px 30px",
  gap: "10px",
  borderRadius: "86px",
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "19px",
  textAlign: "center",
}));
