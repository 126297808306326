import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FondoDesktop from "../../assets/images/contacto/FondoDesktop.svg";
import FondoMobile from "../../assets/images/contacto/FondoMobile.svg";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  backgroundImage: `url(${FondoMobile})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
  [theme.breakpoints.up(550)]: {
    backgroundImage: `url(${FondoDesktop})`,
    backgroundSize: "cover",
  },
}));
