import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";

export const ContainerPage = styled(Box)(({ theme }) => ({
  minHeight: "calc(100vh - 135px) !important",
  marginBottom: "50px",
  [theme.breakpoints.up("lg")]: {
    marginBottom: "30px",
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "20px",
  margin: "58px auto 0px auto",
  [theme.breakpoints.up("lg")]: {
    width: "80%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
}));

export const ContainerSpinner = styled(Box)(() => ({
  width: "100%",
  height: "70vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  margin: "18px auto auto auto",
  [theme.breakpoints.up("lg")]: {
    margin: "auto",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Telolet",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "45px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#3366CC",
  alignSelf: "center",
  [theme.breakpoints.up("lg")]: {
    alignSelf: "flex-start",
  },
}));

export const FlexContainer = styled(Box)(() => ({
  width: "100%",
  alignSelf: "flex-start",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "55px",
  gap: "10px",
  cursor: "pointer",
}));

export const Icon = styled("img")(() => ({
  width: "24px",
  height: "24px",
}));

export const IconText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16.0678px",
  lineHeight: "19px",
  textAlign: "center",
  color: "#3366CC",
}));

export const FlexTitleContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-between',
  gap: '20px',
  [theme.breakpoints.up('md')]:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

export const NextButton = styled(Button)(() => ({
  padding: "11px 22px",
  gap: "10px",
  background: "#3366CC",
  borderRadius: "86px"
}))
