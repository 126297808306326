import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";


export const Container = styled(Box)(({ theme }) => ({
  width: "75vw",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px auto 0px auto",
  minHeight: "calc(100vh - 159px) !important",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    marginTop: "2rem",
    alignItems: "flex-start",
  },
  [theme.breakpoints.up(1500)]: {
    marginTop: "5rem",
  },
}));
