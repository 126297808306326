import { styled } from "@mui/material/styles";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Form } from "formik";
import FondoMobile from "../../../assets/images/auth/FondoMobileRegister.svg";

type Props = {
  success: boolean;
};

export const ContainerPage = styled(Box)<Props>(({ theme, success }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: `url(${success ? "" : FondoMobile})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  width: "75vw",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px auto 0px auto",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    marginTop: "2rem",
    alignItems: "flex-start",
  },
  [theme.breakpoints.up(1500)]: {
    marginTop: "5rem",
  },
}));

export const ContImgTitle = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "50px 0px 20px 0px",
  [theme.breakpoints.up("md")]: {
    marginTop: "20px",
    width: "50%",
  },
}));

export const ContainerTitle = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontWeight: "400",
  fontSize: "45px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#3366CC",
  marginBottom: "10px",
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16.9px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#000000",
  marginBottom: "20px",
  width: "80%",
  [theme.breakpoints.up(1200)]: {
    width: "60%",
  },
}));

export const ContainerImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "175px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    marginTop: "5.1rem",
  },
}));

export const Img = styled("img")(({ theme }) => ({
  width: "214px",
  height: "163px",
  [theme.breakpoints.up("md")]: {
    width: "300px",
    height: "231px",
  },
  [theme.breakpoints.up(1200)]: {
    width: "450px",
    height: "320px",
  },
}));

export const ContainerForm = styled(Form)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 5px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "14px",
  padding: "20px 30px",
  marginBottom: "24px",
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));

export const Titulo = styled(Typography)(() => ({
  color: "#103F63",
  fontWeight: "bold",
  fontSize: "1.5rem",
  fontFamily: "Raleway",
  width: "60%",
  textAlign: "center",
  marginBottom: "1rem",
}));

export const ButtonStyle = styled(Button)(() => ({
  padding: "12px 40px 12px 40px",
  borderRadius: "86px",
  fontSize: "16px",
  fontWeight: "700",
  fontFamily: "Raleway",
  textTransform: "uppercase",
  marginTop: "20px",
}));

export const ContainerLinks = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0px",
}));

export const Links = styled(Link)(() => ({
  fontFamily: "Raleway",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "149.4%",
  textAlign: "center",
  textDecoration: "underline",
  color: "#3366CC",
  marginBottom: "5px",
}));

export const DividerBottom = styled(Divider)(() => ({
  width: "100%",
  gap: "10px",
  height: "3px",
  background: "#1A72B8",
  borderRadius: "10px",
}));

export const Text = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "27px",
  textAlign: "center",
  color: "#3366CC",
  margin: "30px 0px",
}));
