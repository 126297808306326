import { Box, Drawer, List, ListItem, Divider } from "@mui/material"
import { styled } from "@mui/material/styles"

import background from "../../../assets/images/Menu/backgroundMenu.svg"

export const DrawerContainer = styled(Drawer)(() => ({
  width: "100vw",
  height: "100vh",
  minHeight: "100%",
  zIndex: "10000000000",
}))

export const Container = styled(Box)(() => ({
  width: "100vw",
  height: "100%",
  // background: `url(${background}), linear-gradient(#C1EEFF, #FFFACE)`,
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundAttachment: "scroll",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "30px 0px",
}))

export const CloseContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}))

export const Close = styled("img")(() => ({
  width: "35px",
  height: "35px",
  marginLeft: "20px",
  marginTop: "20px",
}))

export const ListContainer = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  marginLeft: "1.41rem",
  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
  },
}))

export const ItemText = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "22px",
  lineHeight: "26px",
  textAlign: "center",
  color: "#000000",
  marginBottom: "3.12rem",
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
}))

export const Button = styled("button")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  gap: "10px",
  width: "223px",
  height: "50px",
  background: "#3366CC",
  borderRadius: "97px",
  color: "#FFFFFF",
  fontSize: "22px",
  outline: "none",
  border: "none",
}))

export const ContainerCuenta = styled(Box)(() => ({
  display: "flex",
  marginLeft: ".5rem",
}))

export const DividerBottom = styled(Divider)(() => ({
  backgroundColor: "#1A72B8",
  width: "90%",
  margin: "7rem auto 0px auto",
  height: "3px",
  borderRadius: "10px",
}))

export const ContainerLogos = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2.5rem",
}))

export const Logo = styled("img")(({ theme }) => ({
  width: "150px",
  height: "auto",
  marginLeft: theme.spacing(1.5),
}))
