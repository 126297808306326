import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loginSchema } from "./loginSchema";
import { Formik, useField } from "formik";
import { useNavigate } from "react-router-dom";
import { loginUser, resetState } from "../../redux/auth/authSlice";
import { RootState } from "../../redux/store";
import { Spinner } from "../Spinner";
import Image from "../../assets/images/auth/ImageLogin.svg";

import { useMediaQuery } from "@mui/material";
import { TextField } from "@mui/material";
import {
  Container,
  ContainerTitle,
  Title,
  ContainerImage,
  Img,
  ContainerForm,
  ButtonStyle,
  Links,
  ContainerLinks,
} from "./styles";
import { sweetAlertMixin } from "../../helpers/sweetAlert";

interface FormValues {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:1100px)");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, token, error, success } = useAppSelector(
    (state: RootState) => state.auth
  );

  // useEffect(() => {
  //   if (error.length > 0) {
  //     sweetAlertMixin('error', `${error}`)
  //   }
  //   return
  // }, [error, dispatch]);


  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  return (
    <Container>
      <ContainerTitle>
        <Title>Inicia Sesión</Title>
        {!isMobile && (
          <ContainerImage>
            <Img src={Image} alt="Imagen de nenes" />
          </ContainerImage>
        )}
      </ContainerTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async (values, { resetForm }) => {
          await dispatch(loginUser(values));
          navigate("/user/profile")
          token && dispatch(resetState());
        }}
      >
        {({ getFieldProps, errors, touched }) => (
          <ContainerForm>
            <TextField
              sx={{ marginBottom: "1rem" }}
              type="text"
              {...getFieldProps("email")}
              error={touched.email && Boolean(errors.email)}
              label="Email"
              fullWidth
              helperText={touched.email && errors.email ? errors.email : null}
            />
            <TextField
              sx={{ marginBottom: "1rem" }}
              type="password"
              {...getFieldProps("password")}
              error={touched.password && Boolean(errors.password)}
              label="Contraseña"
              fullWidth
              helperText={
                touched.password && errors.password ? errors.password : null
              }
            />
            <ButtonStyle
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              {!loading ? (
                "INGRESAR"
              ) : (
                <Spinner color="#FFF" height={25} width={25} />
              )}
            </ButtonStyle>
            <ContainerLinks>
              <Links to="/auth/forgot-password" onClick={() => dispatch(resetState())}>Olvidé mi contraseña</Links>
              <Links to="/auth/register" onClick={() => dispatch(resetState())}>Aún no tengo usuario</Links>
            </ContainerLinks>
          </ContainerForm>
        )}
      </Formik>
      {isMobile && (
        <ContainerImage>
          <Img src={Image} alt="Imagen de nenes" />
        </ContainerImage>
      )}
    </Container>
  );
};
