import { CardHome } from "../CardHome"
import { Container } from "./styles"

import GifUno from "../../../assets/gifs/Plantas.mp4"
import GifDos from "../../../assets/gifs/Biblioteca.mp4"
import GifTres from "../../../assets/gifs/Caroya.mp4"

import videoUno from "../../../assets/gifs/nenaRosa.webm"
import videoDos from "../../../assets/gifs/Bicicleta.webm"
import videoTres from "../../../assets/gifs/neneAzul.webm"

export const Cards = () => {
  const elements = [
    {
      src: GifUno,
      title: "Conocé",
      desc: "LA HISTORIA DE LOS ALIMENTOS",
      video: videoUno,
    },
    {
      src: GifDos,
      title: "Disfrutá",
      desc: "DE UNA EXPERIENCIA VIRTUAL ÚNICA",
      video: videoDos,
    },
    {
      src: GifTres,
      title: "Descubrí",
      desc: "UNA GUIA 360 POR NUESTRAS PLANTAS",
      video: videoTres,
    },
  ]

  return (
    <Container>
      {elements.map((element, index) => (
        <CardHome key={index} element={element} />
      ))}
    </Container>
  )
}
