import * as Yup from 'yup';


export const validationSchemaReset = Yup.object({
    password: Yup.string().required('El campo es obligatorio')
    .matches(/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/, 'Las contraseñas deben tener 12 caracteres como mínimo, 1 mayúsculas, 1 minúsculas, 1 número y 1 símbolo entre los siguientes ( #?!@$%^&*- )'),
    password_confirmation: Yup.string().required('el campo es obligatorio')
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden.')
})

export const validationSchemaForgot = Yup.object({
    email: Yup.string().email('Ingresá un email valido')
    .required('El campo es obligatorio')
})