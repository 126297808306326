
import { axiosInstance } from "./axiosInstance";


interface PropsRegister {
  name: string;
  lastname: string;
  email: string;
  document_number: string;
  password: string;
  password_confirmation: string;
  telephone: string;
}

interface PropsLogin {
  email: string;
  password: string;
}

interface PropsForgotPassword {
  email: string;
}

interface PropsResetPassword {
  email?: string;
  password: string;
  token?: string;
  password_confirmation: string;
}

export const authApiCalls = {
  register: async (values: PropsRegister) => {
    const {
      name,
      lastname,
      email,
      document_number,
      password,
      password_confirmation,
      telephone,
    } = values;

    const response = await axiosInstance.post(`/registerUser`, {
      name,
      lastname,
      email,
      document_number,
      password,
      password_confirmation,
      telephone,
    });
    return response;
  },
  login: async (values: PropsLogin) => {
    const { email, password } = values;

    const response = await axiosInstance.post(`/auth/login`, {
      email,
      password,
      
    });
    return response;
  },
  forgotPassword: async (values: PropsForgotPassword) => {
    const { email } = values;

    const response = await axiosInstance.post(`/forgot-password`, {
      email,
    });
    return response;
  },
  resetPassword: async (values: PropsResetPassword) => {
    const { email, token, password, password_confirmation } = values;

    const response = await axiosInstance.post(`/password/reset`, {
      email,
      password,
      token,
      password_confirmation,
     
    });

    return response;
  },
  registerSuccess: async (token: string) => {
    const response = await axiosInstance.get(
      `/email/verifyAccount/${token}`
    );
    return response;
  },
};
