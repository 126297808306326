import { data } from "./data";
import { useMediaQuery } from "@mui/material";
import {
  BodyContainer,
  Container,
  SubContainer,
  ContainerLogo,
  ImageArcor,
  TitleContainer,
  Title,
  DescriptionContainer,
  Description,
  ImageLinkContainer,
  Text,
  LinkContainer,
  LinkTo,
  Image,
} from "./styles";

export const QuienesSomos = () => {
  const isMobile = useMediaQuery("(max-width:1200px)");

  return (
    <Container>
      {data.map((element, idx) => (
        <>
          <SubContainer key={idx} first={idx === 0}>
            <ContainerLogo>
              <ImageArcor src={element.logo} alt="Logo de Arcor" />
              {!isMobile && (
                <ImageLinkContainer second={idx === 1}>
                  <LinkContainer>
                    <Text>{element.textLink}</Text>
                    <a 
                      href={element.redirect} 
                      target="_BLANK"
                      rel="noreferrer"
                      style={{
                        fontFamily: "Raleway",
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "27px",
                        textAlign: "center",
                        color: "#3366CC"  
                      }}
                    >
                        {element.link}
                    </a>
                  </LinkContainer>
                  <Image src={element.image} alt="Descripcion Arcor" />
                </ImageLinkContainer>
              )}
            </ContainerLogo>
            <BodyContainer>
              <TitleContainer>
                <Title second={idx === 1}>{element.title}</Title>
              </TitleContainer>
              <DescriptionContainer>
                <Description second={idx === 1}>
                  {element.firstDesc}
                </Description>
                <Description>{element.secondDesc}</Description>
              </DescriptionContainer>
            </BodyContainer>
            {isMobile && (
              <ImageLinkContainer>
                <LinkContainer>
                  <Text>{element.textLink}</Text>
                  <LinkTo to="/">{element.link}</LinkTo>
                </LinkContainer>
                <Image src={element.image} alt="Descripcion Arcor" />
              </ImageLinkContainer>
            )}
          </SubContainer>
        </>
      ))}
    </Container>
  );
};
