import { useAppSelector } from "../../../redux/hooks"
import { RootState } from "../../../redux/store"
import { useNavigate } from "react-router-dom"
import { Container, Button } from "./styles"

export const Buttons = () => {
  const navigate = useNavigate()

  const { token } = useAppSelector((state: RootState) => state.auth)

  return (
    <>
      <Container>
        <Button onClick={() => navigate("/ingresar-tour")}>INGRESÁ TOUR</Button>
        <Button
          isReserve
          onClick={() =>
            token ? navigate("reserva-tour") : navigate("/auth/register")
          }
        >
          RESERVÁ TOUR
        </Button>
      </Container>
    </>
  )
}
