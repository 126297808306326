import { styled } from "@mui/material/styles";
import { FormHelperText, FormControl, Box, Button, Typography } from "@mui/material";

export const InputErrors = styled(FormHelperText)(() => ({
  marginBottom: 0,
  paddingBottom: 0,
  color: "#D32F2F",
}));

export const ControlForm = styled(FormControl)(() => ({
  width: "100%",
  textAlign: "start",
}));

export const Form = styled("form")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "15px",
  [theme.breakpoints.up("sm")]: {
    marginTop: "20px",
  },
}));

export const ContainerButton = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
}));

export const Buttons = styled(Button)(() => ({
  borderRadius: "86px",
  padding: "10px 20px",
}));

export const CancelButton = styled(Typography)(() => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "16px",
  textDecoration: "underline",
  color: "#000000",
  textTransform: 'uppercase',
  marginTop: '10px',
  cursor: 'pointer'
}))
