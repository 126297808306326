import { Container } from './styles/contactoPageStyles';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { Contacto } from '../components/Contacto';

import ReactGA from "react-ga4";

export const ContactoPage = () => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Contacto" });
    return (
        <Container>
            <HeaderLayout>
                <Contacto />
            </HeaderLayout>
        </Container>
    )
}