import { styled } from "@mui/material/styles";
import { Divider, Box } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

export const Nav = styled("nav")(({ theme }) => ({
  display: "flex",
  backgroundColor: "none",
  boxShadow: "none",
  justifyContent: "space-around",
  alignItems: "center",
  width: "80%",
  margin: "0px auto 0px auto",
  padding: "15px 0px",
  [theme.breakpoints.down(1100)]: {
    backgroundColor: "#FFF",
    justifyContent: "flex-start",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
    width: "100vw",
    height: "89px",
  },
}));

export const LogosContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
}));

export const LinksContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Logo = styled("img")(({ theme }) => ({
  width: "165px",
  height: "21px",
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
}));

export const Dividers = styled(Divider)(() => ({
  backgroundColor: "rgb(51, 102, 204)",
}));

export const Links = styled(Link)(() => ({
  margin: "0px 10px",
  textDecoration: "none",
  fontSize: ".7rem",
  color: "rgb(16, 63, 99)",
  fontFamily: "Raleway",
  padding: "2.5px 5px",
  fontWeight: "bold",
  "&:hover": {
    borderBottom: "2px solid rgb(16, 63, 99)",
    // fontWeight: 900
  },
}));

// Mobile Navbar

export const Menu = styled(MenuIcon)(({ theme }) => ({
  fontSize: "2.7rem",
  marginLeft: "1.5rem",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

export const ContainerLogoMobile = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "20px",
  gap: "15px",
}));
