import * as Yup from "yup";

export const registerSchema = Yup.object({
  name: Yup.string().required("El campo es obligatorio"),
  lastname: Yup.string().required("El campo es obligatorio"),
  email: Yup.string()
    .required("El campo es obligatorio")
    .email("Ingrese un email válido"),
  document_number: Yup.string()
    .matches(/^[0-9]+$/, "Deben ser números")
    .min(8, "Debe tener 8 dígitos")
    .max(8, "Debe tener 8 dígitos")
    .required("El campo es obligatorio"),
  password: Yup.string()
    .required("El campo es obligatorio")
    .matches(
      /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/,
      "Las contraseñas deben tener 12 caracteres como mínimo, 1 mayúsculas, 1 minúsculas, 1 número y 1 símbolo entre los siguientes ( #?!@$%^&*- )"
    ),
  password_confirmation: Yup.string()
    .required("El campo es obligatorio")
    .oneOf([Yup.ref("password")], "Las contraseñas no coinciden."),
  telephone: Yup.string()
    .matches(
      /^\+?[0-9]{1,3}[-. (]?[0-9]{1,3}[-. )]?[0-9]{1,4}[-. ]?[0-9]{1,4}$/,
      "Ingrese un número de teléfono válido"
    )
    .required("El campo es obligatorio"),
});
