import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { contactoSchema } from "./contactoSchema";
import { TextField } from "@mui/material";
import { Spinner } from "../Spinner";
import { sweetAlertMixin } from "../../helpers/sweetAlert";
import { ContactForm } from "../../services/contactApiCalls";
import ImagenNena from "../../assets/images/contacto/NenaContacto.svg";
import ImagenFrutas from "../../assets/images/contacto/Frutas.svg";
import {
  Container,
  ContainerForm,
  ContImgTitle,
  ContainerTitle,
  ContainerImagenes,
  Title,
  Subtitle,
  ImgFrutas,
  ImgNena,
  ButtonStyle,
} from "./styles";

interface initialStateProps {
  nombre: string;
  email: string;
  telefono: number | string;
  mensaje: string;
}

export const Contacto = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const initialState: initialStateProps = {
    nombre: "",
    email: "",
    telefono: "",
    mensaje: "",
  };

  const { handleBlur, touched, errors, values, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: contactoSchema,
      onSubmit: async (values, { resetForm }) => {
        setLoading(true)
        const response = await ContactForm.sendMessage(values)
        if (response.status === 200) {
          setLoading(false)
          resetForm()
          setSuccess(true)
          setError(false)
        } else {
          setSuccess(false)
          setLoading(false)
          setError(true)
        }
      },
    });

  const { nombre, email, telefono, mensaje } = values;

  useEffect(() => {
    if (success) {
      sweetAlertMixin(
        "success",
        "Recibimos tu consulta, nos pondremos en contacto"
      )
    }
  }, [success])

  useEffect(() => {
    if (error) {
      sweetAlertMixin(
        "error",
        "Ocurrió un error, intentelo nuevamente mas tarde"
      )
    }
  }, [error])

  return (
    <Container>
      <ContImgTitle>
        <ContainerTitle>
          <Title>Dejános un mensaje</Title>
          <Subtitle>y tus datos para contactarte</Subtitle>
        </ContainerTitle>
        <ContainerImagenes>
          <ImgFrutas src={ImagenFrutas} alt="Imagen de Frutas" />
          <ImgNena src={ImagenNena} alt="Imagen Nena" />
        </ContainerImagenes>
      </ContImgTitle>
      <ContainerForm onSubmit={handleSubmit}>
        <TextField
          sx={{ marginBottom: "1rem" }}
          id="nombre"
          name="nombre"
          value={nombre}
          onChange={handleChange}
          label="Nombre y Apellido"
          onBlur={handleBlur}
          error={touched.nombre && Boolean(errors.nombre)}
          helperText={touched.nombre && errors.nombre ? errors.nombre : null}
          fullWidth
        />
        <TextField
          sx={{ marginBottom: "1rem" }}
          id="email"
          name="email"
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Email"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email ? errors.email : null}
          fullWidth
        />
        <TextField
          sx={{ marginBottom: "1rem" }}
          id="telefono"
          name="telefono"
          value={telefono}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          label="Telefono"
          error={touched.telefono && Boolean(errors.telefono)}
          helperText={touched.telefono && errors.telefono ? errors.telefono : null}
          fullWidth
        />
        <TextField
          sx={{ marginBottom: "1rem" }}
          id="mensaje"
          name="mensaje"
          value={mensaje}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          label="Deje su mensaje"
          multiline
          rows={4}
          maxRows={4}
          inputProps={{ maxLength: 250 }}
          error={touched.mensaje && Boolean(errors.mensaje)}
          helperText={
            touched.mensaje && errors.mensaje ? errors.mensaje : null
          }
          fullWidth
        />
        <ButtonStyle
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{ textTransform: "none" }}
          type="submit"
        >
          {
            loading ?
              <Spinner width='30px' height='30px' color='#000' />
              : "ENVIAR"
          }
        </ButtonStyle>
      </ContainerForm>
    </Container>
  );
};