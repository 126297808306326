import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const Container = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: '1.8rem',
    marginBottom: '1.8rem',
    [theme.breakpoints.up('md')]:{
        position: 'relative',
        alignItems: 'flex-start',
        marginTop: '2.5rem'
    }
}));

export const SubContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}))

export const Text = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '149%',
    fontFamily: 'Raleway',
    textAlign: 'center',
    color: '#4F4F4F',
}));

export const ContactLink = styled(Link)(() => ({
    color: '#3366CC',
    textDecoration: 'underline',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '149%',
    fontFamily: 'Raleway',
    textAlign: 'center',
}));

export const NenaVerdeAnim = styled("video")(({theme}) => ({
    display: 'none',
    [theme.breakpoints.up('md')]:{
        width: "230px",
        height: "230px",
        position: 'absolute',
        bottom: -20,
        right: '5%',
        display: 'block'
    }
  }));