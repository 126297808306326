import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from "../../redux/store";
import { Formik } from 'formik';
import { forgotPasswordAsync, resetState } from '../../redux/auth/authSlice';
import { validationSchemaForgot } from './validationSchema';
import { FormValuesForgot } from './interfaces';
import { Spinner } from '../Spinner';

import { TextField } from '@mui/material';

import { ContainerPage, Container, ContImgTitle, ContainerTitle, Title, ContainerImage, Img, ContainerForm, ButtonStyle, ContainerLinks, Links } from './styles/forgotPasswordStyles';

import image from '../../assets/images/auth/ImageRegister.svg';
import { useEffect } from 'react';
import { sweetAlertMixin } from '../../helpers/sweetAlert';

export const ForgotPasswordForm = () => {

    const dispatch = useAppDispatch();
    const { loading, error, success } = useAppSelector((state: RootState) => state.auth);

    const initialValues: FormValuesForgot = {
        email: ''
    }

    // useEffect(() => {
    //     if(error){
    //         sweetAlertMixin("error", "Ocurrió un error, verifica el email o vuelve a intentarlo mas tarde")
    //         resetState()
    //     }
    // }, [error])

    // useEffect(() => {
    //     if(success){
    //         sweetAlertMixin("success", "Te enviamos un email para que puedas cambiar tu clave.")
    //         resetState()
    //     }
    // }, [success])

    return (
        <ContainerPage>
            <Container>
                <ContImgTitle>
                    <ContainerTitle>
                        <Title>Recuperar Clave</Title>
                    </ContainerTitle>
                    <ContainerImage>
                        <Img src={image} alt="Imagen de Frutas" />
                    </ContainerImage>
                </ContImgTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaForgot}
                    onSubmit={async (values) => {
                        await dispatch(forgotPasswordAsync(values));
                    }}
                >
                    {({ getFieldProps, errors, touched }) => (
                        <ContainerForm>
                            <TextField
                                sx={{ marginBottom: "1rem" }}
                                type="email"
                                id="email"
                                {...getFieldProps("email")}
                                label="Email"
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email ? errors.email : null}
                                fullWidth
                            />
                            <ButtonStyle
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                sx={{ textTransform: "none" }}
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner color="#000" height={25} width={25} />
                                ) : (
                                    "RECUPERAR"
                                )}
                            </ButtonStyle>
                            <ContainerLinks>
                                <Links to="/auth/register">Aún no tengo usuario</Links>
                            </ContainerLinks>
                        </ContainerForm>
                    )}
                </Formik>
            </Container>
        </ContainerPage>
    )
}