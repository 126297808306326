import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const TitleText = styled(Typography)(() => ({
  fontSize: "34px",
  fontWeight: 400,
  lineHeight: "34px",
  fontFamily: "Telolet",
  textAlign: "center",
  color: "#3366CC",
}));

export const TitleImage = styled("img")(({ theme }) => ({
  width: "145px",
  height: "74px",
}));

export const BoyImage = styled("img")(({ theme }) => ({
  width: "225px",
  height: "222px"
}));
