// import "react-loader-spinner/dist/loader/TailSpin";
import { TailSpin } from 'react-loader-spinner';



export const Spinner = (props: any) => {

    return(
        <TailSpin  {...props}/>
    )
};