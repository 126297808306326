import { BodyWR } from "./BodyWR"
import { Container } from "./styles"

export const WaitingRoom = () => {
  return (
    <Container>
      <BodyWR />
    </Container>
  )
}
