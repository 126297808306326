import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axiosInstance";

export const Reservas = {
  getAllToursCall: async () => {
    const response = await axiosInstance.get(`/auth/tours`);
    return response;
  },
  getAvailableDatesCall: async (id: number, config?: AxiosRequestConfig ) => {
    const response = await axiosInstance.get(
      `/auth/tours/check-availabilty-between-dates/${id}`,
      config
    );
    return response;
  },
  getAvailableHourCall: async (id: number, config?: AxiosRequestConfig) => {
    const response = await axiosInstance.get(
      `/auth/getWeekAvailability/${id}`,
      config
    );
    return response;
  },
  makeReservationCall: async (bodyParameters: any, config?: AxiosRequestConfig) => {
    const response = await axiosInstance.post(
      `/auth/tours/make-reservation`,
      bodyParameters,
      config,
    );
    return response;
  },
  getTourBook: async (id: any) => {
    const response = await axiosInstance.get(`/auth/tours/${id}`);
    return response;
  },
  updateTourBook: async (id: any, bodyParameters: any) => {
    const response = await axiosInstance.put(
      `/auth/tours/${id}`,
      bodyParameters
    );
    return response;
  },
};
