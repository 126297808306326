import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material"
import { HeaderLayout } from "../../layouts/HeaderLayout";
import { Card } from "./Card";
import { registerSuccess } from "../../redux/auth/authSlice";
import { Spinner } from "../Spinner";


export const RegisterSuccess = () => {

    const { token } = useParams();

    const dispatch = useAppDispatch();
    const { loading, success } = useAppSelector(
    (state: RootState) => state.auth
  );

    const isMobile = useMediaQuery("(max-width:900px)");


    useEffect(() => {
        dispatch(registerSuccess(token!))
    }, [])


    return (
        <>
            {isMobile && success && <Card />}
            {success &&
                <HeaderLayout>
                    <Card />
                </HeaderLayout>
            }
            {loading &&
            <HeaderLayout>
                <div
                    style={{
                        width: "100vw",
                        minHeight: "84vh",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: "center"
                    }}
                >
                    <Spinner width="150px" height="150px" color="#3366CC"/>
                </div>
            </HeaderLayout>
            }
        </>
    )
}