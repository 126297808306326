import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const ContainerRecomendations = styled('div')(({ theme }) => ({
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 5px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "14px",
    padding: "20px 30px",
    marginBottom: "50px",
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
}));

export const ListRecomendations = styled('ul')(({ theme }) => ({
  listStyle: 'upper',
}));

export const Texto = styled(Typography)(({theme}) => ({
  fontFamily: "Raleway",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "27px",
}));

export const TextoResaltado = styled("span")(() => ({
  margin: "0px 5px",
  color: "#3366CC",
  fontWeight: "700",
}));