import { Container } from './styles/termsPageStyles';
import { TermsAndConditions } from '../components/TermsAndConditions';
import { HeaderLayout } from '../layouts/HeaderLayout';

import ReactGA from "react-ga4";

export const TermsPage = () => {

  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "TermsRegister Page" });

  return (
    <Container>
      <HeaderLayout>
        <TermsAndConditions />
      </HeaderLayout>
    </Container>
  )
}