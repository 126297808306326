import { HeaderLayout } from "../layouts/HeaderLayout";
import { LoginForm } from "../components/LoginForm/LoginForm";
import { Container } from './styles/LoginPageStyles';

import ReactGA from "react-ga4";

export const LoginPage = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Login" });
    return (
        <Container>
            <HeaderLayout>
                <LoginForm />
            </HeaderLayout>
        </Container>
    )
};