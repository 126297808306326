import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"
import FondoDesktop from "../../assets/images/profile/FondoDesktop.svg"
import FondoMobile from "../../assets/images/profile/FondoMobile.svg"

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  margin: 0,
  padding: 0,
  backgroundImage: `url(${FondoMobile})`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center top",
  overflow: "hidden",
  [theme.breakpoints.up("sm")]: {
    backgroundSize: "cover",
  },
  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${FondoDesktop})`,
    backgroundPosition: "center top",
    backgroundSize: "cover",
  },
}))
