import { Register } from "../components/RegisterForm"
import { Container } from './styles/registerPageStyles';

import ReactGA from "react-ga4";

export const RegisterPage = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Register Page" });

    return (
        <Container>
            <Register />
        </Container>
    )
}