import { useEffect, useState } from "react"
import { RootState } from "../../../redux/store"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { getStatesAndGrades } from "../../../redux/reservas/statesGradesSlice"
import {
  setOwnerReserva,
  nextStep,
  previousStep,
} from "../../../redux/reservas/reservasSlice"
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  useMediaQuery,
} from "@mui/material"
import { reservaSchema } from "./validationSchema"
import { useFormik } from "formik"
import {
  Form,
  ControlForm,
  ButtonContainer,
  Botones,
  TextContainer,
  Text,
  InputErrors,
} from "./styles/reservaFormStyles"
import { validateCueCalls } from "../../../services/validateCueCalls"
import { Link } from "react-router-dom"
import { sweetAlertMixin } from "../../../helpers/sweetAlert"

interface InitialValuesProps {
  institution: string
  cue: string
  state_id: number | string
  city: string
  participants: number | string
  grade_id: number | string
}

export const ReservaForm = ({ setHandleOpen, handleOpen }: any) => {
  const isMobile = useMediaQuery("(max-width:600px)")

  const [isFirstValidaton, setIsFirstValidaton] = useState(true)

  const dispatch = useAppDispatch()

  const { states, grades } = useAppSelector(
    (state: RootState) => state.StateGrades
  )
  const { reserva } = useAppSelector((state: RootState) => state.reservas)

  const { owner } = reserva

  const initialValues: InitialValuesProps = {
    institution: owner.institution,
    cue: owner.cue,
    city: owner.city,
    state_id: owner.state_id,
    participants: owner.participants,
    grade_id: owner.grade_id,
  }

  const { getFieldProps, errors, touched, handleSubmit, resetForm, setErrors } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: reservaSchema,
      onSubmit: async (values) => {
        try {
          const response = await validateCueCalls.getCueValidation(values.cue)

          if (response.data.cue) {
            dispatch(setOwnerReserva(values))
            dispatch(nextStep())
          } else {
            if (isFirstValidaton) {
              setIsFirstValidaton(false)
              setErrors({ cue: "¡Verifica que el CUE esté bien ingresado!" })
            } else {
              setErrors({
                cue: "custom",
              })
            }
          }
        } catch (error) {
          console.error(error)
        }
      },
    })

  useEffect(() => {
    if (!handleOpen) {
      resetForm()
    }
  }, [handleOpen, resetForm])

  useEffect(() => {
    dispatch(getStatesAndGrades())
  }, [dispatch])

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        type="text"
        label="Institucion"
        {...getFieldProps("institution")}
        error={touched.institution && Boolean(errors.institution)}
        helperText={
          touched.institution && errors.institution ? errors.institution : null
        }
        fullWidth
        size={isMobile ? "small" : "medium"}
      />
      <TextField
        type="text"
        label="CUE"
        {...getFieldProps("cue")}
        error={touched.cue && Boolean(errors.cue)}
        fullWidth
        size={isMobile ? "small" : "medium"}
        helperText={
          touched.cue &&
          errors.cue &&
          (errors.cue === "custom" ? (
            <>
              Este CUE es inválido, contacta con el equipo de Arcor{" "}
              <Link to="/contacto" onClick={() => setHandleOpen(false)}>
                desde acá
              </Link>{" "}
              .
            </>
          ) : (
            errors.cue
          ))
        }
      />
      <ControlForm>
        <InputLabel id="provnicia-label">Provincia</InputLabel>
        <Select
          labelId="provnicia-label"
          id="provnicia-label"
          type="text"
          label="Provincia"
          {...getFieldProps("state_id")}
          error={touched.state_id && Boolean(errors.state_id)}
          fullWidth
          size={isMobile ? "small" : "medium"}
        >
          {states.map((state) => (
            <MenuItem key={state.id} value={state.id}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
        <InputErrors>
          {touched.state_id && errors.state_id && errors.state_id}
        </InputErrors>
      </ControlForm>
      <TextField
        type="text"
        label="Localidad"
        {...getFieldProps("city")}
        error={touched.city && Boolean(errors.city)}
        helperText={touched.city && errors.city ? errors.city : null}
        fullWidth
        size={isMobile ? "small" : "medium"}
      />
      <TextField
        type="number"
        label="Cantidad de participantes"
        {...getFieldProps("participants")}
        error={touched.participants && Boolean(errors.participants)}
        helperText={
          touched.participants && errors.participants
            ? errors.participants
            : null
        }
        fullWidth
        size={isMobile ? "small" : "medium"}
      />
      <ControlForm>
        <InputLabel id="grado-label">Grado</InputLabel>
        <Select
          labelId="grado-label"
          id="grado-label"
          type="text"
          label="Grado"
          {...getFieldProps("grade_id")}
          error={touched.grade_id && Boolean(errors.grade_id)}
          size={isMobile ? "small" : "medium"}
        >
          {grades.map((grade) => (
            <MenuItem key={grade.id} value={grade.id}>
              {grade.name}
            </MenuItem>
          ))}
        </Select>
        <InputErrors>
          {touched.grade_id && errors.grade_id && errors.grade_id}
        </InputErrors>
      </ControlForm>
      <ButtonContainer>
        <Botones
          variant="outlined"
          color="primary"
          onClick={() => dispatch(previousStep())}
          fullWidth
        >
          Volver
        </Botones>
        <Botones variant="contained" color="primary" type="submit" fullWidth>
          Continuar
        </Botones>
      </ButtonContainer>
      <TextContainer>
        <Text>
          Recorda que la capacidad máxima de los tours es de 30 participantes
        </Text>
      </TextContainer>
    </Form>
  )
}
