import * as Yup from "yup";

export const reservaSchema = Yup.object({
  institution: Yup.string().required("El campo es obligatorio"),
  cue: Yup.string().required("El campo es obligatorio").matches(/^\d+$/, "El CUE debe contener solo números").min(9, "El CUE debe contener, al menos, 9 dígitos"),
  city: Yup.string().required("El campo es obligatorio"),
  state_id: Yup.string().required("El campo es obligatorio"),
  participants: Yup.number()
    .required("El campo es obligatorio")
    .min(1, "Minimo 1 participante")
    .max(30, "Maximo 30 participantes"),
  grade_id: Yup.string().required("El campo es obligatorio"),
});
