import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';


export const AvatarHeader = styled(Avatar)(() => ({
    backgroundColor: '#3366CC', 
    marginLeft: '20px', 
    marginBottom: '10px',
    textTransform: 'uppercase',

}));

export const Links = styled(Link)(() => ({
    textDecoration: 'none'
}))