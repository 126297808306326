import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
// import { RegisterPage } from './pages/RegisterPage';
import App from "./App"
import reportWebVitals from "./reportWebVitals"

// Google Analytics
import ReactGA from "react-ga4"

const root = ReactDOM.createRoot(document.getElementById("root")!)

if (
  window.location.href === "https://universoarcor.com/" ||
  window.location.href === "https://universoarcor.com.ar/"
) {
  ReactGA.initialize("G-09ZLNK7L5K")
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
