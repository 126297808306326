import { useAppSelector, useAppDispatch} from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { Container, Image, Texto, Button,InnerContainer } from "./style"
import ImageComing from '../../assets/images/auth/ImageRegister.svg'
import { useNavigate } from "react-router-dom";
import { sweetAlertConfirm } from "../../helpers/sweetAlert"
import { deleteReserveProfile, getTourProfile, } from "../../redux/profile/profileSlice"

export const CancelTour = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const token = user.access_token || sessionStorage.getItem("token")
  
  const handleDeleteReserve = async () => {
    const searchParams = new URLSearchParams(document.location.search);
    const idTour = searchParams.get('tourid');
    if ( idTour == '' || idTour == null || idTour == 'undefined' ) {
      console.log('There is not an id in url');
      return true;
    }
    const response = await sweetAlertConfirm()
    if (response) {
      const i = await dispatch(deleteReserveProfile(parseInt(idTour)))
      navigate("/user/profile")
    }
  }

  return(
    <Container>
    <Image 
      src={ImageComing}
      alt="Imagen con frutas"
    />
    <InnerContainer>
      <Texto>
        ¿Seguro quiere cancelar la Experiencia?
      </Texto>

      <Button onClick={() => navigate("/confirm-tour")}>No, fue un error</Button>
      <Button
        important
        onClick={() =>
          token ? handleDeleteReserve() : navigate("/auth/login")
        }
      >
        Sí, cancelar
      </Button>

      <Texto>
        ¡Muchas Gracias!
      </Texto>  
    </InnerContainer>
  </Container> 
  )
}