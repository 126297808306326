import { useAppSelector } from '../../../redux/hooks';
import { RootState } from "../../../redux/store";

import { AvatarMenu } from './styles';

export const Avatar = () => {

    const { user } = useAppSelector((state: RootState) => state.auth);

    const firstLetter = user.user.name.charAt(0)

    return(
        <AvatarMenu>
            {firstLetter}
        </AvatarMenu>
    )
}