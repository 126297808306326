import { ReservaSuccess } from '../components/ReservaTour/ReservaSuccess';
import { Container } from './styles/reservaExitosaStyle'

import ReactGA from "react-ga4";

export const ReservaExitosaPage = () => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "ReservaExitosa Page" });

    return (
        <Container>
            <ReservaSuccess />
        </Container>
    )
}