import { Container } from './styles/reserveConfirmStyles';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { ReserveConfirm } from '../components/ReserveConfirm';

import ReactGA from "react-ga4";

export const ReserveConfirmPage = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "ReservaConfirmada Page" });
  return (
    <Container>
      <HeaderLayout>
        <div style={{
          minHeight: "100vh",
          width: "100vw",
          display: 'flex',
          justifyContent: "center",
          alignItems: "center"
        }}>
          <ReserveConfirm />
        </div>        
      </HeaderLayout>
    </Container>
  )

}