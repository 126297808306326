import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const Container = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: '60%'
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: "2.5rem",
  fontFamily: "Telolet",
  lineHeight: "2.5rem",
  fontWeight: "400",
  color: "#3366CC",
  textAlign: "center",
  margin: "2.8rem",
}));
