import { HeaderLayout } from "../layouts/HeaderLayout";
import { HomeReserva } from "../components/ReservaTour";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FondoDesktop from "../assets/images/auth/FondoDesktop.svg";
import FondoMobile from "../assets/images/auth/FondoMobileLogin.svg";

import ReactGA from "react-ga4";

export const ReservaTourPage = () => {
  const Container = styled(Box)(({ theme }) => ({
    width: "100%",
    backgroundImage: `url(${FondoMobile})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "calc(100vh + 1px)",
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${FondoDesktop})`,
    },
  }));

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: "ReservaTour Page",
  });

  return (
    <Container>
      <HeaderLayout>
        <HomeReserva />
      </HeaderLayout>
    </Container>
  );
};
