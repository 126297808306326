import { useAppSelector, useAppDispatch } from "../../../../redux/hooks"
import { RootState } from "../../../../redux/store"
import { setInitiState } from "../../../../redux/reservas/reservasSlice"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import {
  Container,
  Image,
  Title,
  Text,
  TextBold,
  Boton,
  TextContainer,
} from "./styles"
import ImageReserve from "../../../../assets/images/reservaSuccess/Image.svg"

export const Card = () => {
  const dispatch = useAppDispatch()
  const { reserva } = useAppSelector((state: RootState) => state.reservas)

  const { date, hour } = reserva

  const newDate = moment(date).format("DD/MM/YYYY")

  const navigate = useNavigate()

  const handleClose = () => {
    dispatch(setInitiState())
    navigate("/user/profile")
  }

  return (
    <Container>
      <Image alt="Image reserve success" src={ImageReserve} />
      <TextContainer>
        <Title>¡LISTO!</Title>
        <Text>
          ¡Muchas Gracias! <br /> por reservar tu recorrido
        </Text>
        <TextBold>
          Los esperamos el <br /> {newDate} a las {hour}
        </TextBold>
        <Text>
          Te enviamos un correo con <br /> el link de acceso y <br /> contraseña
          para tus <br /> alumno/as.
        </Text>
        <Boton
          variant="contained"
          color="primary"
          onClick={handleClose}
          fullWidth
        >
          CERRAR
        </Boton>
      </TextContainer>
    </Container>
  )
}
