import { Container } from "./styles"
import { Recomendations } from "../Recomendations/index"
import { Text } from "../Text/index"

export const BodyWR = () => {
  return (
    <Container>
      <Text />
      <Recomendations />
    </Container>
  )
}
