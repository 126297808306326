import { Box, Typography, Divider } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Link } from "react-router-dom"

export const Container = styled(Box)(() => ({
  width: "100%",
  borderTopLeftRadius: "3px",
  borderTopRightRadius: "3px",
  diplay: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "35px",
}))

export const DividerTop = styled(Divider)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#1A72B8",
  height: "3px",
  margin: "0px auto 57px auto",
  [theme.breakpoints.up("md")]: {
    width: "80%",
  },
}))

export const FlexContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "55px",
  gap: "10px",
  cursor: "pointer",
}))

export const Icon = styled("img")(() => ({
  width: "18px",
  height: "18px",
}))

export const IconText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "15px",
  textAlign: "center",
  color: "#3366CC",
}))

export const ContactoContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "60px",
}))

export const Text = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13.55px",
  lineHeight: "112%",
  textAlign: "center",
  color: "#4F4F4F",
}))

export const LinkContacto = styled(Link)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13.55px",
  lineHeight: "112%",
  textAlign: "center",
  color: "#3366CC",
}))

export const LogoutContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const Logout = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "10.53px",
  lineHeight: "13px",
  textDecoration: "underline",
  color: "#000000",
  cursor: "pointer",
}))
