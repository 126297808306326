import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ContainerHome = styled("div")(({ theme }) => ({
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  //Margin auto causa problemas
  [theme.breakpoints.up(900)]: {
    width: "90%",
    marginBottom: "90px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "80%",
  },
}));

export const EmptyContainer = styled(Box)(({ theme }) => ({
  height: "2.75rem",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));
