import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


export const Container = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3.6rem'
}))