import { ResetPasswordForm } from "../components/ForgotPasswordForm/ResetPasswordForm";
import { HeaderLayout } from "../layouts/HeaderLayout"
import { Container } from './styles/forgotPageStyles';

import ReactGA from "react-ga4";

export const ResetPasswordPage = () => {
  
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "ResetPassword Page" });

  return (
    <Container>
      <HeaderLayout>
        <ResetPasswordForm />
      </HeaderLayout>
    </Container>
  )
}