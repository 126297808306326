
import { axiosInstance } from "./axiosInstance";



export const statesAndGradesCalls = {
  getStatesAndGrades: async () => {
    const response = await axiosInstance(`/auth/getStatesAndGrades`);
    return response;
  },
};
