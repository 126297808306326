import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { Navigate, Outlet } from "react-router-dom";


export const PublicRoutes = () => {

  const { token } = useAppSelector(
    (state: RootState) => state.auth
  );



  return token ? <Navigate to="/" /> : <Outlet />;
}