import { createTheme } from "@mui/material"
import { ThemeOptions } from "@mui/material/styles"

// Extend the ThemeOptions interface to include custom properties
const theme = createTheme({
  palette: {
    primary: {
      main: "#3366CC",
      light: "#00559D",
      dark: "#103F63",
      contrastText: "#FAFAFA",
    },
    secondary: {
      main: "#FEC90A",
      contrastText: "#000",
    },
    info: {
      main: "#103F63",
      contrastText: "#000",
    },
  },
} as ThemeOptions)

export default theme
