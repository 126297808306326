import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

type Props = {
  isEmpty: boolean
  loading: boolean
}

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    justifyContent: "center",
  },
}))

export const BookingsContainer = styled(Box)<Props>(
  ({ theme, isEmpty, loading }) => ({
    width: "100%",
    minHeight: "25vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: loading || isEmpty ? "center" : "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: isEmpty || loading ? "center" : "space-between",
      alignItems: isEmpty ? "center" : "flex-start",
      padding: "0px 0px 0px 30px",
      gap: "87px",
    },
  })
)
