/* eslint-disable no-useless-escape */
import { useEffect } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { resetPasswordAsync, resetState } from '../../redux/auth/authSlice';
import { validationSchemaReset } from './validationSchema';
import { FormValuesReset } from './interfaces';
import { Spinner } from '../Spinner';
import { TextField } from '@mui/material';

import image from '../../assets/images/auth/ImageLogin.svg';

import { ContainerPage, Container, ContImgTitle, ContainerTitle, Title, ContainerImage, Img, ContainerForm, ButtonStyle, ContainerLinks, Links } from './styles/forgotPasswordStyles';

export const ResetPasswordForm = () => {

    const { email, token } = useParams();
    const dispatch = useAppDispatch();
    const { loading, success } = useAppSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    const initialValues: FormValuesReset = {
        email,
        password: '',
        token,
        password_confirmation: ''
    }

    useEffect(() => {
        if (success) {
            dispatch(resetState())
            navigate('/auth/login');
        }
    }, [success, navigate, dispatch])

    return (
        <ContainerPage>
            <Container>
                <ContImgTitle>
                    <ContainerTitle>
                        <Title>Modificar clave</Title>
                    </ContainerTitle>
                    <ContainerImage>
                        <Img src={image} alt="Imagen de Frutas" />
                    </ContainerImage>
                </ContImgTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaReset}
                    onSubmit={async (values) => {
                        await dispatch(resetPasswordAsync(values))
                    }}
                >
                    {({ getFieldProps, errors, touched }) => (
                        <ContainerForm>
                            <TextField
                                sx={{ marginBottom: "1rem" }}
                                type="password"
                                id="password"
                                {...getFieldProps("password")}
                                label="Contraseña"
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password ? errors.password : null}
                                fullWidth
                            />
                            <TextField
                                sx={{ marginBottom: "1rem" }}
                                type="password"
                                id="password_confirmation"
                                {...getFieldProps("password_confirmation")}
                                label="Repetir - Contraseña"
                                error={touched.password_confirmation && Boolean(errors.password_confirmation)}
                                helperText={touched.password_confirmation && errors.password_confirmation ? errors.password_confirmation : null}
                                fullWidth
                            />
                            <ButtonStyle
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                sx={{ textTransform: "none" }}
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner color="#000" height={25} width={25} />
                                ) : (
                                    "MODIFICAR"
                                )}
                            </ButtonStyle>
                        </ContainerForm>
                    )}
                </Formik>
            </Container>
        </ContainerPage>
    )
};