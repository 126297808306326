import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 20px 30px",
  gap: "20px",
  background: "#FFFFFF",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },
}));

export const ContainerDay = styled(Box)(({ theme }) => ({
  width: "95%",
  display: "flex",
  justifyContent: "flex-start",
  padding: "15px 10px 20px 0px",
  gap: "10px",
  borderBottom: "4px solid #CBDDFF",
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#000000",
  [theme.breakpoints.up("lg")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up(1400)]: {
    fontSize: "20px",
  },
}));

export const Day = styled(Typography)(() => ({
  margin: "20px auto",
  color: "rgb(51 102 204)",
  fontWeight: "bold",
}));

export const ContainerButton = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifySelf: "center",
  alignSelf: "flex-start",
}));

export const Boton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "58px",
  padding: "11.25px 22.5px !important",
  borderRadius: "86px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px !important",
  lineHeight: "21px !important",
  textAlign: "center",
  textTransform: "none",
  [theme.breakpoints.up("sm")]: {
    width: "70% !important",
  },
  [theme.breakpoints.up("lg")]: {
    width: "80% !important",
    fontSize: "15px",
  },
  [theme.breakpoints.up(1400)]: {
    fontSize: "22px",
  },
}));

export const BotonDisabled = styled(Button)(({ theme }) => ({
  width: "80% !important",
  height: "58px",
  padding: "11.25px 22.5px !important",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px !important",
  lineHeight: "21px !important",
  textAlign: "center",
  textTransform: "none",
  cursor: "initial",
  borderRadius: "86px",
  backgroundColor: "#BDBDBD !important",
  [theme.breakpoints.up("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
    fontSize: "15px",
  },
  [theme.breakpoints.up(1400)]: {
    fontSize: "22px",
  },
}));
