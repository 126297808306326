import { Container } from './styles/reserveConfirmStyles';
import { HeaderLayout } from '../layouts/HeaderLayout';
import { RegisterSuccess } from '../components/RegisterSuccess';

import ReactGA from "react-ga4";

export const SuccessRegisterPage = () => {

  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "SucessRegister Page" });

  return (
    <Container>
        <RegisterSuccess />
    </Container>
  )

}