import LogoArcor from '../../assets/images/footer/LogoArcor.svg';
import { Container, SubContainer, LogoCopyContainer, Logo, Text, TextLink } from './style';


export const Footer = () => {
  return (
    <Container>
      <SubContainer>
        <LogoCopyContainer>
          <Logo
            alt='Logo Arcor'
            src={LogoArcor}
          />
          <Text>Copyright Arcor 2018. / Todos los derechos reservados.</Text>
        </LogoCopyContainer>
        <TextLink to='/terminos-condiciones'>VER TERMINOS Y CONDICIONES</TextLink>
      </SubContainer>
    </Container>
  )
}