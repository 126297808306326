import { styled } from "@mui/material/styles"
import { Container, Button } from "@mui/material"

export const ContainerCalendar = styled(Container)(({ theme }) => ({
  width: "95vw",
  height: "80vh",
  fontFamily: "Raleway",
  margin: "auto",
  marginBottom: "65px",
  [theme.breakpoints.up("md")]: {
    width: "70vw",
  },
}))

export const ContainerAlerts = styled(Container)(({ theme }) => ({
  width: "100%",
  display: "flex",
  margin: "0 auto",
  flexDirection: "column",
  alignItems: "center",
  fontSize: ".7rem",
  textAlign: "center",
  marginBottom: "50px",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },

  "& p": {
    maxWidth: "600px",
    margin: "10px 0px 20px 0px",
    fontStyle: "italic",
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(14),
  },
}))

export const ButtonAlerts = styled(Button)(({ theme }) => ({
  margin: "0 auto",
  width: "20%",
  fontSize: ".7rem",
  marginBottom: "25px",
  paddingLeft: "10px",
  paddingRight: "10px",
  borderRadius: "25px",
  minWidth: "90px",

  [theme.breakpoints.up("md")]: {
    margin: "0",
    fontSize: "1rem",
  },
}))
