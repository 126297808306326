import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type ButtonProps = {
  important?: boolean;
};

export const Container = styled(Box)(({ theme  }) => ({
  width: "100vw",
  minHeight: "100vh",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('md')]:{
    flexDirection: 'row',
    gap: '0px'
  }
}));

export const InnerContainer = styled(Box)(({ theme  }) => ({
  // width: "100vw",
  // minHeight: "100vh",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('md')]:{
    flexDirection: 'column',
    gap: '0px'
  }
}));

export const Image = styled("img")(({ theme }) => ({
  width: "250px",
  height: "213px",
  [theme.breakpoints.up('md')]:{
    paddingBottom: '10rem',
    width: "400px",
    height: "340px",
  }
}));

export const Texto = styled(Typography)(({ theme }) => ({
  fontFamily: "Telolet",
  fontWeight: "400",
  fontSize: "45px",
  textAlign: "center",
  color: "#3366CC",
  lineHeight: '60px',
  padding: '1rem 10px',
  [theme.breakpoints.up('md')]:{
    width: '600px',
    lineHeight: '80px',
    fontSize: "65px",
  }
}));

export const Button = styled("button")<ButtonProps>(({ important }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 20px",
  width: "155px",
  height: "49px",
  margin: "10px 0",
  background: `${important ? "#3366CC" : "#F2C94C"}`,
  color: `${important ? "#FFFFFF" : "#000000"}`,
  marginLeft: `${important ? "10px" : ""}`,
  borderRadius: "86px",
  border: "none",
  outline: "none",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "19px",
  cursor: "pointer",
  transition: "all .3s ease",
  "&:hover": {
    background: `${important ? "#103F63" : "#B18C06"}`,
  },
}));