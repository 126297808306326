import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FondoDesktop from "../../assets/images/enterTour/FondoDesktop.svg";
import FondoDesktopIntermediate from "../../assets/images/contacto/FondoDesktop.svg";
import FondoMobile from "../../assets/images/enterTour/FondoMobile.svg";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  backgroundImage: `url(${FondoMobile})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  backgroundSize: "cover",
  [theme.breakpoints.up(450)]: {
    backgroundImage: `url(${FondoDesktopIntermediate})`,
  },
  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${FondoDesktop})`,
  },
}));