import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "75vw",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px auto 0px auto",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    marginTop: "2rem",
    minHeight: "calc(100vh - 159px) !important",
    alignItems: "flex-start",
  },
  [theme.breakpoints.up(1500)]: {
    marginTop: "5rem",
  },
}));

export const ContImgTitle = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "50px 0px 20px 0px",
  [theme.breakpoints.up("md")]: {
    marginTop: "20px",
    width: "60%",
  },
}));

export const ContainerTitle = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontWeight: "400",
  fontSize: "45px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#3366CC",
  marginBottom: "10px",
}));

export const Subtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16.9px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#000000",
  marginBottom: "20px",
}));

export const ContainerImagenes = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "175px",
  marginTop: "20px",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    marginTop: "5.1rem",
  },
}));

export const ImgFrutas = styled("img")(({ theme }) => ({
  width: "151px",
  height: "115px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.zIndex.tooltip,
  paddingRight: "100px",
  [theme.breakpoints.up("md")]: {
    width: "250px",
    height: "180px",
    paddingRight: "250px",
  },
}));

export const ImgNena = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "183px",
  height: "175px",
  paddingLeft: "70px",
  [theme.breakpoints.up("md")]: {
    width: "285px",
    height: "255px",
  },
}));

export const ContainerForm = styled("form")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 5px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "14px",
  padding: "20px 30px",
  marginBottom: "24px",
  [theme.breakpoints.up("sm")]: {
    width: "60%",
  },
  [theme.breakpoints.up("md")]: {
    width: "40%",
  },
}));

export const Titulo = styled(Typography)(() => ({
  color: "#103F63",
  fontWeight: "bold",
  fontSize: "1.5rem",
  fontFamily: "Raleway",
  width: "60%",
  textAlign: "center",
  marginBottom: "1rem",
}));

export const ButtonStyle = styled(Button)(() => ({
  padding: "12px 40px 12px 40px",
  borderRadius: "86px",
  fontSize: "16px",
  fontWeight: "700",
  fontFamily: "Raleway",
  textTransform: "uppercase",
}));
