import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { Modal } from "@mui/material";
import { Container, Title, LastBookContainer, NewBookContainer, Text, TitleText, ButtonsContainer, ButtonStyled } from "./styles"

import moment from 'moment';
import 'moment/locale/es';


export const ModalEdit = ({ openEditConfirmModal, setOpenEditConfirmModal, setOpenEditFormModal }: any) => {

  moment.locale('es');

  const { newTourBook, oldTourBook } = useAppSelector((state: any) => state.editTour);
  const [toShow, setToShow] = useState({
    oldBook: {
      day: '',
      date: '',
      hour: '',
      name: ''
    },
    newBook: {
      day: '',
      date: '',
      hour: '',
      name: ''
    }
  })

  const { oldBook, newBook } = toShow;

  const handleClose = () => {
    setOpenEditConfirmModal(false);
  }

  const handleNext = () => {
    setOpenEditConfirmModal(false);
    setOpenEditFormModal(true)
  }

  useEffect(() => {
    setToShow({
      oldBook: {
        day: moment(oldTourBook.date).format('dddd'),
        date: moment(oldTourBook.date).format('DD/MM'),
        hour: `${oldTourBook.hour} hs`,
        name: oldTourBook.name
      },
      newBook: {
        day: moment(newTourBook.date).format('dddd'),
        date: moment(newTourBook.date).format('DD/MM'),
        hour: newTourBook.hour,
        name: oldTourBook.name
      }
    })
  }, [newTourBook])

  return (
    <Modal
      open={openEditConfirmModal}
      keepMounted
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Container>
        <Title>Estás por editar
          el DÍA y HORARIO
          de tu turno:
        </Title>
        <LastBookContainer>
          <TitleText oldBook={true}>Anterior:</TitleText>
          <Text oldBook={true}>{oldBook.day} {oldBook.date} - {oldBook.hour}</Text>
          <Text oldBook={true}>Tour {oldBook.name}</Text>
        </LastBookContainer>
        <NewBookContainer>
          <TitleText>Nuevo:</TitleText>
          <Text>{newBook.day} {newBook.date} - {newBook.hour}</Text>
          <Text>Tour {newBook.name}</Text>
        </NewBookContainer>
        <ButtonsContainer>
          <ButtonStyled
            color='primary'
            variant='outlined'
            fullWidth
            onClick={handleClose}
          >
            VOLVER
          </ButtonStyled>
          <ButtonStyled
            color='primary'
            variant='contained'
            fullWidth
            onClick={handleNext}
          >
            CONFIRMAR
          </ButtonStyled>
        </ButtonsContainer>
      </Container>
    </Modal>
  )
}