

import { axiosInstance } from "./axiosInstance";



export const validateCueCalls = {
  getCueValidation: async (cue: string) => {
    const response = await axiosInstance(`/auth/isCueValid?cue=${cue}`);
    return response;
  },
};
