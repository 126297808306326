import {
  Box,
  Button,
  Typography,
  Accordion,
  TypographyProps,
  AccordionSummary,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// WARNING: THIS CARD IS ALSO USED IN MY TOURS

interface ElementsProps {
  nextBooking?: boolean;
  isAccordion?: boolean;
}

const shouldForwardProp = (prop: string) =>
  prop !== "isAccordion" && prop !== "nextBooking";

export const ContainerCard = styled(Box, {
  shouldForwardProp,
})<ElementsProps>(({ theme, isAccordion }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  padding: theme.spacing(3),
  maxWidth: "240px",
  position: "relative",
  background: theme.palette.background.paper,
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
  paddingBottom: "37px",

  ...(!isAccordion && {
    gap: theme.spacing(2),
  }),

  ...(isAccordion && {
    padding: theme.spacing(2, 0),
  }),
}));

export const DetailContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));

export const DayText = styled(Typography, {
  shouldForwardProp,
})<ElementsProps & TypographyProps>(({ theme, isAccordion }) => ({
  fontFamily: "Raleway",
  textAlign: "center",
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  fontWeight: 600,
  textDecoration: "italic",

  "& h4": {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.primary,
    margin: 0,
  },

  ...(!isAccordion &&
    {
      // gap: theme.spacing(2),
    }),

  ...(isAccordion && {
    color: theme.palette.primary.main,
    textAlign: "left",
    marginBottom: 0,
    "& h4": {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.secondary.contrastText,
      opacity: 0.8,
      margin: 0,
    },

    "& span": {
      display: "block",
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.primary.dark,
    },
  }),
}));

export const DayContainer = styled(Box, {
  shouldForwardProp,
})<ElementsProps>(({ theme, nextBooking }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  borderBottom: `3px dotted ${theme.palette.primary.main}`,
}));

export const CopyIconContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const IconCopy = styled("img")(({ theme }) => ({
  width: "18px",
  height: "18px",
  margin: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  background: theme.palette.primary.contrastText,
  borderRadius: "50%",
  cursor: "pointer",
  position: "absolute",
  top: 0,
  right: 0,
  "&:hover": {
    opacity: 0.8,
  },
}));

export const TourDataContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 1),
  borderRadius: "14px",
  position: "relative",
  width: "100%",
}));

export const DataTourText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: "Raleway",
  fontSize: theme.typography.pxToRem(14),
  width: "100%",
}));

export const TourDetailsContainer = styled(Box, {
  shouldForwardProp,
})<ElementsProps>(({ theme, isAccordion }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  marginBottom: theme.spacing(2),

  ...(isAccordion && {
    marginTop: theme.spacing(2),
  }),
}));

export const DetailText = styled(Typography)(({ theme }) => ({
  width: "100%",
  // height: "51px",
  // fontSize: "10.5px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 500,
  // lineHeight: "13px",
}));

export const EnterTourButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  height: "37px",
  background: "#F2C94C",
  borderRadius: "0 0 14px 14px",
  color: "#000",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  textAlign: "center",
  transition: "all .3s ease",
  "&:hover": {
    background: "#F8E4A5",
  },
}));

export const ContainerEditButton = styled(Box, {
  shouldForwardProp,
})<ElementsProps>(({ nextBooking, theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),

  ...(!nextBooking && {
    marginBottom: 0,
  }),
}));

export const IconEdit = styled("img")(() => ({
  width: "12px",
  height: "12px",
}));

export const EditButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: theme.typography.pxToRem(12),
  padding: 0,
  gap: theme.spacing(0.5),
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const CancelButton = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(12),
  textDecoration: "underline",
  color: "#000000",
  textTransform: "uppercase",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.error.main,
  },
}));

export const AccordionStyled = styled(Accordion)(() => ({
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px !important",
  width: "100%",

  "&:before": {
    display: "none",
    border: "none",
  },
}));

export const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));
