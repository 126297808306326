import { Container, Image, Texto } from "./styles"
import ImageComing from '../../assets/images/auth/ImageRegister.svg'


export const ComingSoon = () => {
  return(
    <Container>
    <Image 
      src={ImageComing}
      alt="Imagen con frutas"
    />
    <Texto>
      Próximamente <br />
      vas a poder disfrutar <br /> 
      de esta mágica <br />
      expriencia <br />
    </Texto>
  </Container> 
  )
}