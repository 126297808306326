import { Container, Text } from "./styles"
import { Card } from "../../Card"
import { ProfileProps } from "../../../../redux/profile/profileSlice"

export const ScheduledBookings = ({ profile }: { profile: ProfileProps[] }) => {
  return (
    <Container>
      <Text>Agendados</Text>
      {profile?.map(
        (element, i) =>
          i !== 0 && <Card key={i} element={element} isAccordion={true} />
      )}
    </Container>
  )
}
