import { ContainerPage } from './styles/quienesSomosPageStyles';
import { QuienesSomos } from '../components/QuienSomos';
import { HeaderLayout } from '../layouts/HeaderLayout';

import ReactGA from "react-ga4";

export const QuienesSomosPage = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Quienes somos Page" });

    return(
        <ContainerPage>
            <HeaderLayout>
                <QuienesSomos />
            </HeaderLayout>
        </ContainerPage>
    )
}