import { useState } from "react";
import { Formik } from "formik";
import { Container, ContImgTitle, ContainerTitle, Title, Subtitle, ContainerImagenes, ImgFrutas, ImgNena, ContainerForm, ButtonStyle } from './styles';
import { TextField } from '@mui/material';

import { InitialValuesProps } from "./interface";
import { validationSchema } from "./validationSchema";

import ImagenNena from "../../assets/images/contacto/NenaContacto.svg";
import ImagenFrutas from "../../assets/images/contacto/Frutas.svg";
import { enterTourCalls } from "../../services/enterTourApiCalls";
import { sweetAlertMixin } from "../../helpers/sweetAlert";
import { Spinner } from "../Spinner";
import { current } from "@reduxjs/toolkit";

export const EnterTour = () => {


  const initialValues: InitialValuesProps = {
    tour_book_code: '',
    tour_code: '',
    name: ''
  }

  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <ContImgTitle>
        <ContainerTitle>
          <Title>¡Vamos a hacer el TOUR!</Title>
          <Subtitle>Cargá los datos que te dió tu docente para ingresar.</Subtitle>
        </ContainerTitle>
        <ContainerImagenes>
          <ImgFrutas src={ImagenFrutas} alt="Imagen de Frutas" />
          <ImgNena src={ImagenNena} alt="Imagen Nena" />
        </ContainerImagenes>
      </ContImgTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true)
          try {
            const response = await enterTourCalls.enterTour(values);
              const currentDate = new Date().getTime();
            const tourDate = new Date(`${response.data.data.tour_book.date}T${response.data.data.tour_book.hour}`).getTime();
              if(currentDate >= tourDate){
                resetForm()
                setLoading(false);
                window.open(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBDOMINIO_URL}/index.html?name=${response.data.data.name}&code=${values.tour_book_code}&tour_code=${values.tour_code}&isGuide=${response.data.data.is_guide
                }`, "_blank")
              }else{
                sweetAlertMixin('error', 'Todavia no comenzó la experiencia, revisa la fecha y hora.')  
                setLoading(false)
              }
          } catch (err: any) {
            console.log('ERROR: ', err)
            if(err.response.status === 405){
              sweetAlertMixin('error', 'La experiencia todavía no comenzó. Si el problema persiste contacta a soporte')
            }else if (err.response.status == 404){
              sweetAlertMixin('error', 'No pudimos encontrar la reserva, revisa tus datos ingresados.')
            }else{
              sweetAlertMixin('error', 'Ocurrio un error, intente nuevamente mas tarde.')
            }
            setLoading(false)
          }


        }}
      >
        {({ getFieldProps, errors, touched }) => (
          <ContainerForm>
            <TextField
              sx={{ marginBottom: "1rem" }}
              id="tour_code"
              {...getFieldProps('tour_code')}
              label="Número de Tour"
              error={touched.tour_code && Boolean(errors.tour_code)}
              helperText={touched.tour_code && errors.tour_code ? errors.tour_code : null}
              fullWidth
            />
            <TextField
              sx={{ marginBottom: "1rem" }}
              id="tour_number"
              {...getFieldProps('tour_book_code')}
              label="Código de acceso"
              error={touched.tour_book_code && Boolean(errors.tour_book_code)}
              helperText={touched.tour_book_code && errors.tour_book_code ? errors.tour_book_code : null}
              fullWidth
            />
            <TextField
              sx={{ marginBottom: "1rem" }}
              id="name"
              {...getFieldProps('name')}
              label="Tu Nombre"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name ? errors.name : null}
              fullWidth
            />
            <ButtonStyle
              variant="contained"
              color="secondary"
              sx={{ textTransform: "none" }}
              type="submit"
              disabled={loading}
            >
              {
                loading
                  ? <Spinner width='30px' height='30px' color='#000000' />
                  : "INGRESAR"
              }
            </ButtonStyle>
          </ContainerForm>
        )}
      </Formik>
    </Container >
  )
}