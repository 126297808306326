import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const ContainerCard = styled(Box)(({ theme }) => ({
  width: '100%',
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: "2.75rem",
    flex: "1 0 100%",
    scrollSnapAlign: "start",
  },
}));

export const ContainerVideo = styled(Box)(() => ({
  width: "14.6rem",
  height: "14.6rem",
  borderRadius: "100%",
  position: "relative",
}));

export const Video = styled("video")(({theme}) => ({
  borderRadius: "50%",
  objectFit: "cover",
  width: "14.6rem",
  height: "14.6rem",
  [theme.breakpoints.up('md')]:{
    boxShadow: '-5px 14px 23px -2px rgba(0,0,0,0.75)'
  }
}));

export const LineasVideo = styled("img")(() => ({
  zIndex: "10000",
  width: "14.6rem",
  height: "14.6rem",
  position: "absolute",
  left: 0,
}));

export const ContainerActions = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
}));

export const ContainerDescription = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "Telolet",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "3.8rem",
  lineHeight: "3.8rem",
  textAlign: "center",
  color: "#3366CC",
  margin: "11px 0px",
}));

export const Subtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1rem",
  lineHeight: "20px",
  textAlign: "center",
  color: "#000000",
  width: "134px",
}));

export const AnimVideo = styled("video")(() => ({
  width: "160px",
  height: "160px",
  marginTop: '22px'
}));
