import Swal from "sweetalert2";
import { SweetAlertIcon } from "sweetalert2";

export const sweetAlertConfirm = async () => {
  const alerta = Swal.fire({
    title: "Estas Seguro?",
    text: "Si lo cancelas, no podras volver a reservar ese horario!",
    icon: "warning",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Borrar!",
  });

  const response = await alerta;

  return response.isConfirmed;
};

export const sweetAlertMixin = (type:SweetAlertIcon, message:string) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: type,
    title: message,
  });
};
