import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../../theme";

type Props = {
  isOpen?: boolean;
  isMobile?: boolean;
};

export const MonthTitleText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(24),
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(1),

  "& span": {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.dark,
  },
}));

export const MonthTitleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 1),
  borderBottom: `3px solid ${theme.palette.primary.main}`,
}));

export const Icon = styled("img")<Props>(({ isOpen, isMobile, theme }) => ({
  width: "12px",
  height: "12px",
  transition: "transform 0.3s ease",
  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
}));

export const AccordionStyled = styled(Accordion)({
  width: "100%",
  boxShadow: "none !important",
  backgroundColor: "transparent !important",
  "&:before": {
    display: "none",
  },
});

export const AccordionSummaryStyled = styled(AccordionSummary)({
  padding: 0,
});

export const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

export const CardContainer = styled(Box)<{ isMobile?: boolean }>(
  ({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(2),
  })
);
